import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Step, StepLabel, Stepper } from '@material-ui/core';
import _ from 'lodash';
import LoadMenu from 'components/menus/LoadMenu';
import CardDetailsForm from 'components/forms/load/CardDetailsForm';
import BulkCardDetailsForm from 'components/forms/load/BulkCardDetailsForm';
import BulkDigitalCardDetailsForm from 'components/forms/load/BulkDigitalCardDetailsForm';
import PaymentMethodForm from 'components/forms/load/PaymentMethodForm';
import PaymentForm from 'components/forms/load/PaymentForm';
import PurchaseTotals from 'components/PurchaseTotals';
import { addCard, updateCards, addSerial, loadCard, bulkLoad } from 'actions/LoadActions';

export class LoadCardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      formData: {
        currency_type: null
      }
    };

    props.updateCards([]); // Resets cards
    if (!(props.bulkPhysical || props.bulkDigital)) props.addCard(); // Default card
    if (props.bulkPhysical && _.isEmpty(props.serials)) props.addSerial(); // Default serial set

    this.renderStep = this.renderStep.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleDataNext = this.handleDataNext.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePaymentMethodSelection = this.handlePaymentMethodSelection.bind(this);
  }

  renderStep(step) {
    const { webposProfile, bulkPhysical, bulkDigital, isProcessing, cards, serials } = this.props;
    const { formData } = this.state;

    let steps = [
      <PaymentMethodForm handleBack={this.handleBack}
                         handlePaymentMethodSelection={this.handlePaymentMethodSelection}
                         paymentMethods={this.props.paymentMethods} />,

      <PaymentForm handleBack={this.handleBack}
                   handleNext={this.handleSubmit}
                   handleDataChange={this.handleDataChange}
                   formData={formData}
                   isProcessing={isProcessing}
                   webposProfile={webposProfile}
                   paymentMethod={formData.currency_type}
                   cardCost={this.cardValue()}
                   totalCost={this.totalCost()}
                   disableSubmit={_.isEmpty(cards) && _.isEmpty(serials)} />
    ]

    if (bulkPhysical) {
      steps.splice(0, 0,
        <BulkCardDetailsForm handleNext={this.handleNext}
                             maxCardValue={webposProfile.max_card_value}
                             maxCartValue={webposProfile.max_cart_value}
                             maxCartCards={webposProfile.max_cart_cards}
                             totalCost={this.totalCost()} />
      );
    } else if (bulkDigital) {
      steps.splice(0, 0,
        <BulkDigitalCardDetailsForm handleNext={this.handleNext}
                                    handleDataChange={this.handleDataChange}
                                    maxCardValue={webposProfile.max_card_value}
                                    maxCartValue={webposProfile.max_cart_value}
                                    maxCartCards={webposProfile.max_cart_cards}
                                    totalCost={this.totalCost()} />
      );
    } else {
      steps.splice(0, 0,
        <CardDetailsForm handleNext={this.handleNext}
                         formData={formData}
                         maxCardValue={webposProfile.max_card_value}
                         maxCartValue={webposProfile.max_cart_value}
                         maxCartCards={webposProfile.max_cart_cards}
                         totalCost={this.totalCost()}
                         multiple />
      );
    }

    return steps[step];
  }

  handleNext() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  }

  handleBack() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  }

  handlePaymentMethodSelection(paymentMethod) {
    const { formData } = this.state;
    this.setState({ formData: _.merge(formData, {currency_type: paymentMethod}) });

    this.handleNext();
  }

  handleDataChange(data) {
    this.setState({ formData: _.merge(this.state.formData, data) });
  }

  handleDataNext(data, _resetForm, _invalidateForm) {
    this.handleDataChange(data);
    this.handleNext();
  }

  mapInputs(inputs) {
    // TODO: Investigate encryption in prod
    // if (inputs.currency_type == 'card') {
    //   inputs.credit_card_number = eCrypt.encryptValue(inputs['credit_card_number'], window.EWAY_ENCRYPTION_KEY);
    //   inputs.credit_card_verification_value = eCrypt.encryptValue(inputs['credit_card_verification_value'], window.EWAY_ENCRYPTION_KEY);
    // }

    return inputs;
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const { bulkPhysical, loadCard, bulkLoad, cards, serials } = this.props;
    const { formData } = this.state;
    this.setState({ formData: _.merge(formData, data) });
    const encryptedFormData = this.mapInputs(formData);

    if (bulkPhysical) {
      bulkLoad(serials, encryptedFormData);
    } else {
      loadCard(cards, encryptedFormData);
    }
  }

  cardQuantity() {
    const { bulkPhysical, cards, serials } = this.props;

    if (bulkPhysical) {
      return _.reduce(serials, (sum, i) => {
        const serialDifference = parseInt(i.last_serial) - parseInt(i.first_serial);
        if (i.first_serial && i.last_serial) {
          return sum + serialDifference + 1
        } else {
          return sum;
        }
      }, 0);
    } else {
      return cards.length
    }
  }

  cardValue() {
    const { bulkPhysical, cards, serials } = this.props;

    if (bulkPhysical) {
      return _.reduce(serials, (sum, i) => {return sum + ((i.last_serial - i.first_serial + 1) * parseFloat(i.amount || 0)) }, 0);
    } else {
      return _.reduce(cards, (sum, i) => {return sum + parseFloat(i.amount || 0) }, 0);
    }
  }

  cardFeesValue() {
    if (this.state.formData.waive_card_fee == '1') {
      return 0;
    }

    const { bulkPhysical, bulkDigital, cards, serials, webposProfile } = this.props;

    if (bulkPhysical) {
      return _.reduce(serials, (sum, i) => {return sum + ((i.last_serial - i.first_serial + 1) * parseFloat(i.fee || 0)) }, 0);
    } else if(bulkDigital) {
      const product = _.find(webposProfile.digital_products, (p) => { return p.id === parseInt(this.state.formData.bulk_product_id) }) || webposProfile.digital_products[0];
      return cards.length * (product ? product.webpos_fees : 0);
    } else {
      return _.reduce(cards, (sum, i) => {return sum + parseFloat(i.fee || 0) }, 0);
    }
  }

  creditCardSurcharge() {
    const { creditCardFee } = this.props;
    const applicableAmount = this.cardValue() + this.cardFeesValue();
    return creditCardFee ? applicableAmount / 100 * creditCardFee.percentage : 0;
  }

  totalCost() {
    return this.cardValue() + this.cardFeesValue() + this.creditCardSurcharge();
  }

  salesTax() {
    return this.cardFeesValue() - (this.cardFeesValue() / 1.1);
  }

  render() {
    const { activeStep, formData } = this.state;
    const { webposProfile, creditCardFee } = this.props;

    let steps = ['Card Details', 'Payment Method', 'Payment'];

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <LoadMenu />
        </Grid>
        <Grid item md={8} className="full-width" style={{minHeight: '550px'}}>
          <Paper className="form-container" elevation={1}>
            <Stepper activeStep={activeStep}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel StepIconProps={{classes: {text: 'white-fill'}}}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Grid container spacing={8}>
              <Grid item sm={12} lg={5} className="full-width" style={{padding: '1em'}}>
                <PurchaseTotals
                  webposProfile={webposProfile}
                  formData={formData}
                  cardQuantity={this.cardQuantity()}
                  cardValue={this.cardValue()}
                  cardFeesValue={this.cardFeesValue()}
                  creditCardFee={creditCardFee}
                  creditCardSurcharge={this.creditCardSurcharge()}
                  salesTax={this.salesTax()}
                  onDataChange={this.handleDataChange}
                  totalAmount={this.totalCost()}
                />
              </Grid>
              <Grid item sm={12} lg={7} className="full-width">
                {this.renderStep(activeStep)}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    paymentMethods: state.session.currentUser.payment_methods,
    webposProfile: state.session.webposProfile,
    isProcessing: state.load.isProcessing,
    cards: state.load.cards,
    serials: state.load.serials,
    creditCardFee: state.load.creditCardFee,
  }
}

const mapDispatchToProps = {
  addCard,
  updateCards: (cards) => updateCards(cards),
  addSerial,
  loadCard: (cards, data) => loadCard(cards, data),
  bulkLoad: (serials, data) => bulkLoad(serials, data)
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadCardPage)
