import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, InputAdornment, CircularProgress, Button,
         Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import BalanceMenu from 'components/menus/BalanceMenu';
import TextInput from 'components/inputs/TextInput';
import { formatCurrency } from 'utils/currency';
import { formatDateTime } from 'utils/dates';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import { parseCardID } from 'utils/card-swipes';
import { fetchBalance } from 'actions/BalanceActions';
import { cancelCard, resendCard } from 'actions/CardActions';

export class BalancePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: queryString.parse(this.props.location.search).card_id
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const data = {card_number: this.state.cardNumber};

    if (data.card_number) {
      this.props.fetchBalance(data, this.formRef);
    }
  }

  onChange(event) {
    this.setState({cardNumber: event.target.value});
    const parsedNumber = parseCardID(event.target.value);

    if (parsedNumber) {
      this.setState({cardNumber: parsedNumber});
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.fetchBalance(data, this.formRef);
  }

  renderTableItem(label, value) {
    return (
      value ?
        <TableRow>
          <TableCell padding="dense">{label}</TableCell>
          <TableCell padding="dense">{value}</TableCell>
        </TableRow>
      :
        null
    );
  }

  render() {
    const { webposProfile, isFetching, balanceData, cancelCard, resendCard } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <BalanceMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" className="hidden-print" gutterBottom>Balance Check</Typography>

            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef} className="full-width hidden-print">
              <Grid container spacing={8} justify="center">
                <Grid item md={6} xs={12}>
                  <TextInput name="card_number" label="Card Number" autoFocus required
                    value={this.state.cardNumber}
                    hint="Enter 11 digit Card ID or 19 digit Card Number"
                    inputProps={{
                      endAdornment: isFetching ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                    }}
                    onChange={this.onChange}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type='submit' variant='contained' fullWidth color='secondary' disabled={isFetching}>Check Balance</Button>
                </Grid>
              </Grid>
            </Formsy>

            {balanceData &&
              <React.Fragment>
                <Grid container spacing={8} justify="center" className="top-space">
                  <Grid item md={6} xs={12}>
                    <Table>
                      <TableBody>
                        {this.renderTableItem('ID', balanceData.card_id)}
                        {this.renderTableItem('Number', balanceData.number)}
                        {this.renderTableItem('Recipient SMS Number', balanceData.delivery_sms_number)}
                        {this.renderTableItem('Recipient Email', balanceData.delivery_email_address)}
                        {this.renderTableItem('Expiry Date', balanceData.expiry)}
                        {this.renderTableItem('Status', balanceData.status)}
                      </TableBody>
                    </Table>
                    {balanceData.replaced &&
                      <div className="top-space">
                        <Typography variant="h6" className="inline">{'Replaced with card number: '}
                          {balanceData.include_replacement_link ?
                            <a href="javascript:void(0);" onClick={() => this.setState({cardNumber: balanceData.replacement_number}, this.handleSubmit({card_number: balanceData.replacement_number}))}>
                              {balanceData.replacement_number}
                            </a>
                          :
                            balanceData.replacement_number
                          }
                        </Typography>
                      </div>
                    }
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <img className="full-width hidden-print" src={balanceData.product_image} />
                  </Grid>
                </Grid>
                <Grid container spacing={8} justify="center" className="top-space">
                  <Grid item md={6} xs={12} align="center">
                    <Typography variant="subtitle1">Balance</Typography>
                    <Typography variant="h4" color="primary">{formatCurrency(balanceData.balance, webposProfile.currency_code)}</Typography>
                  </Grid>
                  {webposProfile.rewards_enabled &&
                    <Grid item md={6} xs={12} align="center">
                      <Typography variant="subtitle1">Reward Points</Typography>
                      <Typography variant="h4" color="primary">{balanceData.reward_points}</Typography>
                    </Grid>
                  }
                </Grid>
                {balanceData.cancellable &&
                  <div className="button-container">
                    <Button variant="contained" color="secondary" className="hidden-print" onClick={() => cancelCard(balanceData.card_id)}>Cancel Card</Button>
                  </div>
                }

                {balanceData.purchases &&
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom className="top-space">Purchase History</Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="dense">ID</TableCell>
                          <TableCell padding="dense">Date/Time</TableCell>
                          <TableCell padding="dense">Type</TableCell>
                          <TableCell padding="dense">Payment Method</TableCell>
                          <TableCell align="right" padding="dense">Total Paid</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {balanceData.purchases.map((purchase, index) =>
                          <TableRow key={index}>
                            <TableCell padding="dense">
                              <NavLink to={'/purchases/' + purchase.id}>{purchase.id}</NavLink>
                            </TableCell>
                            <TableCell padding="dense">{formatDateTime(purchase.date)}</TableCell>
                            <TableCell padding="dense">{purchase.type}</TableCell>
                            <TableCell padding="dense">{purchase.payment_method}</TableCell>
                            <TableCell align="right" padding="dense">{formatCurrency(purchase.total_paid, webposProfile.currency_code)}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </React.Fragment>
                }

                <Typography variant="h5" gutterBottom className="top-space">Card History</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="dense">Date/Time</TableCell>
                      <TableCell padding="dense">Type</TableCell>
                      <TableCell padding="dense">Description</TableCell>
                      <TableCell align="right" padding="dense">Amount</TableCell>
                      {balanceData.transactions[0] && balanceData.transactions[0].balance &&
                        <TableCell align="right" padding="dense">Balance</TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {balanceData.transactions.map((transaction, index) =>
                      <TableRow key={index}>
                        <TableCell padding="dense">{formatDateTime(transaction.date)}</TableCell>
                        <TableCell padding="dense">{transaction.type}</TableCell>
                        <TableCell padding="dense">{transaction.description}</TableCell>
                        <TableCell align="right" padding="dense">{formatCurrency(transaction.amount, webposProfile.currency_code)}</TableCell>
                        {balanceData.transactions[0] && balanceData.transactions[0].balance &&
                          <TableCell align="right" padding="dense">{formatCurrency(transaction.balance, webposProfile.currency_code)}</TableCell>
                        }
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <div className="button-container">
                  <Button variant="contained" color="secondary" className="hidden-print" onClick={() => window.print()}>Print Report</Button>
                  {balanceData.id && balanceData.delivery_sms_number &&
                    <Button variant="contained" className="hidden-print" onClick={() => {
                        if (window.confirm('Are you sure you want to resend this card via SMS to ' + balanceData.delivery_sms_number + '?')){
                          resendCard(balanceData.id);
                        }
                      }}
                    >
                      Resend SMS
                    </Button>
                  }
                </div>
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    isFetching: state.balance.isFetching,
    balanceData: state.balance.balanceData
  }
}

const mapDispatchToProps = {
  fetchBalance: (data, form) => fetchBalance(data, form),
  cancelCard: (card_id) => cancelCard(card_id),
  resendCard: (card_id) => resendCard(card_id)
};

export default connect(mapStateToProps, mapDispatchToProps)(BalancePage)
