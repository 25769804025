import React from 'react';
import { Paper, MenuList, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export default class LoadMenu extends React.Component {
  render() {
    return (
      <Paper>
        <MenuList className="menu" disablePadding>
          <NavLink to="/load" exact isActive={(match, location) => {return location.pathname == '/' || location.pathname == '/load'}} activeClassName="active">
            <MenuItem>Purchase</MenuItem>
          </NavLink>
          <NavLink to="/bulk_load" activeClassName="active">
            <MenuItem>Bulk Purchase</MenuItem>
          </NavLink>
        </MenuList>
      </Paper>
    )
  }
}
