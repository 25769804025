import axios from 'axios';
import { flashMessage } from 'redux-flash';
import download from 'downloadjs';

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

function fetchProductsRequest() {
  return {
    type: FETCH_PRODUCTS_REQUEST
  }
}

function fetchProductsSuccess(products) {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    products
  }
}

function fetchProductsFailure() {
  return {
    type: FETCH_PRODUCTS_FAILURE
  }
}

export function fetchProducts() {
  return(dispatch) => {
    dispatch(fetchProductsRequest());
    return axios.get('products')
      .then(response => {
        dispatch(fetchProductsSuccess(response.data.products));
      }).catch(error => {
        dispatch(fetchProductsFailure());
      });
  }
}

export function downloadProductData() {
  return(dispatch) => {
    return axios.get('products.csv', {responseType: 'blob'})
      .then(response => {
        download(response.data, 'products.csv');
      }).catch(error => {
        dispatch(flashMessage('Error downloading products data', {isError: true}));
      });
  }
}
