import React from 'react';
import { Modal, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { formatCurrency } from 'utils/currency';
import { withStyles } from '@material-ui/core/styles';
import { popupStyles } from 'utils/commonStyles';

export class CardPopup extends React.Component {
  render() {
    const { open, handleClose, cards, classes, webposProfile } = this.props;

    return (
      <React.Fragment>
        <Modal open={open} onClose={handleClose}>
          <div className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="dense">Delivery SMS Number</TableCell>
                  <TableCell padding="dense">Recipient First Name</TableCell>
                  <TableCell padding="dense">Recipient Last Name</TableCell>
                  <TableCell align="right" padding="dense">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cards.map((card, index) =>
                  <TableRow key={index}>
                    <TableCell padding="dense">{card.delivery_sms_number}</TableCell>
                    <TableCell padding="dense">{card.delivery_first_name}</TableCell>
                    <TableCell padding="dense">{card.delivery_last_name}</TableCell>
                    <TableCell align="right" padding="dense">{formatCurrency(card.amount, webposProfile.currency_code)}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(popupStyles)(CardPopup);
