import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAILURE = 'CREATE_INVOICE_FAILURE';
export const INVOICE_ADD_CARD = 'INVOICE_ADD_CARD';
export const INVOICE_UPDATE_CARD = 'INVOICE_UPDATE_CARD';
export const INVOICE_REMOVE_CARD = 'INVOICE_REMOVE_CARD';

function fetchInvoicesRequest() {
  return {
    type: FETCH_INVOICES_REQUEST
  }
}

function fetchInvoicesSuccess(invoices) {
  return {
    type: FETCH_INVOICES_SUCCESS,
    invoices
  }
}

function fetchInvoicesFailure() {
  return {
    type: FETCH_INVOICES_FAILURE
  }
}

function createInvoiceRequest() {
  return {
    type: CREATE_INVOICE_REQUEST
  }
}

function createInvoiceSuccess() {
  return {
    type: CREATE_INVOICE_SUCCESS
  }
}

function createInvoiceFailure(errors) {
  return {
    type: CREATE_INVOICE_FAILURE,
    errors
  }
}

function invoiceAddCardRequest() {
  return {
    type: INVOICE_ADD_CARD
  }
}

function invoiceUpdateCardRequest(id, attrs) {
  return {
    type: INVOICE_UPDATE_CARD,
    id,
    attrs
  }
}

function invoiceRemoveCardRequest(id) {
  return {
    type: INVOICE_REMOVE_CARD,
    id
  }
}

export function fetchInvoices() {
  return(dispatch) => {
    dispatch(fetchInvoicesRequest());
    return axios.get('invoices')
      .then(response => {
        dispatch(fetchInvoicesSuccess(response.data.invoices));
      }).catch(error => {
        dispatch(fetchInvoicesFailure());
      });
  }
}

export function createInvoice(data) {
  return(dispatch) => {
    dispatch(createInvoiceRequest());
    return axios.post('invoices', {invoice: data})
      .then(response => {
        dispatch(createInvoiceSuccess());
        dispatch(flashMessage('Invoice submitted successfully'));
        dispatch(push('/invoices'));
      }).catch(error => {
        dispatch(createInvoiceFailure(error.response.data.errors));
        dispatch(flashMessage('Error submitting invoice', {isError: true}));
      });
  }
}

export function invoiceAddCard() {
  return dispatch => {
    dispatch(invoiceAddCardRequest());
  }
}

export function invoiceUpdateCard(id, attrs) {
  return dispatch => {
    dispatch(invoiceUpdateCardRequest(id, attrs));
  }
}

export function invoiceRemoveCard(id) {
  return dispatch => {
    dispatch(invoiceRemoveCardRequest(id));
  }
}
