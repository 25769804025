import React from 'react';
import { connect } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import Formsy from 'formsy-react';
import TextInput from 'components/inputs/TextInput';
import LoginMenu from 'components/menus/LoginMenu';
import { sendConfirmInstructions } from 'actions/ProfileActions';

export class ResendConfirmationPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formRef = React.createRef();
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.sendConfirmInstructions(data.username, this.formRef);
  }

  render() {
    const { isProcessing } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>Resend Confirmation Instructions</Typography>

        <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
          <TextInput name="username" label="Username" compact required
            validationErrors={{
              isDefaultRequiredValue: "can't be blank"
            }}
          />

          <div className="login-button-container">
            <Button type="submit" disabled={isProcessing} variant="contained" color="primary">Resend Instructions</Button>
            <LoginMenu />
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isProcessing: state.profile.isProcessing
  }
}

const mapDispatchToProps = {
  sendConfirmInstructions: (username, formRef) => sendConfirmInstructions(username, formRef)
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmationPage)
