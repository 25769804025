import React from 'react';
import { connect } from 'react-redux';
import Alert from './Alert';
import { getLatestMessage, flashMessageType, clearMessages } from 'redux-flash';

export class FlashMessage extends React.Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.clearMessages();
  }

  render() {
    const { flash, classes } = this.props;

    if (flash) {
      const type = flash.props && flash.props.type || (flash.isError ? 'error' : 'success');

      return (
        <Alert type={type} message={flash.message} handleClose={this.handleClose} />
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    flash: getLatestMessage(state)
  };
}

const mapDispatchToProps = {
  clearMessages
};

FlashMessage.propTypes = {
  flash: flashMessageType
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);
