import React from 'react';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

export default class HelpTooltip extends React.Component {
  render() {
    const { message } = this.props;

    return (
      <Tooltip title={message}>
        <HelpIcon color="primary" className="icon" />
      </Tooltip>
    );
  }
}
