import axios from 'axios';
import { flashMessage } from 'redux-flash';
import { push } from 'connected-react-router';

export const FETCH_TRANSACTION_REQUEST = 'FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_FAILURE = 'FETCH_TRANSACTION_FAILURE';
export const RESEND_RECEIPT_EMAIL_REQUEST = 'RESEND_RECEIPT_EMAIL_REQUEST';
export const RESEND_RECEIPT_EMAIL_SUCCESS = 'RESEND_RECEIPT_EMAIL_SUCCESS';
export const RESEND_RECEIPT_EMAIL_FAILURE = 'RESEND_RECEIPT_EMAIL_FAILURE';

function fetchTransactionRequest() {
  return {
    type: FETCH_TRANSACTION_REQUEST
  }
}

function fetchTransactionSuccess(transaction) {
  return {
    type: FETCH_TRANSACTION_SUCCESS,
    transaction
  }
}

function fetchTransactionFailure() {
  return {
    type: FETCH_TRANSACTION_FAILURE
  }
}

function resendReceiptEmailRequest() {
  return {
    type: RESEND_RECEIPT_EMAIL_REQUEST
  }
}

function resendReceiptEmailSuccess() {
  return {
    type: RESEND_RECEIPT_EMAIL_SUCCESS
  }
}

function resendReceiptEmailFailure() {
  return {
    type: RESEND_RECEIPT_EMAIL_FAILURE
  }
}

export function fetchTransaction(id) {
  return(dispatch) => {
    dispatch(fetchTransactionRequest());
    return axios.get('purchases/' + id)
      .then(response => {
        dispatch(fetchTransactionSuccess(response.data.transaction));
      }).catch(error => {
        dispatch(fetchTransactionFailure());
        dispatch(flashMessage('Transaction not found', {isError: true}));
        dispatch(push('/load'));
      });
  }
}

export function resendReceiptEmail(id, email) {
  return(dispatch) => {
    dispatch(resendReceiptEmailRequest());
    return axios.post('resend_receipt', {email, id})
      .then(response => {
        dispatch(resendReceiptEmailSuccess());
        dispatch(flashMessage('Receipt has been resent to the email address'));
      }).catch(error => {
        dispatch(resendReceiptEmailFailure());
      });
  }
}
