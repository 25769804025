import {
  FETCH_DIGITAL_FLOAT_REQUEST,
  FETCH_DIGITAL_FLOAT_SUCCESS,
  FETCH_DIGITAL_FLOAT_FAILURE
} from '../actions/DigitalPartnerActions'

const initialState = {
  isFetching: false,
  digitalPartner: null
};

export default function digitalPartner(state = initialState, action) {
  switch(action.type) {
    case FETCH_DIGITAL_FLOAT_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_DIGITAL_FLOAT_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        digitalPartner: action.digitalPartner
      });
    }
    case FETCH_DIGITAL_FLOAT_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    default:
      return state;
  }
}
