import React from 'react';
import { connect } from 'react-redux';
import { CssBaseline, AppBar, Toolbar, Typography, Button, Paper } from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';
import FlashMessage from 'components/FlashMessage';
import { NavLink } from 'react-router-dom';
import { hasPermission } from 'utils/auth';
import classnames from 'classnames';
import { signOutUser } from 'actions/SessionActions';
import PersonIcon from '@material-ui/icons/Person';

const styles = theme => ({
  appBar: {
    position: 'relative',
    marginBottom: '3em'
  },
  brand: {
    paddingRight: '3em'
  },
  logo: {
    height: '50px',
    verticalAlign: 'middle'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3
  },
  footer: {
    margin: '2em 0',
    textAlign: 'center'
  }
});

export class MainLayout extends React.Component {
  renderFooter() {
    return (
      <div className="footer hidden-print">
        <Typography align="center" variant="caption" gutterBottom>Powered by Waivpay</Typography>
      </div>
    )
  }

  render() {
    const { children, currentUser, webposProfile, permissions, classes, signOutUser } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position='static' color='default' className={classnames(classes.appBar, 'hidden-print')}>
          <Toolbar className='nav-menu' style={{justifyContent: 'space-between'}}>
          <span>
            <Typography variant='h6' color='inherit' noWrap className={classes.brand} style={{display: 'inline'}}>
              <NavLink to='/' className='button-link'>
                <img src={webposProfile.menu_image} className={classes.logo} />
              </NavLink>
            </Typography>
            {hasPermission(this.props.permissions, 'create', 'Transaction') &&
              <NavLink to='/load' exact className='button-link'>
                <Button>Purchase Cards</Button>
              </NavLink>
            }
            {hasPermission(this.props.permissions, 'balance_check', 'Card') &&
              <NavLink to='/balance' exact className='button-link'>
                <Button>Search</Button>
              </NavLink>
            }
            <NavLink to='/products' exact className='button-link'>
              <Button>Products</Button>
            </NavLink>
            {hasPermission(this.props.permissions, 'read', 'Transaction') &&
              <NavLink to='/reports' exact className='button-link'>
                <Button>Reports</Button>
              </NavLink>
            }
            {hasPermission(this.props.permissions, 'read', 'InvoiceRequest') &&
              <NavLink to='/invoices' exact className='button-link'>
                <Button>Invoices</Button>
              </NavLink>
            }
            {hasPermission(this.props.permissions, 'create', 'User') &&
              <NavLink to='/users' exact className='button-link'>
                <Button>Admin</Button>
              </NavLink>
            }
            {window.EXTERNAL_SUPPORT_URL ?
              <a href={window.EXTERNAL_SUPPORT_URL} target="_blank" style={{textDecoration: 'none'}}>
                <Button>Help</Button>
              </a>
            :
              <NavLink to='/help' exact className='button-link'>
                <Button>Help</Button>
              </NavLink>
            }
            </span>
            <span>
              <NavLink to='/profile' exact className='button-link nav-link'>
                <Button>
                  <PersonIcon />
                  <Typography variant="body2" style={{margin: '10px'}}>{currentUser.first_name} {currentUser.last_name}</Typography>
                </Button>
              </NavLink>
              <Button variant='outlined' onClick={signOutUser}>Log out</Button>
            </span>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <FlashMessage />
          {children}
          {this.renderFooter()}
        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    webposProfile: state.session.webposProfile,
    permissions: state.session.permissions
  };
}


const mapDispatchToProps = {
  signOutUser
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
