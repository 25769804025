import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import { Grid, Paper, Table, TableBody, TableRow, Button, Avatar } from '@material-ui/core';
import { StyledTableCell } from 'utils/commonStyles';
import ProfileMenu from 'components/menus/ProfileMenu';
import UserIcon from '@material-ui/icons/Person';

const styles = theme => ({
  avatar: {
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
});

export class ProfilePage extends React.Component {
  render() {
    const { user, navigateTo, classes } = this.props;
    const centerStyle = {style: {textAlign: 'center'}};

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <ProfileMenu />
        </Grid>
        <Grid item md={8} className='full-width'>
          <Paper className='form-container' elevation={1}>
            <Grid container spacing={16}>
              <Grid item sm={12}>
                <Avatar className={classes.avatar}>
                  <UserIcon />
                </Avatar>
                <Table>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell {...centerStyle}>Username</StyledTableCell>
                      <StyledTableCell {...centerStyle}>{user.username}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell {...centerStyle}>First Name</StyledTableCell>
                      <StyledTableCell {...centerStyle}>{user.first_name}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell {...centerStyle}>Last Name</StyledTableCell>
                      <StyledTableCell {...centerStyle}>{user.last_name}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell {...centerStyle}>Email</StyledTableCell>
                      <StyledTableCell {...centerStyle}>{user.email}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell {...centerStyle}>Timezone</StyledTableCell>
                      <StyledTableCell {...centerStyle}>{user.timezone}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className='button-container'>
                  <Button variant='contained' onClick={() => navigateTo('/profile/edit')} className='font-medium'>Edit</Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProfilePage))
