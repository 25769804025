import {
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILURE,
  SEARCH_TRANSACTIONS_REQUEST,
  SEARCH_TRANSACTIONS_SUCCESS,
  SEARCH_TRANSACTIONS_FAILURE
} from 'actions/BalanceActions';

const initialState = {
  isFetching: false,
  balanceData: null,
  transactions: null
};

export default function balance(state = initialState, action) {
  switch(action.type) {
    case FETCH_BALANCE_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_BALANCE_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        balanceData: action.data,
      });
    }
    case FETCH_BALANCE_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false,
        balanceData: null
      });
    }
    case SEARCH_TRANSACTIONS_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SEARCH_TRANSACTIONS_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        transactions: action.transactions
      });
    }
    case SEARCH_TRANSACTIONS_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false,
        transactions: null
      });
    }

    default:
      return state;
  }
}
