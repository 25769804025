import CommonTheme from './CommonTheme';
import _ from 'lodash';
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme(_.merge({
  palette: {
    primary: {
      main: '#152284'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(',')
  }
}, CommonTheme));
