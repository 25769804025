import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Grid, Paper, Table, TableBody, TableRow, TableCell, Step, StepLabel, Stepper } from '@material-ui/core';
import InvoiceMenu from 'components/menus/InvoiceMenu';
import { formatCurrency } from 'utils/currency';
import { createInvoice, invoiceAddCard } from 'actions/InvoiceActions';
import DetailsStep from 'components/forms/invoice/DetailsStep';
import ReferenceStep from 'components/forms/invoice/ReferenceStep';

export class NewInvoicePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      formData: {}
    };

    this.formRef = React.createRef();
    this.steps = ['Details', 'Reference'];
    this.renderStep = this.renderStep.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateInvoice = this.updateInvoice.bind(this);

    if (_.isEmpty(this.props.cards)) this.props.invoiceAddCard();
  }

  componentDidUpdate() {
    const { invoiceErrors } = this.props;

    if (invoiceErrors) {
      try {
        this.formRef.current.updateInputsWithError(invoiceErrors);
      } catch(err) {}
    }
  }

  renderStep(step) {
    const { formData } = this.state;

    switch (step) {
      case 'Details':
        return (
          <DetailsStep
            handleNext={this.handleNext}
            webposProfile={this.props.webposProfile}
            updateInvoice={this.updateInvoice}
            invoiceType={formData.invoice_type}
            initialBalance={formData.initial_balance}
          />
        );
      case 'Reference':
        return (
          <ReferenceStep
            handleNext={this.handleSubmit}
            handleBack={this.handleBack}
            isProcessing={this.props.isProcessing}
            webposProfile={this.props.webposProfile}
            user={this.props.user}
            referenceNumber={formData.reference_number}
            internalReference={formData.internal_reference}
            paymentType={formData.payment_type}
            requestedEmails={formData.requested_emails}
            formRef={this.formRef}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  mapInputs(inputs) {
    return {
      ..._.omit(inputs, 'invoice_type', 'initial_balance'),
      'invoice_attributes': {
        'invoice_type': inputs.invoice_type,
        'initial_balance': inputs.initial_balance
      },
      'invoice_items_attributes': inputs.invoice_type == 'invoice' ? this.props.cards.map((card) => {return {product_id: card.product_id, value_per_card: card.value_per_card, number_of_cards: card.number_of_cards}}) : []
    };
  }

  handleNext() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  }

  handleBack() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  }

  handleSubmit(data, _data, _invalidateForm) {
    const { cards, createInvoice } = this.props;
    const { formData } = this.state;
    this.setState({ formData: _.merge(formData, data) });
    createInvoice(this.mapInputs(formData));
  }

  updateInvoice(attrs) {
    this.setState({ formData: _.merge(this.state.formData, attrs) });
  }

  cardValue() {
    return _.reduce(this.props.cards, (sum, card) => {return sum + parseFloat(card.value_per_card * card.number_of_cards || 0) }, 0);
  }

  cardFees() {
    return _.reduce(this.props.cards, (sum, card) => {return sum + parseFloat(card.card_fees * card.number_of_cards || 0) }, 0);
  }

  renderCosts(formData, webposProfile) {
    const invoiceType = formData ? formData.invoice_type : null;
    const initialBalance = formData ? formData.initial_balance : 0;
    const totalCardValue = invoiceType === 'float' ? (initialBalance || 0) : this.cardValue();
    const totalCardFees = invoiceType === 'float' ? 0 : this.cardFees();
    const cardTotal = totalCardValue + totalCardFees;
    const cardGst = this.cardFees() / 11;

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Card Value:</TableCell>
            <TableCell align="right">{formatCurrency(totalCardValue, webposProfile.currency_code)}</TableCell>
          </TableRow>
          { invoiceType !== 'float' &&
            <TableRow>
              <TableCell>Card Fees:</TableCell>
              <TableCell align="right">{formatCurrency(totalCardFees, webposProfile.currency_code)}</TableCell>
            </TableRow>
          }
          <TableRow className="font-bold">
            <TableCell>Total:</TableCell>
            { invoiceType === 'invoice' ?
              <TableCell align="right">{formatCurrency(cardTotal, webposProfile.currency_code)}</TableCell>
              :
              <TableCell align="right">{formatCurrency(totalCardValue, webposProfile.currency_code)}</TableCell>
            }
          </TableRow>
          { invoiceType !== 'float' &&
            <TableRow>
              <TableCell>GST:</TableCell>
              <TableCell align="right">{formatCurrency(cardGst, webposProfile.currency_code)}</TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    );
  }

  render() {
    const { activeStep, formData } = this.state;
    const { webposProfile } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={16}>
          <Grid item md={2} className='full-width'>
            <InvoiceMenu />
          </Grid>

          <Grid item md={8} className='full-width'>
            <Paper className='form-container' elevation={1}>
              <Stepper activeStep={activeStep}>
                {this.steps.map(label => (
                  <Step key={label}>
                    <StepLabel StepIconProps={{classes: {text: 'white-fill'}}}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Grid container spacing={8}>
                <Grid item sm={12} lg={5} className='full-width' style={{padding: '1em'}}>
                  {this.renderCosts(formData, webposProfile)}
                </Grid>
                <Grid item sm={12} lg={7} className='full-width' style={{padding: '1em'}}>
                  {this.renderStep(this.steps[activeStep])}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser,
    webposProfile: state.session.webposProfile,
    invoiceErrors: state.invoice.invoiceErrors,
    isProcessing: state.invoice.isProcessing,
    cards: state.invoice.cards
  }
}

const mapDispatchToProps = {
  invoiceAddCard,
  createInvoice: (data) => createInvoice(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoicePage)
