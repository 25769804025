import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Table, TableHead, TableBody, TableRow, CircularProgress } from '@material-ui/core';
import { StyledTableCell } from 'utils/commonStyles';
import _ from 'lodash';
import { formatCurrency } from 'utils/currency';
import InvoiceMenu from 'components/menus/InvoiceMenu';
import { fetchInvoices } from 'actions/InvoiceActions';

export class InvoicesPage extends React.Component {
  componentDidMount() {
    this.props.fetchInvoices();
  }

  render() {
    const { invoices, isFetching, webposProfile } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <InvoiceMenu />
        </Grid>
        <Grid item md={10} className="full-width">
          <Paper className="form-container" elevation={1}>
            <div className="scroll-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="dense">Invoice</StyledTableCell>
                    <StyledTableCell padding="dense">Reference Number</StyledTableCell>
                    <StyledTableCell padding="dense">Type</StyledTableCell>
                    <StyledTableCell padding="dense">Amount</StyledTableCell>
                    <StyledTableCell padding="dense">Payment Type</StyledTableCell>
                    <StyledTableCell padding="dense">Paid</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    invoices.map((invoice, index) =>
                      <TableRow key={index}>
                        <StyledTableCell padding="dense">{invoice.number}</StyledTableCell>
                        <StyledTableCell padding="dense">{invoice.reference_number}</StyledTableCell>
                        <StyledTableCell padding="dense">{invoice.type}</StyledTableCell>
                        <StyledTableCell padding="dense">{formatCurrency(invoice.current_balance, webposProfile.currency_code)}</StyledTableCell>
                        <StyledTableCell padding="dense">{invoice.payment_type}</StyledTableCell>
                        <StyledTableCell padding="dense">{invoice.paid ? 'Yes' : 'No'}</StyledTableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </div>
            {isFetching && _.isEmpty(invoices) &&
              <Grid container justify="center" className="top-space">
                <CircularProgress />
              </Grid>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.invoice.isFetching,
    invoices: state.invoice.invoices,
    webposProfile: state.session.webposProfile,
  }
}

const mapDispatchToProps = {
  fetchInvoices
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage)
