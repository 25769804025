import React from 'react';
import { connect } from 'react-redux';
import { Grid, InputAdornment, CircularProgress } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import _ from 'lodash';
import { parseCreditCard, validateCreditCard } from 'actions/LoadActions';
import { getMonthOptions, getYearOptions } from 'utils/dates';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export class CardPaymentMethodFieldset extends React.Component {
  constructor(props) {
    super(props);

    this.cardNumberKeyPressed = this.cardNumberKeyPressed.bind(this);
    this.cardNumberBlur = this.cardNumberBlur.bind(this);
  }

  cardNumberKeyPressed(event) {
    const key = event.charCode ? event.charCode : event.keyCode ? event.keyCode : 0;
    // Enter key is sent by card readers. Indicates the end of the stream of chars
    if (key === 13) {
      event.preventDefault();
      this.props.parseCreditCard(event.target.value.toLocaleUpperCase(), this.cvvInputRef, this.numberInputRef.textFieldRef);
    }
  }

  cardNumberBlur({target}) {
    if (target.value) {
      this.props.validateCreditCard(target.value, this.props.formRef);
    }
  }

  render() {
    const { creditCard, isCreditCardValidating } = this.props;

    return (
      <React.Fragment>
        <TextInput name="credit_card_number" label="Card Number" placeholder="Swipe Credit Card" autoFocus required
                   value={creditCard.number}
                   onKeyPress={this.cardNumberKeyPressed}
                   onBlur={this.cardNumberBlur}
                   inputLabelProps={{shrink: true}}
                   inputProps={{
                     endAdornment: isCreditCardValidating ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                   }}
                   validationErrors={requiredValidation}
                   innerRef={(c) => { this.numberInputRef = c; }}
        />
        <TextInput name="name" label="Card Holder Name" placeholder="Name on Card" required
                   value={creditCard.name}
                   inputLabelProps={{shrink: true}}
                   validationErrors={{isDefaultRequiredValue: "can't be blank"}}
        />

        <Grid container spacing={24}>
          <Grid item xs={3}>
            <SelectInput name="credit_card_expiry_month" label="Expiry Month" placeholder="MM" required
                         value={creditCard.expiryMonth}
                         options={getMonthOptions()}
                         validations="futureMonth:credit_card_expiry_year"
                         validationErrors={_.merge(requiredValidation, {
                          futureMonth: 'must be in future'
                         })}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectInput name="credit_card_expiry_year" label="Expiry Year" placeholder="YYYY" required
                         value={creditCard.expiryYear}
                         options={getYearOptions()}
                         validations="futureYear"
                         validationErrors={_.merge(requiredValidation, {
                          futureYear: 'must be in future'
                         })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput name="credit_card_verification_value" label="Card Security Code" placeholder="3/4 digit security code" required
                       inputLabelProps={{shrink: true}}
                       validations={{
                        minLength: 3,
                        maxLength: 4
                       }}
                       validationErrors={_.merge(requiredValidation, {
                        minLength: 'must be 3-4 digits',
                        maxLength: 'must be 3-4 digits'
                       })}
                       innerRef={(c) => { this.cvvInputRef = c; }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    creditCard: state.load.creditCard,
    isCreditCardValidating: state.load.isCreditCardValidating
  }
}

const mapDispatchToProps = {
  parseCreditCard: (value, cvvRef, numberRef) => parseCreditCard(value, cvvRef, numberRef),
  validateCreditCard: (value, formRef) => validateCreditCard(value, formRef)
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentMethodFieldset)
