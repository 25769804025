import axios from 'axios';
import { push } from 'connected-react-router';
import store from './store';
import { signOutSuccess } from 'actions/SessionActions';
import { flashMessage } from 'redux-flash';

axios.defaults.baseURL = '/api/v1/merchant';

axios.defaults.headers.common['X-CSRF-Token'] = getToken();

function getToken() {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return meta && meta.getAttribute('content');
}

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    // If any request returns unauthorized, clear current user and redirect to login
    store.dispatch(signOutSuccess());
    // Redirect via browser location in order for CSRF token to be regenerated
    // and avoid looping issue with logging in and 401s
    window.location = '/login?expired=true'
  }
  return Promise.reject(error);
});
