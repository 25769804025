import React from 'react';
import { Paper, MenuList, MenuItem, Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

// Main profile tab should be active for all profile subpages except the change password page
const isActive = (match, location) => {
  return match && location.pathname != '/profile/change_password';
}

export default class ProfileMenu extends React.Component {

  render() {
    return (
      <Paper>
        <MenuList className="menu" disablePadding>
          <NavLink to="/profile" isActive={isActive} activeClassName="active">
            <MenuItem>Profile</MenuItem>
          </NavLink>
          <Divider />
          <NavLink to="/profile/change_password" exact activeClassName="active">
            <MenuItem>Change Password</MenuItem>
          </NavLink>
        </MenuList>
      </Paper>
    )
  }
}
