import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import _ from 'lodash';
import { InputLabel, Radio, RadioGroup, FormHelperText, FormControlLabel } from '@material-ui/core';

export class RadioInput extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.target.value);
  }

  render() {
    const { name, label, options, hint, value, errorMessage, isPristine, row, disabled } = this.props;
    const isError = !isPristine && errorMessage != null;

    return (
      <div className="input-container">
        <InputLabel htmlFor={name} error={isError}>{label}</InputLabel>
        <RadioGroup
          id={name}
          name={name}
          onChange={this.changeValue}
          value={value || ""}
          row={row}
        >
          {options.map((option, i) => (
            _.isString(option) ?
              <FormControlLabel key={i} value={option} control={<Radio />} label={option} disabled={disabled} />
            :
              <FormControlLabel key={i} value={option.value} control={<Radio />} label={option.label} disabled={disabled} />
          ))}
        </RadioGroup>
        {!isPristine && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText >{hint}</FormHelperText>
        }
      </div>
    );
  }
}

RadioInput.propTypes = {
  ...propTypes
};

export default withFormsy(RadioInput);
