import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import LoginLayout from '../layouts/LoginLayout';
import { Typography, Button } from '@material-ui/core';

export class NotFoundPage extends React.Component {
  render() {
    return (
      <LoginLayout>
        <div className="center">
          <Typography variant="body1" className="below-space">
            Sorry, couldn't find the page you were looking for.
          </Typography>
          <Button variant="contained" color="primary" onClick={() => this.props.navigateTo('/')}>Go Back Home</Button>
        </div>
      </LoginLayout>
    );
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
};

export default connect(null, mapDispatchToProps)(NotFoundPage);
