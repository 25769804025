import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';

export class HelpPage extends React.Component {
  render() {
    const { webposProfile } = this.props;

    return (
      <Grid container spacing={16} justify="center">
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Help</Typography>
            <Typography variant="subtitle1" align="center">GiVVPOS V{webposProfile.version}</Typography>

            <Typography paragraph>
              <a href="https://givv.sharepoint.com/_layouts/15/guestaccess.aspx?guestaccesstoken=PZETkWIsmyw1GWOXpmGC%2fCOrhIPls9m1euNNmaFZSc8%3d&docid=02f066992f64c44cd8421d171f0a90d1e" target="_blank">
                Click here for Training Presentation
              </a>
            </Typography>

            <Typography>
              Email <a href={'mailto:' + webposProfile.support_email} target="_top">{webposProfile.support_email}</a> or phone {webposProfile.support_phone} for further assistance.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile
  }
}

export default connect(mapStateToProps)(HelpPage)
