import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button, Table,
         TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, Divider } from '@material-ui/core';
import SelectInput from 'components/inputs/SelectInput';
import _ from 'lodash';
import { formatCurrency } from 'utils/currency';
import { formatDate } from 'utils/dates';
import ReportMenu from 'components/menus/ReportMenu';
import { hasPermission } from 'utils/auth';
import { fetchInvoiceReportData, downloadInvoiceReportData } from 'actions/ReportActions';

export class InvoiceReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 10
    };

    this.formRef = React.createRef();
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateTransactions = this.updateTransactions.bind(this);
  }

  handleChangePage(_event, page) {
    this.setState({page}, this.updateTransactions);
  }

  handleChangeRowsPerPage(event) {
    this.setState({page: 0, rowsPerPage: event.target.value}, this.updateTransactions);
  }

  handleSubmit() {
    this.updateTransactions();
  }

  updateTransactions() {
    const { page, rowsPerPage } = this.state;
    this.props.fetchInvoiceReportData(this.formRef.current.getModel(), page + 1, rowsPerPage, this.formRef.current);
  }

  render() {
    const { invoices, isFetching, invoiceData, downloadInvoiceReportData, permissions, webposProfile } = this.props;
    const { page, rowsPerPage } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ReportMenu />
        </Grid>
        {/* Set min-height so page is always big enough should the invoice dropdown be shown */}
        <Grid item md={8} className="full-width" style={{minHeight: '500px'}}>
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Invoice Summary Report</Typography>
            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={4} className="full-width">
                  <SelectInput name="invoice_id" label="Invoice Number" required
                    options={_.map(invoices, (invoice) => { return {label: invoice.number, value: invoice.id} })}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Search</Button>
                </Grid>
              </Grid>
            </Formsy>

            {invoiceData &&
              <React.Fragment>
                <Grid container spacing={16} className="top-space" justify="center">
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Invoice Initial Balance</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(invoiceData.initial_balance, webposProfile.currency_code)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Invoice Current Balance</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(invoiceData.current_balance, webposProfile.currency_code)}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={16} className="top-space" justify="center">
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1">Total Card Quantity</Typography>
                    <Typography variant="h5" color="primary">{invoiceData.card_quantity}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Card Value</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(invoiceData.card_value, webposProfile.currency_code)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Fees</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(invoiceData.total_fees, webposProfile.currency_code)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1">Total Cost</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(invoiceData.total_cost, webposProfile.currency_code)}</Typography>
                  </Grid>
                </Grid>

                <Divider className="report-divider" />

                <div className="scroll-container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="dense">Transaction ID</TableCell>
                        <TableCell padding="dense">Date</TableCell>
                        <TableCell padding="dense">User</TableCell>
                        <TableCell padding="dense">Status</TableCell>
                        <TableCell padding="dense">Reference</TableCell>
                        <TableCell align="right" padding="dense">Card Quantity</TableCell>
                        <TableCell align="right" padding="dense">Card Value</TableCell>
                        <TableCell align="right" padding="dense">Fees</TableCell>
                        <TableCell align="right" padding="dense">Cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceData.transactions.map((transaction, index) =>
                        <TableRow key={index}>
                          <TableCell padding="dense">{transaction.id}</TableCell>
                          <TableCell padding="dense">{formatDate(transaction.date)}</TableCell>
                          <TableCell padding="dense">{transaction.user}</TableCell>
                          <TableCell padding="dense">{transaction.status}</TableCell>
                          <TableCell padding="dense">{transaction.reference}</TableCell>
                          <TableCell align="right" padding="dense">{transaction.card_quantity}</TableCell>
                          <TableCell align="right" padding="dense">{formatCurrency(transaction.total_value, webposProfile.currency_code)}</TableCell>
                          <TableCell align="right" padding="dense">{formatCurrency(transaction.total_fees, webposProfile.currency_code)}</TableCell>
                          <TableCell align="right" padding="dense">{formatCurrency(transaction.total_paid, webposProfile.currency_code)}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={9}
                          count={invoiceData.transaction_count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>

                <Typography variant="body1" className="top-space">
                  Download all results in <button onClick={() => downloadInvoiceReportData(this.formRef.current.getModel())} className="raw-button-link">Excel</button>
                </Typography>
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoices: state.session.webposProfile.invoices,
    isFetching: state.report.isFetching,
    invoiceData: state.report.invoiceData,
    permissions: state.session.permissions,
    webposProfile: state.session.webposProfile
  }
}

const mapDispatchToProps = {
  fetchInvoiceReportData: (data, page, perPage, form) => fetchInvoiceReportData(data, page, perPage, form),
  downloadInvoiceReportData: (data) => downloadInvoiceReportData(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReportPage)
