import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { fetchCurrentUser } from 'actions/SessionActions';
import store, { history } from 'utils/store';
import Router from './Router';
import AppTheme from '../themes/GivvTheme';

class App extends React.Component {
  constructor() {
    super();

    store.dispatch(fetchCurrentUser());
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={AppTheme}>
            <CssBaseline />
            <Router/>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default App;
