import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Card, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import PurchaseIcon from '@material-ui/icons/AttachMoney';
import BalanceIcon from '@material-ui/icons/LocalAtm';
import ReportIcon from '@material-ui/icons/Assessment';
import { hasPermission } from 'utils/auth';

export class HomePage extends React.Component {
  render() {
    const { permissions } = this.props;

    return (
      <Grid container spacing={16} justify="center">
        <Grid item md={8} align="center" className="full-width">
          <Paper className="form-container" elevation={1}>
            <div>
              <Typography variant="h4" gutterBottom>Welcome to</Typography>
              <img src={require('images/givvpos.png')} style={{width: '500px', maxWidth: '100%'}} />
            </div>

            {hasPermission(permissions, 'create', 'Transaction') &&
              <NavLink to="/load">
                <Card className="home-card">
                  <PurchaseIcon color="primary" className="icon" />
                  <Typography variant="h5">Purchase Cards</Typography>
                </Card>
              </NavLink>
            }
            {hasPermission(permissions, 'balance_check', 'Card') &&
              <NavLink to="/balance">
                <Card className="home-card">
                  <BalanceIcon color="primary" className="icon" />
                  <Typography variant="h5">Balance Check</Typography>
                </Card>
              </NavLink>
            }
            {hasPermission(permissions, 'read', 'Transaction') &&
              <NavLink to="/reports">
                <Card className="home-card">
                  <ReportIcon color="primary" className="icon" />
                  <Typography variant="h5">Reports</Typography>
                </Card>
              </NavLink>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.session.permissions
  }
}

export default connect(mapStateToProps)(HomePage);
