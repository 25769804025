import React from 'react';
import { connect } from 'react-redux';
import { propTypes, withFormsy } from 'formsy-react';
import _ from 'lodash';
import axios from 'axios';
import { InputLabel, TextField, FormHelperText, Button, CircularProgress } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { DirectUploadProvider } from 'react-activestorage-provider';
import { validateBulkDigitalFile } from 'actions/LoadActions';

export class FileInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filename: null,
      fileError: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(response) {
    const { setValue, onChange } = this.props;

    this.props.validateBulkDigitalFile(_.merge({}, {signed_id: response[0]}), (response) => {
      this.setState({filename: response.data.filename, fileError: null}, onChange && onChange(response.data.key, response.data.url));
      setValue(response.data.key);
      }, (error) => {
        this.setState({filename: null, fileError: error.response.data.error}, onChange && onChange(null, null));
        setValue('');
      }
    );
  }

  truncate(string, length) {
    if(string && string.length > length) {
      return string.substring(0, length) + '...';
    }

    return string;
  }

  render() {
    const { name, label, buttonLabel, inputLabelProps, hint, errorMessage, isPristine } = this.props;
    const errorMessageDisplay = this.state.fileError || errorMessage;
    const isError = !isPristine && errorMessage != null;

    return (
      <div className='input-container file-input-container'>
        <DirectUploadProvider
          endpoint={{
            attribute: 'signed_id',
            method: 'POST'
          }}
          onSuccess={this.handleSubmit}
          render={({ handleUpload, uploads, ready }) => (
            <div>
              <InputLabel htmlFor={name} className='file-label' error={isError}>{label}</InputLabel>
              <TextField
                type='file'
                disabled={!ready}
                id={name}
                name={name}
                onChange={e => handleUpload(e.currentTarget.files)}
                fullWidth
                style={{display: 'none'}}
              />

              <label htmlFor={name}>
                <Button variant='contained' component='span'>
                  <AttachmentIcon className='attachment-icon' />
                  {this.truncate(this.state.filename, 20) || buttonLabel || 'Upload'}

                  {uploads[0] && _.includes(['waiting', 'uploading'], uploads[0].state) &&
                    <CircularProgress size={20} className='upload-progress' />
                  }
                </Button>
              </label>

              {!isPristine && errorMessageDisplay &&
                <FormHelperText error={isError}>{errorMessageDisplay}</FormHelperText>
              }
              {!isPristine && uploads[0] && uploads[0].state == 'error' &&
                <FormHelperText error={isError}>{uploads[0].error}</FormHelperText>
              }
              {hint &&
                <FormHelperText>{hint}</FormHelperText>
              }
            </div>
          )}
        />
      </div>
    );
  }
}

FileInput.propTypes = {
  ...propTypes
};

const mapDispatchToProps = {
  validateBulkDigitalFile: (data, onSuccess, onFailure) => validateBulkDigitalFile(data, onSuccess, onFailure)
};

export default connect(null, mapDispatchToProps)(withFormsy(FileInput))
