import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { FormHelperText } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers'
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

export class DateInput extends React.Component {
  constructor(props) {
    super(props);

    this.props.setValue(this.props.value, false);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.format(dateFormat));
    this.setState({ value: event });
  }

  render() {
    const { name, label, hint, inputProps, value, errorMessage, isPristine,
            disableFuture, shouldDisableDate, startDate, endDate, withCalendar, includeYear } = this.props;
    const isError = !isPristine && errorMessage != null;

    return (
      <div className="input-container">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <InlineDatePicker
            autoOk
            onlyCalendar={!withCalendar}
            openTo={includeYear ? 'year' : null}
            disableFuture={disableFuture}
            error={isError}
            id={name}
            name={name}
            label={label}
            value={value ? moment(value, dateFormat) : null}
            onChange={this.changeValue}
            format={dateFormat}
            shouldDisableDate={shouldDisableDate}
            InputProps={inputProps}
            fullWidth
            minDate={startDate || '1900-01-01'}
            maxDate={endDate || '2100-01-01' }
          />
        </MuiPickersUtilsProvider>
        {!isPristine && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

DateInput.propTypes = {
  ...propTypes
};

export default withFormsy(DateInput);
