import {
  VALIDATE_STORED_CARD_REQUEST,
  VALIDATE_STORED_CARD_SUCCESS,
  VALIDATE_STORED_CARD_ERROR,
  VALIDATE_SERIAL_SUCCESS,
  VALIDATE_CREDIT_CARD_REQUEST,
  VALIDATE_CREDIT_CARD_SUCCESS,
  VALIDATE_CREDIT_CARD_ERROR,
  ADD_CARD,
  UPDATE_CARD,
  REMOVE_CARD,
  ADD_SERIAL,
  UPDATE_SERIAL,
  REMOVE_SERIAL,
  LOAD_CARD_REQUEST,
  LOAD_CARD_SUCCESS,
  LOAD_CARD_ERROR,
  PARSE_CREDIT_CARD_START,
  PARSE_CREDIT_CARD_COMPLETE,
  BULK_DIGITAL_SUCCESS
} from 'actions/LoadActions';

const initialState = {
  isProcessing: false,
  isCreditCardValidating: false,
  cards: [],
  serials: [],
  creditCard: {},
  formData : {},
  creditCardFee: null
};

export default function load(state = initialState, action) {
  switch(action.type) {
    case ADD_CARD : {
      return Object.assign({}, state, {
        cards: state.cards.concat({id: new Date().getTime(), card_number: null, amount: null, product_type: 'physical'})
      });
    }
    case UPDATE_CARD : {
      const newCards = state.cards.map((card) => {
        if (card.id != action.id) return card;
        return Object.assign({}, {...card}, action.attrs);
      });

      return Object.assign({}, state, {
        cards: newCards,
        fee: action.cardFee
      });
    }
    case REMOVE_CARD : {
      return Object.assign({}, state, {
        cards: state.cards.filter((card) => card.id != action.id)
      });
    }
    case ADD_SERIAL : {
      return Object.assign({}, state, {
        serials: state.serials.concat({id: new Date().getTime(), first_serial: null, last_serial: null, amount: null})
      });
    }
    case UPDATE_SERIAL : {
      const newSerials = state.serials.map((serial) => {
        if (serial.id != action.id) return serial;
        return Object.assign({}, {...serial}, action.attrs);
      });

      return Object.assign({}, state, {
        serials: newSerials
      });
    }
    case REMOVE_SERIAL : {
      return Object.assign({}, state, {
        serials: state.serials.filter((serial) => serial.id != action.id)
      });
    }
    case VALIDATE_SERIAL_SUCCESS : {
      const newSerials = state.serials.map((serial) => {
        if (serial.id != action.id) return serial;
        return Object.assign({}, {...serial}, {isValidating: false, first_serial: action.first, last_serial: action.last,
                                               fee: action.fee, firstError: false, lastError: false});
      });

      return Object.assign({}, state, {serials: newSerials});
    }
    case VALIDATE_STORED_CARD_REQUEST : {
      const newCards = state.cards.map((card) => {
        if (card.id != action.id) return card;
        return Object.assign({}, {...card}, {isValidating: true});
      });

      return Object.assign({}, state, {
        cards: newCards
      });
    }
    case VALIDATE_STORED_CARD_SUCCESS : {
      const newCards = state.cards.map((card) => {
        if (card.id != action.id) return card;
        return Object.assign({}, {...card}, {isValidating: false, fee: action.cardFee, product: action.product, error: null});
      });

      return Object.assign({}, state, {cards: newCards});
    }
    case VALIDATE_STORED_CARD_ERROR : {
      const newCards = state.cards.map((card) => {
        if (card.id != action.id) return card;
        return Object.assign({}, {...card}, {isValidating: false, fee: 0, error: action.cardError});
      });

      return Object.assign({}, state, {cards: newCards});
    }
    case LOAD_CARD_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case LOAD_CARD_SUCCESS : {
      return initialState;
    }
    case LOAD_CARD_ERROR : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case PARSE_CREDIT_CARD_START : {
      return Object.assign({}, state, {
        creditCard: null
      })
    }
    case PARSE_CREDIT_CARD_COMPLETE : {
      return Object.assign({}, state, {
        creditCard: action.creditCard
      })
    }
    case VALIDATE_CREDIT_CARD_REQUEST : {
      return Object.assign({}, state, {
        isCreditCardValidating: true,
        creditCardFee: null
      })
    }
    case VALIDATE_CREDIT_CARD_SUCCESS : {
      return Object.assign({}, state, {
        isCreditCardValidating: false,
        creditCardFee: action.creditCardFee
      })
    }
    case VALIDATE_CREDIT_CARD_ERROR : {
      return Object.assign({}, state, {
        isCreditCardValidating: false,
        creditCardFee: null
      })
    }
    case BULK_DIGITAL_SUCCESS : {
      return Object.assign({}, state, {
        cards: action.cards
      })
    }
    default:
      return state;
  }
}
