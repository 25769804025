import {
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  UPDATE_INVOICE_BALANCE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS
} from 'actions/SessionActions';

const initialState = {
  isFetching: null,
  isProcessing: false,
  currentUser: null,
  webposProfile: null,
  permissions: null
};

export default function session(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        currentUser: null
      });
    }
    case FETCH_CURRENT_USER_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        currentUser: action.user,
        webposProfile: action.webposProfile,
        permissions: action.permissions
      });
    }
    case FETCH_CURRENT_USER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        currentUser: null
      });
    }
    case UPDATE_INVOICE_BALANCE: {
      return Object.assign({}, state, {
        webposProfile: Object.assign({}, state.webposProfile, {
          invoices: state.webposProfile.invoices.map(invoice => {
            if (invoice.id === action.invoice.id) {
              return action.invoice;
            }
            return invoice;
          }
        )})
      });
    }
    case SIGN_IN_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true,
        currentUser: null
      });
    }
    case SIGN_IN_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        currentUser: action.user,
        webposProfile: action.webposProfile,
        permissions: action.permissions
      });
    }
    case UPDATE_PROFILE_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case UPDATE_PROFILE_SUCCESS : {
      return Object.assign({}, state, {
        isProcessing: false,
        currentUser: action.user
      });
    }
    case UPDATE_PROFILE_FAILURE : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case SIGN_IN_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false,
        currentUser: null
      });
    }
    case SIGN_OUT_SUCCESS: {
      return Object.assign({}, state, {
        currentUser: null,
        webposProfile: null,
        permissions: null
      });
    }
    case SIGN_OUT_FAILURE: {
      return state;
    }
    case CREATE_PASSWORD_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case RESET_PASSWORD_SUCCESS :
    case CREATE_PASSWORD_SUCCESS : {
      return Object.assign({}, state, {
        isProcessing: false,
        currentUser: action.user,
        webposProfile: action.webposProfile,
        permissions: action.permissions
      });
    }
    case CREATE_PASSWORD_FAILURE : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    default:
      return state;
  }
}
