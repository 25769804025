import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';
import download from 'downloadjs';

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const CREATE_UPDATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_UPDATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_UPDATE_USER_FAILURE = "CREATE_USER_FAILURE";

function fetchUsersRequest() {
  return {
    type: FETCH_USERS_REQUEST
  }
}

function fetchUsersSuccess(users) {
  return {
    type: FETCH_USERS_SUCCESS,
    users
  }
}

function fetchUsersFailure() {
  return {
    type: FETCH_USERS_FAILURE
  }
}

function createUpdateUserRequest() {
  return {
    type: CREATE_UPDATE_USER_REQUEST
  }
}

function createUpdateUserSuccess() {
  return {
    type: CREATE_UPDATE_USER_SUCCESS
  }
}

function createUpdateUserFailure(errors) {
  return {
    type: CREATE_UPDATE_USER_FAILURE,
    errors
  }
}

export function fetchUsers() {
  return(dispatch) => {
    dispatch(fetchUsersRequest());
    return axios.get('users')
      .then(response => {
        dispatch(fetchUsersSuccess(response.data.users));
      }).catch(error => {
        dispatch(fetchUsersFailure());
      });
  }
}

export function createUser(data) {
  return(dispatch) => {
    dispatch(createUpdateUserRequest());
    return axios.post('users', {user: data})
      .then(response => {
        dispatch(createUpdateUserSuccess());
        dispatch(flashMessage('User added successfully'));
        dispatch(fetchUsers()); // Force update of users
        dispatch(push('/users'));
      }).catch(error => {
        dispatch(createUpdateUserFailure(error.response.data.errors));
        dispatch(flashMessage('Error creating user', {isError: true}));
      });
  }
}

export function updateUser(id, data) {
  return(dispatch) => {
    dispatch(createUpdateUserRequest());
    return axios.put('users/' + id, {user: data})
      .then(response => {
        dispatch(createUpdateUserSuccess());
        dispatch(flashMessage('User updated successfully'));
        dispatch(fetchUsers()); // Force update of users
        dispatch(push('/users'));
      }).catch(error => {
        dispatch(createUpdateUserFailure(error.response.data.errors));
        dispatch(flashMessage('Error updating user', {isError: true}));
      });
  }
}

export function updateUserStatus(userId, toArchive) {
  return(dispatch) => {
    return axios.put('users/' + userId + '/update_status', {archive: toArchive})
      .then(response => {
        dispatch(flashMessage(`User has been ${toArchive ? 'archived' : 'activated'} successfully`));
        dispatch(fetchUsers()); // Force update of users
      }).catch(error => {
        dispatch(flashMessage('Unable to update user status. Please try again later', {isError: true}));
      });
  }
}

export function downloadUserData() {
  return(dispatch) => {
    return axios.get('users.csv', {responseType: 'blob'})
      .then(response => {
        download(response.data, 'users.csv');
      }).catch(error => {
        dispatch(flashMessage('Error downloading user data', {isError: true}));
      });
  }
}
