import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button, InputAdornment, Table, TableHead, TableBody, TableRow, TableCell,
         ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, AppBar, Tabs, Tab, CircularProgress } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateInput from 'components/inputs/DateInput';
import SelectInput from 'components/inputs/SelectInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import moment from 'moment';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatCurrency } from 'utils/currency';
import ReportMenu from 'components/menus/ReportMenu';
import { hasPermission } from 'utils/auth';
import { fetchOrganisationReportData, fetchOrganisationReportGroupData, downloadOrganisationReportData,
         fetchCompanyReportData, fetchCompanyOrganisationReportData, downloadCompanyReportData } from 'actions/ReportActions';

export class OrganisationReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      organisationTab: 0,
      groupTab: 0
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(this);
    this.handleOrganisationTabChange = this.handleOrganisationTabChange.bind(this);
    this.handleGroupTabChange = this.handleGroupTabChange.bind(this);
    this.handleGroupSelection = this.handleGroupSelection.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.formRef = React.createRef();
  }

  handleSubmit(data) {
    if (data.full_admin_report == '1') {
      this.props.fetchCompanyReportData(data);
    } else {
      this.props.fetchOrganisationReportData(data);
    }
  }

  handleExpansionPanelChange(_event, expanded) {
    this.setState({open: expanded});
  }

  handleOrganisationTabChange(_event, value) {
    this.setState({organisationTab: value});
  }

  handleGroupTabChange(_event, value) {
    this.setState({groupTab: value});
  }

  handleGroupSelection(event) {
    const data = _.merge(this.formRef.current.getModel(), {group_id: event.target.value});
    if (data.full_admin_report == '1') {
      this.props.fetchCompanyOrganisationReportData(data);
    } else {
      this.props.fetchOrganisationReportGroupData(data);
    }
  }

  handleDownload() {
    const data = this.formRef.current.getModel();
    if (data.full_admin_report == '1') {
      this.props.downloadCompanyReportData(data);
    } else {
      this.props.downloadOrganisationReportData(data);
    }
  }

  renderGraphSummary(label, data) {
    const { webposProfile } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item sm={12} md={6}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              title: {text: 'Sales By ' + label},
              chart: {type: 'pie', plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  showInLegend: true
                }
              },
              series: [{
                name: 'Sales',
                colorByPoint: true,
                data: data
              }],
              credits: {enabled: false}
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Table style={{marginTop: '3em'}}>
            <TableHead>
              <TableRow>
                <TableCell padding="dense">{label}</TableCell>
                <TableCell align="right" padding="dense">Card Sales</TableCell>
                <TableCell align="right" padding="dense">Average Card Value</TableCell>
                <TableCell align="right" padding="dense">Total Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) =>
                <TableRow key={index}>
                  <TableCell padding="dense">{row.name}</TableCell>
                  <TableCell align="right" padding="dense">{row.y}</TableCell>
                  <TableCell align="right" padding="dense">{formatCurrency(row.average_value, webposProfile.currency_code)}</TableCell>
                  <TableCell align="right" padding="dense">{formatCurrency(row.total_value, webposProfile.currency_code)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { isFetching, organisationData, organisationGroupData, permissions, webposProfile } = this.props;
    const { open, organisationTab, groupTab } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ReportMenu />
        </Grid>
        {/* Set min-height so page is always big enough should the date dropdown be shown */}
        <Grid item md={8} className="full-width" style={{minHeight: '500px'}}>
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Organisation Report</Typography>
            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_from" label="Date From" disableFuture required
                    value={moment().format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_to" label="Date To" disableFuture required
                    value={moment().format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validations="dateSameOrAfter:date_from"
                    validationErrors={{
                      dateSameOrAfter: 'must be after from date',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />
                </Grid>
              </Grid>
              {hasPermission(permissions, 'report', 'Organisation') &&
                <Grid container spacing={16} justify="center">
                  <Grid item sm={10} md={6} className="full-width">
                    <CheckboxInput name="full_admin_report" label="Full organisation overview?" />
                  </Grid>
                </Grid>
              }
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Search</Button>
                </Grid>
              </Grid>
            </Formsy>

            {organisationData &&
              <div style={{marginTop: '1em'}}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    title: {text: 'Sales By Group'},
                    chart: {type: 'pie', plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
                    plotOptions: {
                      pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        showInLegend: true
                      }
                    },
                    series: [{
                      name: 'Sales',
                      colorByPoint: true,
                      data: organisationData.group
                    }],
                    credits: {enabled: false}
                  }}
                />

                <ExpansionPanel expanded={open} onChange={this.handleExpansionPanelChange} style={{marginTop: '1em', marginBottom: '1em'}}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor: 'lightgrey'}}>
                    <Typography>Show Extended Data</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{flexWrap: 'wrap'}}>
                    <div className="scroll-container">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="dense">Group</TableCell>
                            <TableCell align="right" padding="dense">Card Sales</TableCell>
                            <TableCell align="right" padding="dense">Average Card Value</TableCell>
                            <TableCell align="right" padding="dense">Total Value</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {organisationData.group_data.map((row, index) =>
                            <TableRow key={index}>
                              <TableCell padding="dense">{row.name}</TableCell>
                              <TableCell align="right" padding="dense">{row.count}</TableCell>
                              <TableCell align="right" padding="dense">{formatCurrency(row.average_value, webposProfile.currency_code)}</TableCell>
                              <TableCell align="right" padding="dense">{formatCurrency(row.total_value, webposProfile.currency_code)}</TableCell>
                            </TableRow>
                          )}
                          <TableRow className="font-bold">
                            <TableCell align="right" padding="dense">Total</TableCell>
                            <TableCell align="right" padding="dense">{organisationData.group_totals.count}</TableCell>
                            <TableCell align="right" padding="dense">{formatCurrency(organisationData.group_totals.average, webposProfile.currency_code)}</TableCell>
                            <TableCell align="right" padding="dense">{formatCurrency(organisationData.group_totals.value, webposProfile.currency_code)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <Typography variant="body1" align="left" className="below-space hidden-print">
                  Download summary as <button onClick={this.handleDownload} className="raw-button-link">Excel</button>
                </Typography>

                <AppBar position='static'>
                  <Tabs variant="fullWidth" value={organisationTab} onChange={this.handleOrganisationTabChange}>
                    <Tab label='Type' />
                    <Tab label='Payment Type' />
                    <Tab label='Credit Card Type' />
                  </Tabs>
                </AppBar>
                <div className="full-width" style={{padding: '1em'}}>
                  <div className="scroll-container">
                    { organisationTab === 0 && this.renderGraphSummary('Type', organisationData.type) }
                    { organisationTab === 1 && this.renderGraphSummary('Payment Type', organisationData.payment_type) }
                    {organisationTab === 2 &&
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          title: {text: 'Sales By Credit Card Type'},
                          chart: {type: 'pie', plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
                          plotOptions: {
                            pie: {
                              allowPointSelect: true,
                              cursor: 'pointer',
                              showInLegend: true
                            }
                          },
                          series: [{
                            name: 'Sales',
                            colorByPoint: true,
                            data: organisationData.credit_card_type
                          }],
                          credits: {enabled: false}
                        }}
                      />
                    }
                  </div>
                </div>

                <Grid container spacing={16} justify="center">
                  <Grid item sm={12} md={4}>
                    <Formsy>
                      <SelectInput name="group_id" label="Filter By Site" options={organisationData.groups} onChange={this.handleGroupSelection} />
                    </Formsy>
                  </Grid>
                </Grid>

                {organisationGroupData &&
                  <Grid container spacing={16} justify="center">
                    {isFetching ?
                      <CircularProgress />
                    :
                      <React.Fragment>
                        <AppBar position='static'>
                          <Tabs variant="fullWidth" value={groupTab} onChange={this.handleGroupTabChange}>
                            <Tab label='Type' />
                            <Tab label='Payment Type' />
                            <Tab label='Credit Card Type' />
                          </Tabs>
                        </AppBar>
                        <div className="full-width" style={{padding: '1em'}}>
                          <div className="scroll-container">
                            { groupTab === 0 && this.renderGraphSummary('Type', organisationGroupData.type) }
                            { groupTab === 1 && this.renderGraphSummary('Payment Type', organisationGroupData.payment_type) }
                            {groupTab === 2 &&
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                  title: {text: 'Group Sales By Credit Card Type'},
                                  chart: {type: 'pie', plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
                                  plotOptions: {
                                    pie: {
                                      allowPointSelect: true,
                                      cursor: 'pointer',
                                      showInLegend: true
                                    }
                                  },
                                  series: [{
                                    name: 'Sales',
                                    colorByPoint: true,
                                    data: organisationGroupData.credit_card_type
                                  }],
                                  credits: {enabled: false}
                                }}
                              />
                            }
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  </Grid>
                }

              </div>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.report.isFetching,
    organisationData: state.report.organisationData,
    organisationGroupData: state.report.organisationGroupData,
    permissions: state.session.permissions,
    webposProfile: state.session.webposProfile
  }
}

const mapDispatchToProps = {
  fetchOrganisationReportData: (data) => fetchOrganisationReportData(data),
  fetchOrganisationReportGroupData: (data) => fetchOrganisationReportGroupData(data),
  downloadOrganisationReportData: (data) => downloadOrganisationReportData(data),
  fetchCompanyReportData: (data) => fetchCompanyReportData(data),
  fetchCompanyOrganisationReportData: (data) => fetchCompanyOrganisationReportData(data),
  downloadCompanyReportData: (data) => downloadCompanyReportData(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationReportPage)
