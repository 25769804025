import React from 'react';
import { Paper, MenuList, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export default class ProductsMenu extends React.Component {
  render() {
    return (
      <Paper>
        <MenuList className="menu" disablePadding>
          <NavLink to="/products" exact activeClassName="active">
            <MenuItem>Products</MenuItem>
          </NavLink>
        </MenuList>
      </Paper>
    )
  }
}
