import React from 'react';
import { connect } from 'react-redux';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';

export class InvoicePaymentMethodFieldset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceError: null
    };

    this.handleInvoiceChange = this.handleInvoiceChange.bind(this);
  }

  componentDidUpdate() {
    if (this.state.invoiceError) {
      this.props.formRef.current.updateInputsWithError({invoice_id: this.state.invoiceError});
    }
  }

  handleInvoiceChange({target}) {
    const { invoices, cardCost, totalCost } = this.props;

    const invoice = invoices.find(i => i.id == target.value);
    if (invoice) {
      let amount = 0;
      if (invoice.type === 'invoice') {
        amount = totalCost;
      } else if (invoice.type === 'float') {
        amount = cardCost;
      }

      if (parseFloat(invoice.balance) < amount) {
        this.setState({invoiceError: 'not enough funds in invoice'});
        return;
      }
    }
    this.setState({invoiceError: null});
  }

  render() {
    const { invoices } = this.props;

    const options = invoices.map((invoice) => {
      return { value: invoice.id, label: invoice.name };
    });

    return (
      <React.Fragment>
        <SelectInput name="invoice_id" label="Invoice Number" required
                     options={options}
                     onChange={this.handleInvoiceChange}
                     validationErrors={{isDefaultRequiredValue: "can't be blank"}}
        />
        <TextInput name="name" label="Transaction Reference" />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoices: state.session.webposProfile.invoices
  }
}

export default connect(mapStateToProps)(InvoicePaymentMethodFieldset);
