import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Grid, Button } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import { getTimezones, determineTimezone } from 'utils/timezones';

export class ProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.navigateTo('/profile');
  }

  render() {
    const { handleSubmit, isProcessing, timezones, user } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleSubmit}>
          <Grid container spacing={16}>
            <Grid item sm={12} md={6}>
              <TextInput name='username' label='Username' compact required
                value={user.username}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput type='email' name='email' label='Email' compact required
                value={user.email}
                validations='isEmail'
                validationErrors={{
                  isEmail: 'not a valid email',
                  isDefaultRequiredValue: "can't be blank"
                }}
                disabled={true}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput name='first_name' label='First Name' compact required
                value={user.first_name}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput name='last_name' label='Last Name' compact required
                value={user.last_name}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <SelectInput name='timezone' label='Time Zone' required
                options={timezones}
                value={user.timezone}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>
          </Grid>
          <div className='button-container'>
            <Button variant='contained' disabled={isProcessing} onClick={this.handleBack}>Back</Button>
            <Button type='submit' disabled={isProcessing} variant='contained' color='secondary'>Update</Button>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

ProfileForm.defaultProps = {
  timezones: getTimezones()
};

function mapStateToProps(state) {
  return {
    isProcessing: state.profile.isProcessing,
    user: state.session.currentUser
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
