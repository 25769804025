import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import UserMenu from 'components/menus/UserMenu';
import { flashErrorMessage } from 'redux-flash';
import UserForm from 'components/forms/UserForm';
import { fetchUsers, updateUser } from 'actions/UserActions';

export class EditUserPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.users)) this.props.fetchUsers();
  }

  componentDidUpdate() {
    const { isFetching, flashErrorMessage, navigateTo } = this.props;

    if (!isFetching && !this.user()) {
      flashErrorMessage('User not found', {isError: true});
      navigateTo('/users');
    }
  }

  user() {
    const { users, match } = this.props;

    return _.find(users, (u) => { return u.id == match.params.id });
  }

  handleSubmit(data, _data, _invalidateForm) {
    const { updateUser, match } = this.props;

    updateUser(match.params.id, data);
  }

  render() {
    const { isFetching } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <UserMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center">Edit User</Typography>
            {isFetching ?
              <Grid container justify="center" className="top-space">
                <CircularProgress />
              </Grid>
            :
              <UserForm user={this.user()} handleSubmit={this.handleSubmit} />
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.user.isFetching,
    users: state.user.users
  }
}

const mapDispatchToProps = {
  fetchUsers,
  updateUser: (id, data) => updateUser(id, data),
  navigateTo: (path) => push(path),
  flashErrorMessage: (message) => flashErrorMessage(message)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage)
