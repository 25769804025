import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import _ from 'lodash';
import { Grid, Button, IconButton, InputAdornment, CircularProgress, Chip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TextInput from 'components/inputs/TextInput';
import RadioInput from 'components/inputs/RadioInput';
import SelectInput from 'components/inputs/SelectInput';
import { formatCurrency, currencySymbol } from 'utils/currency';
import { addCard, updateCard, removeCard, parseCard, validateStoredCard } from 'actions/LoadActions';
import { flashMessage } from 'redux-flash';
import { customUpdateInputsWithError } from 'utils/custom-update-inputs-with-error';

export class CardDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cardRefs = {};
    this.amountRefs = {};
  }

  componentDidUpdate() {
    // Loop through cards and add validation error from API if exists
    _.each(this.props.cards, (card) => {
      const current = this.formRef.current
      const errorMessages = {['card_number_' + card.id]: card.error || null};

      if (card.error && card.error.length > 0) {
        customUpdateInputsWithError(current, errorMessages);
      }
    });
  }

  cardIdKeyPressed(id, event) {
    const key = event.charCode ? event.charCode : event.keyCode ? event.keyCode : 0;
    // Enter key is sent by card readers. Indicates the end of the stream of chars
    if (key === 13) {
      event.preventDefault();
      this.props.parseCard(id, event.target.value, this.cardRefs[id].textFieldRef, this.amountRefs[id]);
    }
  }

  handleAmountChange(id, {target}) {
    this.props.updateCard(id, {amount: target.value});
  }

  handleCardBlur(id, {target}) {
    if (target.value) this.props.validateStoredCard(id, target.value);
    this.props.updateCard(id, {card_number: target.value});
  }

  handleDigitalProductChange(id, {target}) {
    const webpos_fees = _.find(this.props.webposProfile.digital_products, (p) => { return p.id === parseInt(target.value) }).webpos_fees;
    this.props.updateCard(id, {product_id: target.value, fee: webpos_fees});
  }

  handleFirstNameBlur(id, {target}) {
    this.props.updateCard(id, {delivery_first_name: target.value})
  }

  handleLastNameBlur(id, {target}) {
    this.props.updateCard(id, {delivery_last_name: target.value})
  }

  handleSmsNumberBlur(id, {target}) {
    this.props.updateCard(id, {delivery_sms_number: target.value})
  }

  handleProductTypeChange(id, {target}) {
    let attributes = {product_type: target.value, error: null, card_number: null, product: null,
                      product_id: null, delivery_first_name: null, delivery_last_name: null,
                      delivery_sms_number: null}

    const digitalProducts = this.props.webposProfile.digital_products;
    // Handle case where single product is prefilled
    if (target.value === 'digital' && digitalProducts.length === 1) {
      attributes.product_id = digitalProducts[0].id;
      attributes.fee = digitalProducts[0].webpos_fees;
    }
    this.props.updateCard(id, attributes);
  }

  selectProductType(card) {
    const { webposProfile } = this.props;

    return (
      <Grid container spacing={8}>
        {webposProfile.digital_purchases_enabled && webposProfile.digital_products.length > 0 &&
          <Grid style={{paddingTop: "2em", alignSelf: "flex-end"}}>
            <Chip label="Card Type" color="primary" style={{fontSize: "1em", marginBottom: "0.5em"}}/>
            <RadioInput name="product_type" required row={true}
              value='physical'
              options={[{label: 'Physical', value: 'physical'}, {label: 'Digital', value: 'digital'}]}
              disabled={card.isValidating} // Stop issues where updates after async validations are out of date
              // Clears fields when changing product type
              onChange={
                (e) => {
                  this.handleProductTypeChange(card.id, e);
                }
              }
              validationErrors={{
                isDefaultRequiredValue: "can't be blank"
              }}
            />
          </Grid>
        }
      </Grid>
    )
  }

  renderCards() {
    const { cards, maxCardValue, minCardValue, removeCard, webposProfile } = this.props;

    const digitalProductOptions = webposProfile.digital_products.map((product) => {
      return { value: product.id, label: product.name };
    });

    return (
      cards.map((card, index) =>
        <React.Fragment key={index}>
          <Grid container spacing={8}>
            <Grid item xs={10}>
              {this.selectProductType(card)}
            </Grid>
            {index !== 0 &&
              <Grid item xs={2} style={{alignSelf: "flex-end", paddingBottom: "0.75em"}}>
                <IconButton aria-label="Delete" onClick={() => removeCard(card.id)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            }
            {card.product_type == "physical" &&
              <Grid item xs={12}>
                <TextInput name={'card_number_' + card.id} label="Card Number or Card ID" autoFocus required
                  value={card.card_number}
                  onBlur={(e) => this.handleCardBlur(card.id, e)}
                  onKeyPress={(e) => this.cardIdKeyPressed(card.id, e)}
                  inputLabelProps={{ shrink: true }}
                  inputProps={{
                    endAdornment: card.isValidating ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                  }}
                  validations={{
                    notIn: cards.map((otherCard) => { if (card.id != otherCard.id) return otherCard.card_number })
                  }}
                  validationErrors={{
                    notIn: 'has already been used in this purchase',
                    isDefaultRequiredValue: "can't be blank"
                  }}
                  innerRef={(c) => { this.cardRefs[card.id] = c; }}
                />
              </Grid>
            }
            {card.product_type == "digital" &&
              <Grid item xs={12}>
                <SelectInput name="product_id" label="Product"
                  options={digitalProductOptions}
                  value={digitalProductOptions.length === 1 ? digitalProductOptions[0].value : null} // prefill if only one product available
                  onChange={(e) => this.handleDigitalProductChange(card.id, e)}
                  validationErrors={{ isDefaultRequiredValue: "can't be blank" }}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <TextInput type="number" name={'amount_' + card.id} label="Amount" required
                value={card.amount}
                onChange={(e) => this.handleAmountChange(card.id, e)}
                inputProps={{
                  startAdornment: <InputAdornment position="start">{currencySymbol(webposProfile.currency_code)}</InputAdornment>
                }}
                inputFieldProps={{
                  step: '0.01'
                }}
                validations={{
                  isGreaterEqualTo: minCardValue,
                  isLesserEqualTo: maxCardValue
                }}
                validationErrors={{
                  isGreaterEqualTo: 'must be ' + formatCurrency(minCardValue, webposProfile.currency_code) + ' or greater',
                  isLesserEqualTo: 'must be ' + formatCurrency(maxCardValue, webposProfile.currency_code) + ' or less',
                  isDefaultRequiredValue: "can't be blank"
                }}
                innerRef={(c) => { this.amountRefs[card.id] = c; }}
              />
            </Grid>
            {card.product_type == "digital" &&
              <React.Fragment>
                <Grid item xs={12} md={6}>
                  <TextInput name={'delivery_first_name_' + card.id} label="Recipient first name" required
                    onBlur={(e) => this.handleFirstNameBlur(card.id, e)}
                    validationErrors={{ isDefaultRequiredValue: "can't be blank" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput name={'delivery_last_name_' + card.id} label="Recipient last name" required
                    onBlur={(e) => this.handleLastNameBlur(card.id, e)}
                    validationErrors={{ isDefaultRequiredValue: "can't be blank" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput name={'delivery_sms_number_' + card.id} label="Recipient mobile number"
                    hint="For SMS delivery (Australian numbers only)" required
                    onBlur={(e) => this.handleSmsNumberBlur(card.id, e)}
                    value=''
                    validations={{ auMobileNumber: true }}
                    validationErrors={{
                      auMobileNumber: 'must be a valid mobile number in the format 04XXXXXXXX',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />
                </Grid>
              </React.Fragment>
            }
          </Grid>
        </React.Fragment >
      )
    )
  }

  handleSubmit() {
    const { cards, handleNext, maxCartValue, totalCost, flashMessage, webposProfile } = this.props;

    if (_.find(cards, (c) => { return c.isValidating || c.error })) {
      // Don't pass to next step if a card is validating or contains an error
      return;
    }
    // Check max cart validation
    if (totalCost > maxCartValue) {
      flashMessage("Total cart value can't be greater than " + formatCurrency(maxCartValue, webposProfile.currency_code), {isError: true});
      return;
    }
    handleNext();
  }

  render() {
    const { cards, multiple, maxCartCards, addCard } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
          {this.renderCards()}
          {multiple && cards.length < maxCartCards &&
            <Button variant="contained" color="secondary" size="small" onClick={() => addCard()}>Add another card</Button>
          }

          <div className="button-container">
            <Button type="submit" variant="contained" color="secondary">Next</Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}

CardDetailsForm.defaultProps = {
  minCardValue: 5
};

function mapStateToProps(state) {
  return {
    cards: state.load.cards,
    webposProfile: state.session.webposProfile
  }
}

const mapDispatchToProps = {
  addCard,
  updateCard: (id, attrs) => updateCard(id, attrs),
  removeCard: (id) => removeCard(id),
  parseCard: (id, value, cardRef, amountRef) => parseCard(id, value, cardRef, amountRef),
  validateStoredCard: (id, number) => validateStoredCard(id, number),
  flashMessage: (message, options = {}) => flashMessage(message, options)
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetailsForm)
