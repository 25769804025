import {
  FETCH_TRANSACTION_REQUEST,
  FETCH_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_FAILURE,
  RESEND_RECEIPT_EMAIL_REQUEST,
  RESEND_RECEIPT_EMAIL_SUCCESS,
  RESEND_RECEIPT_EMAIL_FAILURE
} from 'actions/TransactionActions';

const initialState = {
  isFetching: false,
  transaction: null,
  isProcessing: false
};

export default function transaction(state = initialState, action) {
  switch(action.type) {
    case FETCH_TRANSACTION_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_TRANSACTION_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        transaction: action.transaction,
      });
    }
    case FETCH_TRANSACTION_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case RESEND_RECEIPT_EMAIL_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case RESEND_RECEIPT_EMAIL_SUCCESS : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case RESEND_RECEIPT_EMAIL_FAILURE : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }

    default:
      return state;
  }
}
