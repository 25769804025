import React from 'react';
import { connect } from 'react-redux';
import { Paper, MenuList, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { hasPermission } from 'utils/auth';

export class UserMenu extends React.Component {
  render() {
    const { permissions } = this.props;

    return (
      <Paper>
        <MenuList className="menu" disablePadding>
          {hasPermission(permissions, 'create', 'User') &&
            <NavLink to="/users" activeClassName="active">
              <MenuItem>Users</MenuItem>
            </NavLink>
          }
          {hasPermission(permissions, 'read', 'DigitalPartner') &&
            <NavLink to="/digital_floats" activeClassName="active">
              <MenuItem>Digital Floats</MenuItem>
            </NavLink>
          }
        </MenuList>
      </Paper>
    )
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.session.permissions
  }
}

export default connect(mapStateToProps)(UserMenu)
