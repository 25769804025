import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
} from '../actions/ProductActions'

const initialState = {
  isFetching: false,
  products: [],
};

export default function product(state = initialState, action) {
  switch(action.type) {
    case FETCH_PRODUCTS_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_PRODUCTS_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        products: action.products
      });
    }
    case FETCH_PRODUCTS_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    default:
      return state;
  }
}
