import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';

export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const SEND_RESET_INSTRUCTIONS_REQUEST = 'SEND_RESET_INSTRUCTIONS_REQUEST';
export const SEND_RESET_INSTRUCTIONS_SUCCESS = 'SEND_RESET_INSTRUCTIONS_SUCCESS';
export const SEND_RESET_INSTRUCTIONS_FAILURE = 'SEND_RESET_INSTRUCTIONS_FAILURE';
export const SEND_CONFIRM_INSTRUCTIONS_REQUEST = 'SEND_CONFIRM_INSTRUCTIONS_REQUEST';
export const SEND_CONFIRM_INSTRUCTIONS_SUCCESS = 'SEND_CONFIRM_INSTRUCTIONS_SUCCESS';
export const SEND_CONFIRM_INSTRUCTIONS_FAILURE = 'SEND_CONFIRM_INSTRUCTIONS_FAILURE';

function fetchProfileRequest() {
  return {
    type: FETCH_PROFILE_REQUEST
  }
}

function fetchProfileSuccess(user, webposProfile, permissions) {
  return {
    type: FETCH_PROFILE_SUCCESS,
    user,
    webposProfile,
    permissions
  }
}

function fetchProfileFailure() {
  return {
    type: FETCH_PROFILE_FAILURE
  }
}

function sendResetInstructionsRequest() {
  return {
    type: SEND_RESET_INSTRUCTIONS_REQUEST
  }
}

function sendResetInstructionsSuccess() {
  return {
    type: SEND_RESET_INSTRUCTIONS_SUCCESS
  }
}

function sendResetInstructionsFailure() {
  return {
    type: SEND_RESET_INSTRUCTIONS_FAILURE
  }
}

function sendConfirmInstructionsRequest() {
  return {
    type: SEND_CONFIRM_INSTRUCTIONS_REQUEST
  }
}

function sendConfirmInstructionsSuccess() {
  return {
    type: SEND_CONFIRM_INSTRUCTIONS_SUCCESS
  }
}

function sendConfirmInstructionsFailure() {
  return {
    type: SEND_CONFIRM_INSTRUCTIONS_FAILURE
  }
}

export function sendResetInstructions(username, formRef) {
  return(dispatch) => {
    dispatch(sendResetInstructionsRequest());
    return axios.post('passwords', {username})
      .then(response => {
        dispatch(sendResetInstructionsSuccess());
        dispatch(flashMessage('Instructions to reset your password have been sent to your email'));
        // Redirect to login page once moved to frontend
      }).catch(error => {
        dispatch(sendResetInstructionsFailure());
        formRef.current.updateInputsWithError({username: error.response.data.error});
      });
  }
}

export function sendConfirmInstructions(username, formRef) {
  return(dispatch) => {
    dispatch(sendConfirmInstructionsRequest());
    return axios.post('confirmations', {username})
      .then(response => {
        dispatch(sendConfirmInstructionsSuccess());
        dispatch(flashMessage('Instructions to confirm your account have been sent to your email'));
        // Redirect to login page once moved to frontend
      }).catch(error => {
        dispatch(sendConfirmInstructionsFailure());
        formRef.current.updateInputsWithError({username: error.response.data.error});
      });
  }
}

export function changePassword(data) {
  return dispatch => {
    return axios.post('/change_password', {data})
      .then(response => {
        dispatch(flashMessage('Your password has been updated'));
        dispatch(push('/profile'));
      }).catch(error => {
        dispatch(flashMessage('Could not change password', {isError: true}));
      });
  }
}
