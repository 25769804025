import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import CheckboxGroupInput from 'components/inputs/CheckboxGroupInput';
import { parseCardID } from 'utils/card-swipes';
import { getTimezones, determineTimezone } from 'utils/timezones';

export class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.user
    };

    this.formRef = React.createRef();
    this.handleBack = this.handleBack.bind(this);
    this.handleCardNumberPress = this.handleCardNumberPress.bind(this);
  }

  componentDidMount() {
    if (!this.state.timezone) this.setState({timezone: determineTimezone()});
  }

  componentDidUpdate() {
    const { userErrors } = this.props;

    if (userErrors) {
      try {
        this.formRef.current.updateInputsWithError(userErrors);
      } catch(err) {}
    }
  }

  handleBack() {
    this.props.navigateTo('/users');
  }

  handleCardNumberPress(event) {
    const key = event.charCode ? event.charCode : event.keyCode ? event.keyCode : 0;
    // Enter key is sent by card readers. Indicates the end of the stream of chars
    if (key === 13) {
      event.preventDefault();
      const cardId = parseCardID(event.target.value);
      this.setState({login_card_number: cardId});
    }
  }

  render() {
    const { handleSubmit, isProcessing, timezones, userRoles, paymentMethods } = this.props;
    const { login_card_number } = this.state;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleSubmit} ref={this.formRef}>
          <Grid container spacing={16}>
            <Grid item sm={12} md={6}>
              <TextInput name="username" label="Username" compact required
                value={this.state.username}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput type="email" name="email" label="Email" compact required
                value={this.state.email}
                validations="isEmail"
                validationErrors={{
                  isEmail: 'not a valid email',
                  isDefaultRequiredValue: "can't be blank"
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput name="first_name" label="First Name" compact required
                value={this.state.first_name}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput name="last_name" label="Last Name" compact required
                value={this.state.last_name}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <SelectInput name="timezone" label="Time Zone" required
                options={timezones}
                value={this.state.timezone}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />
            </Grid>
          </Grid>

          <Grid container spacing={16}>
            {userRoles && userRoles.length > 0 &&
              <Grid item sm={12} md={6}>
                <Typography variant="subtitle1">Roles</Typography>
                <CheckboxGroupInput name="roles"
                  value={this.state.roles}
                  options={userRoles.map((role, index) =>{ return {label: role[0], value: role[1], disabled: role[1] === 'admin' || role[1] === 'finance'} })}
                />
              </Grid>
            }
            {paymentMethods && paymentMethods.length > 0 &&
              <Grid item sm={12} md={6}>
                <Typography variant="subtitle1">Payment Methods</Typography>
                <CheckboxGroupInput name="payment_methods"
                  value={this.state.payment_methods}
                  options={paymentMethods.map((method, index) =>{ return {label: method[0], value: method[1]} })}
                />
              </Grid>
            }
          </Grid>

          <div className="button-container">
            <Button variant="contained" disabled={isProcessing} onClick={this.handleBack}>Back</Button>
            <Button type="submit" disabled={isProcessing} variant="contained" color="secondary">Submit</Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}

UserForm.defaultProps = {
  timezones: getTimezones()
};

function mapStateToProps(state) {
  return {
    isProcessing: state.user.isProcessing,
    userErrors: state.user.userErrors,
    userRoles: state.session.webposProfile.user_roles,
    paymentMethods: state.session.webposProfile.payment_methods
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
