import React from 'react';
import { SnackbarContent, IconButton, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import lightBlue from '@material-ui/core/colors/lightBlue';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  snackbar: {
    maxWidth: '100%',
    color: 'white',
    marginBottom: '2em'
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: lightBlue[500]
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  messageContainer: {
    width: '80%'
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    paddingLeft: '0px'
  }
});

export class Alert extends React.Component {

  render() {
    const { message, type, hideClose, handleClose, classes } = this.props;

    const Icon = variantIcon[type];

    return (
      <SnackbarContent
        className={classNames(classes[type], classes.snackbar)}
        classes={{message: classes.messageContainer, action: classes.action}}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.icon} />
            {message}
          </span>
        }
        action={!hideClose && [
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }
}

export default withStyles(styles)(Alert);
