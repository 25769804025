import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { TextField, FormHelperText } from '@material-ui/core';

export class TextInput extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.value || this.props.value === 0) {
      this.props.setValue(this.props.value);
    }
    this.changeValue = this.changeValue.bind(this);
    this.focusTextInput = this.focusTextInput.bind(this);
    this.textFieldRef = React.createRef();
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.target.value);
  }

  focusTextInput() {
    this.textFieldRef.current.focus();
  }

  getInputValue() {
    const { value, type } = this.props;
    if (value) {
      return value;
    } else if (type === 'number' && value === 0) {
      // To handle number inputs having 0 instead of blank
      return '0';
    } else {
      return '';
    }
  }

  render() {
    const { type, name, label, disabled, placeholder, multiline, inputProps, inputFieldProps, autoFocus, onBlur, onKeyPress,
            inputLabelProps, hint, compact, style, errorMessage, isPristine } = this.props;
    const isError = !isPristine && errorMessage != null;

    return (
      <div className={compact ? '' : 'input-container'}>
        <TextField
          error={isError}
          type={type || "text"}
          placeholder={placeholder}
          multiline={multiline}
          rows="4"
          id={name}
          name={name}
          label={label}
          autoFocus={autoFocus}
          onChange={this.changeValue}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          value={this.getInputValue()}
          disabled={disabled}
          fullWidth
          inputProps={inputFieldProps}
          InputProps={inputProps}
          InputLabelProps={inputLabelProps}
          inputRef={this.textFieldRef}
          style={style}
        />
        {!isPristine && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

TextInput.propTypes = {
  ...propTypes
};

export default withFormsy(TextInput);
