import axios from 'axios';

export const FETCH_DIGITAL_FLOAT_REQUEST = "FETCH_FLOAT_BALANCE_REQUEST";
export const FETCH_DIGITAL_FLOAT_SUCCESS = "FETCH_FLOAT_BALANCE_SUCCESS";
export const FETCH_DIGITAL_FLOAT_FAILURE = "FETCH_FLOAT_BALANCE_FAILURE";

function fetchDigitalFloatRequest() {
  return {
    type: FETCH_DIGITAL_FLOAT_REQUEST
  }
}

function fetchDigitalFloatSuccess(digitalPartner) {
  return {
    type: FETCH_DIGITAL_FLOAT_SUCCESS,
    digitalPartner
  }
}

function fetchDigitalFloatFailure() {
  return {
    type: FETCH_DIGITAL_FLOAT_FAILURE
  }
}

export function fetchDigitalFloat(id, form) {
  return(dispatch) => {
    dispatch(fetchDigitalFloatRequest());
    return axios.get('digital_partners/' + id)
      .then(response => {
        dispatch(fetchDigitalFloatSuccess(response.data.digital_partner));
      }).catch(error => {
        dispatch(fetchDigitalFloatFailure());
        form && form.current && form.current.updateInputsWithError({id: error.response.data.error});
      });
  }
}
