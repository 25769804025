import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { resetPassword } from 'actions/SessionActions';
import queryString from 'query-string';
import PasswordForm from 'components/forms/PasswordForm';

export class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.navigateTo('/');
    }
  }

  handleSubmit(data, _data, _invalidateForm) {
    const token = queryString.parse(this.props.location.search).reset_password_token;
    this.props.resetPassword(token, data.password, data.password_confirmation);
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>Reset your password</Typography>
        <PasswordForm handleSubmit={this.handleSubmit} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  resetPassword: (token, password, passwordConfirm) => resetPassword(token, password, passwordConfirm)
};

export default connect(null, mapDispatchToProps)(ResetPasswordPage)
