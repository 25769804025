import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import ProfileMenu from 'components/menus/ProfileMenu';
import TextInput from 'components/inputs/TextInput';
import { changePassword } from 'actions/ProfileActions';

export class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.changePassword(data);
  }

  handleBack() {
    this.props.navigateTo('/profile');
  }

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <ProfileMenu />
        </Grid>
        <Grid item md={8} className='full-width'>
          <Paper className='form-container' elevation={1}>
            <Formsy onValidSubmit={this.handleSubmit}>
              <Typography variant='h4' align='center' gutterBottom>Change Password</Typography>
              <Grid container spacing={8} justify="center">
                <Grid item xs={12} md={8}>
                  <TextInput type='password' name='current_password' label='Current Password' required
                    validations="minLength:8"
                    validationErrors={{
                      isDefaultRequiredValue: "can't be blank",
                      minLength: 'must be more than 8 characters'
                    }}
                  />
                  <TextInput type='password' name='password' label='New Password' required
                    validations="minLength:8"
                    validationErrors={{
                      isDefaultRequiredValue: "can't be blank",
                      minLength: 'must be more than 8 characters'
                    }}
                  />
                  <TextInput type='password' name='password_confirmation' label='New Password Confirmation' required
                    validations="minLength:8,equalsField:password"
                    validationErrors={{
                      isDefaultRequiredValue: "can't be blank",
                      minLength: 'must be more than 8 characters',
                      equalsField: 'must match password'
                    }}
                  />
                  <div className='button-container'>
                    <Button variant='contained' onClick={this.handleBack}>Back</Button>
                    <Button type='submit' variant='contained' color='secondary'>Change password</Button>
                  </div>
                </Grid>
              </Grid>
            </Formsy>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path),
  changePassword: (data) => changePassword(data)
};

export default connect(null, mapDispatchToProps)(ChangePasswordPage)
