import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Typography, Button, IconButton, InputAdornment, CircularProgress } from '@material-ui/core';
import FileInput from 'components/inputs/FileInput';
import SelectInput from 'components/inputs/SelectInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import TextInput from 'components/inputs/TextInput';
import CardPopup from 'components/CardPopup';
import { updateCards } from 'actions/LoadActions';

export class BulkDigitalCardDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCards: false,
      includeMessage: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const { cards, updateCards, handleNext } = this.props;

    if (cards.length > 0) {
      // Update all cards with product_id and messaging attributes
      const newCards = cards.map((card) => {
        return Object.assign({}, card, {
          product_id: data.product_id,
          message: data.message || '',
          from: data.from || ''
        })
      });

      updateCards(newCards);
      handleNext();
    }
  }

  render() {
    const { webposProfile, handleDataChange, cards } = this.props;

    const digitalProductOptions = webposProfile.digital_products.map((product) => {
      return { value: product.id, label: product.name };
    });

    return (
      <React.Fragment>
        <Formsy onValidSubmit={this.handleSubmit}>
          <Typography variant="body1" align="center" paragraph>
            Bulk orders can be placed by uploading a file with all recipients and their details.
            Each recipient needs a mobile number and first and last names.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            <a href="/templates/bulk_digital_template.csv" target="_blank">Download Template</a>
          </Typography>

          <Grid container spacing={8}>
            <Grid item xs={11}>
              <SelectInput name="product_id" label="Product"
                options={digitalProductOptions}
                value={digitalProductOptions.length === 1 ? digitalProductOptions[0].value : null}
                onChange={(e) => handleDataChange({bulk_product_id: e.target.value})}
                validationErrors={{isDefaultRequiredValue: "can't be blank"}}
              />

              <FileInput name="card_file" label="Card File" required
                validationErrors={{
                  isDefaultRequiredValue: "can't be blank"
                }}
              />

              {cards.length > 0 &&
                <div>
                  <Button variant="contained" color="primary" onClick={(e) => this.setState({showCards: true})}>Show {cards.length} cards</Button>
                  <CardPopup open={this.state.showCards} cards={cards} handleClose={() => this.setState({showCards: false})} webposProfile={webposProfile} />
                </div>
              }

              <CheckboxInput name="include_message" label="Include Message?"
                value={this.state.includeMessage}
                onChange={(e) => this.setState({includeMessage: e.target.checked})}
              />
              {this.state.includeMessage &&
                <React.Fragment>
                  <TextInput name="from" label="From" required
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />

                  <TextInput name="message" label="Message" required multiline
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </React.Fragment>
              }
            </Grid>
          </Grid>

          <div className="button-container">
            <Button type="submit" variant="contained" color="secondary">Next</Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    cards: state.load.cards
  }
};

const mapDispatchToProps = {
  updateCards: (cards) => updateCards(cards)
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkDigitalCardDetailsForm)
