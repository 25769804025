import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import FlashMessage from 'components/FlashMessage';

export class LoginLayout extends React.Component {
  render() {
    const { children, classes } = this.props;

    return (
      <Grid container spacing={16} justify="center">
        <Grid item md={4} sm={8} className="full-width">
          <Paper className="form-container login-top" elevation={1}>
            <div className="center">
              <img src={require('images/givvpos.png')} style={{width: '100%'}} />
            </div>
            <FlashMessage />
            {children}
            </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default LoginLayout;
