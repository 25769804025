import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 16
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export const popupStyles = theme => ({
  paper: {
    position: 'absolute',
    top: '3em',
    left: '25%',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    maxHeight: '80%'
  },
  spinnerContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    backgroundColor: '#fff',
    width: '100%',
    height: '138px',
    textAlign:'center'
  }
});
