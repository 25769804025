import moment from 'moment';

// Formats a given date to a standard format
export function formatDate(date) {
  return moment.parseZone(date).format('D MMM YYYY');
}

// Formats a given date and time to a standard format
export function formatDateTime(date) {
  return moment.parseZone(date).format('D MMM YYYY H:mm');
}

// Formats a given date and time to a standard format without showing year
export function formatDateTimeWithoutYear(date) {
  return moment.parseZone(date).format('D MMM H:mm');
}

export function getDay(date) {
  return moment.parseZone(date).format('dddd');
}

// 0 padded month numbers
export function getMonthOptions() {
  return moment.months().map(m => moment().month(m).format('MM'))
}

// Next 10 years
export function getYearOptions() {
  var years = [];
  var startYear = (new Date().getFullYear());

  for (var i = 0; i < 10; i++) {
    years.push(startYear + i);
  }
  return years;
}
