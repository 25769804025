import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

export default class PaymentMethodForm extends React.Component {
  render() {
    const { handleBack, paymentMethods, handlePaymentMethodSelection } = this.props;
    return (
      <React.Fragment>
        <Typography variant="subtitle1" align="center" paragraph>Select a payment method:</Typography>
        <Grid container spacing={16}>
          {paymentMethods.map((paymentMethod, index) => (
            <React.Fragment key={index}>
              <Grid item xs={false} md={2} />
              <Grid item className="choice-btn" xs={12} md={8}>
                <Button type="submit" variant="contained" size="large" color="secondary" className="full-width" onClick={(e) => handlePaymentMethodSelection(paymentMethod)}>{paymentMethod}</Button>
              </Grid>
              <Grid item xs={false} md={2} />
            </React.Fragment>
          ))}
        </Grid>
        <div className="button-container">
          <Button variant="contained" onClick={handleBack}>Back</Button>
        </div>
      </React.Fragment>
    );
  }
}
