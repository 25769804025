import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Grid, Paper, Table, TableHead, TableBody, TableRow, Button, CircularProgress, Typography } from '@material-ui/core';
import { StyledTableCell } from 'utils/commonStyles';
import _ from 'lodash';
import UserMenu from 'components/menus/UserMenu';
import { fetchUsers, updateUserStatus, downloadUserData } from 'actions/UserActions';
import { hasPermission } from 'utils/auth';

export class UsersPage extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  render() {
    const { users, currentUserId, navigateTo, updateUserStatus, isFetching, permissions } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <UserMenu />
        </Grid>
        <Grid item md={10} className="full-width">
          <Paper className="form-container" elevation={1}>
            <div className="below-space right">
              <Button variant="contained" onClick={() => navigateTo('/users/new')}>New User</Button>
            </div>
            <div className="scroll-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="dense">Username</StyledTableCell>
                    <StyledTableCell padding="dense">First Name</StyledTableCell>
                    <StyledTableCell padding="dense">Last Name</StyledTableCell>
                    <StyledTableCell padding="dense">Email</StyledTableCell>
                    <StyledTableCell padding="dense">Status</StyledTableCell>
                    <StyledTableCell padding="dense">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    users.map((user, index) =>
                      <TableRow key={index}>
                        <StyledTableCell padding="dense">{user.username}</StyledTableCell>
                        <StyledTableCell padding="dense">{user.first_name}</StyledTableCell>
                        <StyledTableCell padding="dense">{user.last_name}</StyledTableCell>
                        <StyledTableCell padding="dense">
                          <a href={'mailto:' + user.email}>{user.email}</a>
                        </StyledTableCell>
                        <StyledTableCell padding="dense">{user.status}</StyledTableCell>
                        <StyledTableCell padding="dense">
                          <Button size="small" onClick={() => navigateTo('/users/' + user.id + '/edit')} className="font-medium">Edit</Button>
                          {currentUserId != user.id &&
                            (user.status === 'Active' ?
                              <Button size="small" onClick={() => updateUserStatus(user.id, true)} className="font-medium">Archive</Button>
                            :
                              <Button size="small" onClick={() => updateUserStatus(user.id, false)} className="font-medium">Activate</Button>
                            )
                          }
                        </StyledTableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </div>
            {hasPermission(permissions, 'read', 'Organisation') && 
              <Typography variant="body1" className="top-space">
                Export all organisation users as <button onClick={() => this.props.downloadUserData()} className="raw-button-link">CSV</button>
              </Typography>}
            {isFetching && _.isEmpty(users) &&
              <Grid container justify="center" className="top-space">
                <CircularProgress />
              </Grid>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.user.isFetching,
    users: state.user.users,
    currentUserId: state.session.currentUser.id,
    permissions: state.session.permissions,
  }
}

const mapDispatchToProps = {
  fetchUsers,
  updateUserStatus: (userId, toArchive) => updateUserStatus(userId, toArchive),
  navigateTo: (path) => push(path),
  downloadUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
