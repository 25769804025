import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button, InputAdornment, CircularProgress, Table,
         TableHead, TableBody, TableFooter, TableRow, TableCell, TablePagination } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DateTimeInput from 'components/inputs/DateTimeInput';
import SelectInput from 'components/inputs/SelectInput';
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatCurrency } from 'utils/currency';
import { formatDateTime } from 'utils/dates';
import { NavLink } from 'react-router-dom';
import ReportMenu from 'components/menus/ReportMenu';
import { hasPermission } from 'utils/auth';
import { fetchPurchasesReportData, downloadPurchasesReportData } from 'actions/ReportActions';

const MAX_ROWS_EXPORT = 1000;

export class PurchasesReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0
    };

    this.formRef = React.createRef();
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangePage(_event, page) {
    this.setState({page}, this.fetchData);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchPurchasesReportData(this.formRef.current.getModel(), this.props.all ? 'all_purchases' : 'purchases', this.state.page + 1);
  }

  renderFilters(data) {
    const { webposProfile, permissions, isFetching, all } = this.props;
    const fromDate = data ? data.from_date : moment().startOf('day').format('DD/MM/YYYY H:mm');
    const toDate = data ? data.to_date : moment().endOf('day').format('DD/MM/YYYY H:mm');

    return (
      <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
        <Grid container spacing={16} justify="center">
          <Grid item sm={10} md={3} className="full-width print-full-width">
            <DateTimeInput name="from_date" label="From" required
              value={fromDate}
              inputProps={{
                endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
              }}
              validationErrors={{isDefaultRequiredValue: "can't be blank"}}
            />
          </Grid>
          <Grid item sm={10} md={3} className="full-width print-full-width">
            <DateTimeInput name="to_date" label="To" required
              value={toDate}
              inputProps={{
                endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
              }}
              validations="timeSameOrAfter:from_date"
              validationErrors={{
                timeSameOrAfter: 'must be after from time',
                isDefaultRequiredValue: "can't be blank"
              }}
            />
          </Grid>
        </Grid>
        {hasPermission(permissions, 'read', 'Organisation') ?
          <Grid container spacing={16} justify="center">
            <Grid item sm={10} md={6} className="full-width print-full-width" align="left">
              <SelectInput name="group_id" label="Site"
                options={[{label: 'All', value: 'all'}].concat(webposProfile.groups)}
                value="all"
              />
            </Grid>
          </Grid>
        :
          all ? null : ( // don't display on 'All Purchases Report' page
            <Grid container spacing={16} justify="center">
              <Grid item sm={10} md={6} className="full-width print-full-width" align="left">
                <SelectInput name="user_id" label="User"
                  options={[{label: 'All', value: 'all'}].concat(webposProfile.users)}
                  value="all"
                />
              </Grid>
            </Grid>
          )
        }
        <Grid container spacing={16} justify="center">
          <Grid item sm={10} md={6} className="full-width hidden-print">
            <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Search</Button>
          </Grid>
        </Grid>
      </Formsy>
    );
  }

  renderDataCharts(data) {
    const { webposProfile } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={16} justify="center" className="top-space below-space">
          <Grid item md={6} xs={12} className="print-full-width">
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                title: {text: 'Purchases By Type'},
                chart: {type: 'pie', plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
                plotOptions: {
                  pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: true
                  }
                },
                series: [{
                  colorByPoint: true,
                  data: data.breakdown
                }],
                tooltip: {enabled: false},
                credits: {enabled: false}
              }}
            />
          </Grid>

          <Grid item md={6} xs={12} className="print-full-width">
            <Table style={{marginTop: '2em'}}>
              <TableBody>
                {data.breakdown.map((totals, index) =>
                  <TableRow key={index}>
                    <TableCell>{totals.name} Payments</TableCell>
                    <TableCell align="right">{formatCurrency(totals.y, webposProfile.currency_code)}</TableCell>
                  </TableRow>
                )}
                <TableRow className="font-bold">
                  <TableCell>Total</TableCell>
                  <TableCell align="right">{formatCurrency(data.total, webposProfile.currency_code)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderDownloadLink(type, count) {
    const { isDownloading, downloadPurchasesReportData } = this.props;

    if (isDownloading) return;

    const viaEmail = count > MAX_ROWS_EXPORT;

    return (
      <React.Fragment>
        {viaEmail ? 'Send Report via ' : 'Download all results as '}
        <button onClick={() => downloadPurchasesReportData(this.formRef.current.getModel(), type, viaEmail)} className="raw-button-link">{viaEmail ? 'Email' : 'Excel'}</button>
        <br />
      </React.Fragment>
    );
  }

  renderPurchaseTable() {
    const { webposProfile, permissions, purchasesData, isDownloading } = this.props;

    return (
      <React.Fragment>
        <div className="scroll-container">
          <Table className="hidden-print">
            <TableHead>
              <TableRow>
                <TableCell padding="dense">ID</TableCell>
                <TableCell padding="dense">Date/Time</TableCell>
                {hasPermission(permissions, 'read', 'Organisation') &&
                  <TableCell padding="dense">Site</TableCell>
                }
                <TableCell padding="dense">{webposProfile.title} User</TableCell>
                <TableCell padding="dense">Payment Method</TableCell>
                <TableCell padding="dense">Reference</TableCell>
                <TableCell align="right" padding="dense">Quantity</TableCell>
                <TableCell align="right" padding="dense">Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchasesData.transactions.map((transaction, index) =>
                <TableRow key={index}>
                  <TableCell padding="dense">
                    <NavLink to={'/purchases/' + transaction.id}>{transaction.id}</NavLink>
                  </TableCell>
                  <TableCell padding="dense">{formatDateTime(transaction.date)}</TableCell>
                  {hasPermission(permissions, 'read', 'Organisation') &&
                    <TableCell padding="dense">{transaction.group}</TableCell>
                  }
                  <TableCell padding="dense">{transaction.user}</TableCell>
                  <TableCell padding="dense">{transaction.payment_type}</TableCell>
                  <TableCell padding="dense">{transaction.reference}</TableCell>
                  <TableCell align="right" padding="dense">{transaction.quantity}</TableCell>
                  <TableCell align="right" padding="dense">{formatCurrency(transaction.paid, webposProfile.currency_code)}</TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={7}
                  rowsPerPageOptions={[]}
                  count={purchasesData.transactions_count}
                  rowsPerPage={10}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        {isDownloading && <Grid container align="left"><CircularProgress size={20} /></Grid>}
        <Typography variant="body1" align="left" className="top-space hidden-print">
          {this.renderDownloadLink('purchases', purchasesData.transactions_count)}
          <Button variant="contained" color="secondary" onClick={() => window.print()}>Print Summary</Button>
        </Typography>
      </React.Fragment>
    );
  }

  renderAllPurchaseTable() {
    const { permissions, allPurchasesData, isDownloading, webposProfile } = this.props;

    return (
      <React.Fragment>
        <div className="scroll-container">
          <Table className="hidden-print">
            <TableHead>
              <TableRow>
                <TableCell padding="dense">ID</TableCell>
                <TableCell padding="dense">Date/Time</TableCell>
                {hasPermission(permissions, 'read', 'Organisation') &&
                  <TableCell padding="dense">Site</TableCell>
                }
                <TableCell padding="dense">POS Type</TableCell>
                <TableCell padding="dense">POS Name</TableCell>
                <TableCell padding="dense">Payment Method</TableCell>
                <TableCell padding="dense">Reference</TableCell>
                <TableCell align="right" padding="dense">Quantity</TableCell>
                <TableCell align="right" padding="dense">Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPurchasesData.transactions.map((transaction, index) =>
                <TableRow key={index}>
                  <TableCell padding="dense">
                    <NavLink to={'/purchases/' + transaction.id}>{transaction.id}</NavLink>
                  </TableCell>
                  <TableCell padding="dense">{formatDateTime(transaction.date)}</TableCell>
                  {hasPermission(permissions, 'read', 'Organisation') &&
                    <TableCell padding="dense">{transaction.group}</TableCell>
                  }
                  <TableCell padding="dense">{transaction.pos_type}</TableCell>
                  <TableCell padding="dense">{transaction.pos_name}</TableCell>
                  <TableCell padding="dense">{transaction.payment_type}</TableCell>
                  <TableCell padding="dense">{transaction.reference}</TableCell>
                  <TableCell align="right" padding="dense">{transaction.quantity}</TableCell>
                  <TableCell align="right" padding="dense">{formatCurrency(transaction.paid, webposProfile.currency_code)}</TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={7}
                  rowsPerPageOptions={[]}
                  count={allPurchasesData.transactions_count}
                  rowsPerPage={10}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        {isDownloading && <Grid container align="left"><CircularProgress size={20} /></Grid>}
        <Typography variant="body1" align="left" className="top-space hidden-print">
          {this.renderDownloadLink('all_purchases', allPurchasesData.transactions_count)}
          <Button variant="contained" color="secondary" onClick={() => window.print()}>Print Summary</Button>
        </Typography>
      </React.Fragment>
    );
  }

  render() {
    const { webposProfile, purchasesData, allPurchasesData, all } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width hidden-print">
          <ReportMenu />
        </Grid>
        {/* Set min-height so page is always big enough should the date popup be shown */}
        <Grid item md={8} className="full-width print-full-width" align="center" style={{minHeight: '550px'}}>
          <Paper className="form-container" elevation={1}>
            <img src={webposProfile.receipt_image} width="150" className="visible-print below-space" />
            <Typography variant="h4" align="center" gutterBottom>{all ? 'All' : webposProfile.title} Purchases Report</Typography>
            {all ?
              <React.Fragment>
                {this.renderFilters(allPurchasesData)}
                {allPurchasesData &&
                  <React.Fragment>
                    {this.renderDataCharts(allPurchasesData, webposProfile)}
                    {this.renderAllPurchaseTable()}
                  </React.Fragment>
                }
              </React.Fragment>
              :
              <React.Fragment>
                {this.renderFilters(purchasesData)}
                {purchasesData &&
                  <React.Fragment>
                    {this.renderDataCharts(purchasesData)}
                    {this.renderPurchaseTable()}
                  </React.Fragment>
                }
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    permissions: state.session.permissions,
    isFetching: state.report.isFetching,
    isDownloading: state.report.isDownloading,
    purchasesData: state.report.purchasesData,
    allPurchasesData: state.report.allPurchasesData
  }
}

const mapDispatchToProps = {
  fetchPurchasesReportData: (data, route, page) => fetchPurchasesReportData(data, route, page),
  downloadPurchasesReportData: (data, route, viaEmail) => downloadPurchasesReportData(data, route, viaEmail)
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesReportPage)
