import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
export const UPDATE_INVOICE_BALANCE = 'UPDATE_INVOICE_BALANCE';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const CREATE_PASSWORD_REQUEST = "CREATE_PASSWORD_REQUEST";
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS";
export const CREATE_PASSWORD_FAILURE = "CREATE_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

function fetchCurrentUserRequest() {
  return {
    type: FETCH_CURRENT_USER_REQUEST
  }
}

function fetchCurrentUserSuccess(user, webposProfile, permissions) {
  return {
    type: FETCH_CURRENT_USER_SUCCESS,
    user,
    webposProfile,
    permissions
  }
}

function fetchCurrentUserFailure() {
  return {
    type: FETCH_CURRENT_USER_FAILURE
  }
}

function updateInvoiceBalanceRequest(invoice) {
  return {
    type: 'UPDATE_INVOICE_BALANCE',
    invoice
  }
}

function signInRequest() {
  return {
    type: SIGN_IN_REQUEST
  }
}

function signInSuccess(user, webposProfile, permissions) {
  return {
    type: SIGN_IN_SUCCESS,
    user,
    webposProfile,
    permissions
  }
}

function signInFailure() {
  return {
    type: SIGN_IN_FAILURE
  }
}

function updateProfileRequest() {
  return {
    type: UPDATE_PROFILE_REQUEST
  }
}

function updateProfileSuccess(user) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    user
  }
}

function updateProfileFailure() {
  return {
    type: UPDATE_PROFILE_FAILURE
  }
}

function createPasswordRequest() {
  return {
    type: CREATE_PASSWORD_REQUEST
  }
}

function createPasswordSuccess(user, webposProfile, permissions) {
  return {
    type: CREATE_PASSWORD_SUCCESS,
    user,
    webposProfile,
    permissions
  }
}

function createPasswordFailure() {
  return {
    type: CREATE_PASSWORD_FAILURE
  }
}

function resetPasswordSuccess(user, webposProfile, permissions) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    user,
    webposProfile,
    permissions
  }
}

export function updateProfile(data) {
  return(dispatch) => {
    dispatch(updateProfileRequest());
    return axios.put('/profile', {profile: data})
      .then(response => {
        dispatch(updateProfileSuccess(response.data.user));
        dispatch(flashMessage('Profile updated successfully'));
        dispatch(push('/profile'));
      }).catch(error => {
        dispatch(updateProfileFailure());
        dispatch(flashMessage('Error updating profile', {isError: true}));
      });
  }
}


export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS
  }
}

function signOutFailure() {
  return {
    type: SIGN_OUT_FAILURE
  }
}

export function fetchCurrentUser() {
  return dispatch => {
    dispatch(fetchCurrentUserRequest());
    return axios.get('session')
      .then(response => {
        dispatch(fetchCurrentUserSuccess(response.data.user, response.data.webpos_profile, response.data.permissions));
      }).catch(error => {
        dispatch(fetchCurrentUserFailure());
      });
  }
}

export function updateInvoiceBalance(invoice) {
  return dispatch => {
    dispatch(updateInvoiceBalanceRequest(invoice));
  }
}

export function signInUser(username, password) {
  return dispatch => {
    dispatch(signInRequest());
    return axios.post('/session', {username: username, password: password})
      .then(response => {
        dispatch(signInSuccess(response.data.user, response.data.webpos_profile, response.data.permissions));
        dispatch(flashMessage('You are now signed in'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(signInFailure());
        dispatch(flashMessage(error.response.data.error || 'Username or password incorrect, please try again', {isError: true}));
        dispatch(push("/login"));
      });
  }
}

export function signOutUser() {
  return dispatch => {
    return axios.delete('/session')
      .then(response => {
        dispatch(signOutSuccess());
        dispatch(push('/login'));
        dispatch(flashMessage('You have been logged out'));
      }).catch(error => {
        dispatch(signOutFailure());
      });
  }
}

export function createPassword(data) {
  return(dispatch) => {
    dispatch(createPasswordRequest());
    return axios.put('/users/confirm', {data})
      .then(response => {
        dispatch(createPasswordSuccess(response.data.user, response.data.webpos_profile, response.data.permissions));
        dispatch(flashMessage('Password created successfully'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(createPasswordFailure(error.response));
        dispatch(flashMessage('Error creating password', {isError: true}));
      });
  }
}

export function resetPassword(token, password, passwordConfirmation) {
  return dispatch => {
    return axios.put('/password/edit', {reset_password_token: token, password: password, password_confirmation: passwordConfirmation})
      .then(response => {
        dispatch(resetPasswordSuccess(response.data.user, response.data.webpos_profile, response.data.permissions));
        dispatch(flashMessage('Your password has been reset'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(flashMessage('Could not reset password', {isError: true}));
      });
  }
}
