import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Switch, Route, withRouter } from 'react-router-dom';
import { AppRoute } from './AppRoute';
import MainLayout from './layouts/MainLayout';
import { hasPermission } from 'utils/auth';
import { HomePage, LoadCardPage, BulkPurchaseSelectionPage, BalancePage, TransactionSearchPage, UsersPage, NewUserPage, EditUserPage, DigitalFloatPage,
         OrganisationReportPage, PurchasesReportPage, CustomerRedemptionReportPage, UnusedFundsReportPage, ProductReportPage,
         InvoiceReportPage, ActiveInvoiceReportPage, WebstoresReportPage, InvoicesPage, NewInvoicePage, ApiHelpPage, HelpPage, ProfilePage,
         EditProfilePage, ChangePasswordPage, ReceiptPage, NotFoundPage, ProductPage} from './pages/all';

export class SessionRouter extends React.Component {
  constructor(props) {
    super(props);

    if (!props.currentUser) {
      props.navigateTo('/login');
    }
  }
  render() {
    const { currentUser, permissions } = this.props;

    return (
      <React.Fragment>
        {currentUser &&
          <Switch>
            <AppRoute exact path='/' layout={MainLayout} component={HomePage} />
            {hasPermission(permissions, 'create', 'Transaction') && <AppRoute exact path='/load' layout={MainLayout} component={LoadCardPage} />}
            {hasPermission(permissions, 'create', 'Transaction') && <AppRoute exact path='/bulk_load' layout={MainLayout} component={() => <BulkPurchaseSelectionPage />} />}
            {hasPermission(permissions, 'create', 'Transaction') && <AppRoute exact path='/bulk_load/physical' layout={MainLayout} component={() => <LoadCardPage bulkPhysical />} />}
            {hasPermission(permissions, 'create', 'Transaction') && <AppRoute exact path='/bulk_load/digital' layout={MainLayout} component={() => <LoadCardPage bulkDigital />} />}
            {hasPermission(permissions, 'balance_check', 'Card') && <AppRoute exact path='/balance' layout={MainLayout} component={BalancePage} />}
            {hasPermission(permissions, 'balance_check', 'Card') && <AppRoute exact path='/transaction_search' layout={MainLayout} component={TransactionSearchPage} />}
            {<AppRoute exact path='/products' layout={MainLayout} component={ProductPage} />}
            {hasPermission(permissions, 'create', 'User') && <AppRoute exact path='/users' layout={MainLayout} component={UsersPage} />}
            {hasPermission(permissions, 'create', 'User') && <AppRoute exact path='/users/new' layout={MainLayout} component={NewUserPage} />}
            {hasPermission(permissions, 'edit', 'User') && <AppRoute exact path='/users/:id/edit' layout={MainLayout} component={EditUserPage} />}
            {hasPermission(permissions, 'read', 'DigitalPartner') && <AppRoute exact path='/digital_floats' layout={MainLayout} component={DigitalFloatPage} />}
            {hasPermission(permissions, 'read', 'Organisation') && <AppRoute exact path='/reports/organisation' layout={MainLayout} component={OrganisationReportPage} />}
            {hasPermission(permissions, 'read', 'Transaction') && <AppRoute exact path='/reports/purchases' layout={MainLayout} component={PurchasesReportPage} />}
            {hasPermission(permissions, 'read', 'Transaction') && <AppRoute exact path='/reports' layout={MainLayout} component={PurchasesReportPage} />}
            {hasPermission(permissions, 'read', 'Transaction') && <AppRoute exact path='/reports/all_purchases' layout={MainLayout} component={() => <PurchasesReportPage all />} />}
            {hasPermission(permissions, 'read', 'TransactionRecord') && <AppRoute exact path='/reports/customer_redemptions' layout={MainLayout} component={CustomerRedemptionReportPage} />}
            {hasPermission(permissions, 'read', 'TransactionRecord') && hasPermission(permissions, 'read', 'Organisation') && <AppRoute exact path='/reports/unused_funds' layout={MainLayout} component={UnusedFundsReportPage} />}
            {hasPermission(permissions, 'read', 'Transaction') && <AppRoute exact path='/reports/products' layout={MainLayout} component={ProductReportPage} />}
            {hasPermission(permissions, 'read', 'Transaction') && <AppRoute exact path='/reports/invoices' layout={MainLayout} component={InvoiceReportPage} />}
            {hasPermission(permissions, 'read', 'Transaction') && <AppRoute exact path='/reports/active_invoices' layout={MainLayout} component={ActiveInvoiceReportPage} />}
            {hasPermission(permissions, 'read', 'Organisation') && <AppRoute exact path='/reports/webstores' layout={MainLayout} component={WebstoresReportPage} />}
            {hasPermission(permissions, 'read', 'InvoiceRequest') && <AppRoute exact path='/invoices' layout={MainLayout} component={InvoicesPage} />}
            {hasPermission(permissions, 'create', 'InvoiceRequest') && <AppRoute exact path='/invoices/new' layout={MainLayout} component={NewInvoicePage} />}
            {hasPermission(permissions, 'read', 'Doorkeeper::Application') && <AppRoute exact path='/api/help' layout={MainLayout} component={ApiHelpPage} />}
            <AppRoute exact path='/help' layout={MainLayout} component={HelpPage} />
            <AppRoute exact path='/profile' layout={MainLayout} component={ProfilePage} />
            <AppRoute exact path='/profile/edit' layout={MainLayout} component={EditProfilePage} />
            <AppRoute exact path='/profile/change_password' layout={MainLayout} component={ChangePasswordPage} />
            <AppRoute exact path='/purchases/:id' layout={MainLayout} component={ReceiptPage} />
            <Route component={NotFoundPage} />
          </Switch>
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    permissions: state.session.permissions
  };
}

const mapDispatchToProps = {
    navigateTo: (path) => push(path)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionRouter));
