import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import _ from 'lodash';
import { Grid, Typography, Button, IconButton, InputAdornment, CircularProgress, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TextInput from 'components/inputs/TextInput';
import { formatCurrency, currencySymbol } from 'utils/currency';
import { addSerial, updateSerial, removeSerial, validateSerial } from 'actions/LoadActions';
import { flashMessage } from 'redux-flash';

export class BulkCardDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSerialBlur(id) {
    if (!this.formRef.current) return;
    const firstSerial = this.formRef.current.getModel()['first_serial_' + id];
    const lastSerial = this.formRef.current.getModel()['last_serial_' + id];
    if (!firstSerial || !lastSerial) return;

    this.props.validateSerial(id, firstSerial, lastSerial, this.formRef.current);
  }

  checkOverlap(set, set2) {
    return (set.first_serial >= set2.first_serial && set.first_serial <= set2.last_serial) ||
      (set.last_serial >= set2.first_serial && set.last_serial <= set2.last_serial);
  }

  handleSubmit() {
    const { serials, handleNext, maxCartValue, maxCartCards, totalCost, flashMessage, webposProfile } = this.props;

    if (_.find(serials, (s) => { return s.isValidating || s.firstError || s.lastError })) {
      // Don't pass to next step if a card serial set is validating or contains an error
      return;
    }

    // Check overlapping serial sets
    let validSets = true;
    _.forEach(serials, (serialSet) => {
      const overlap = _.find(serials, (s) => { return s.id != serialSet.id && this.checkOverlap(serialSet, s) });
      if (overlap) {
        validSets = false;
        return false;
      }
    });
    if (!validSets) {
      flashMessage('Serial ranges contain an overlap', {isError: true});
      return;
    }

    // Check max cart cards validation
    const cardsCount = _.sumBy(serials, (s) => { return s.last_serial - s.first_serial + 1 });
    if (cardsCount > maxCartCards) {
      flashMessage("Total cart can't have more than " + maxCartCards + ' cards', {isError: true});
      return;
    }

    // Check max cart validation
    if (totalCost > maxCartValue) {
      flashMessage("Total cart value can't be greater than " + formatCurrency(maxCartValue, webposProfile.currency_code), {isError: true});
      return;
    }
    handleNext();
  }

  render() {
    const { serials, addSerial, updateSerial, removeSerial, maxCardValue, minCardValue, webposProfile } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
          <Typography variant="body1" align="center" paragraph>
            Bulk orders can be placed by using the serial number printed on the back of the cards.
            Enter the first and last serial numbers below for each denomination.
            All cards within that serial number range will be added to the cart.
          </Typography>

          {serials.map((serialSet, index) =>
            <React.Fragment key={index}>
              {index != 0 &&
                <Divider style={{margin: '1em auto', width: '50%'}} />
              }
              <Grid container spacing={8}>
                <Grid item xs={11}>
                  <TextInput type="number" name={'first_serial_' + serialSet.id} label="First Card Serial Number" autoFocus required
                    value={serialSet.first_serial}
                    onBlur={() => this.handleSerialBlur(serialSet.id)}
                    inputProps={{
                      endAdornment: serialSet.isValidating ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                    }}
                    validationErrors={{
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />
                  <TextInput type="number" name={'last_serial_' + serialSet.id} label="Last Card Serial Number" required
                    value={serialSet.last_serial}
                    onBlur={() => this.handleSerialBlur(serialSet.id)}
                    inputProps={{
                      endAdornment: serialSet.isValidating ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                    }}
                    validations={'isGreaterEqualToField:' + 'first_serial_' + serialSet.id}
                    validationErrors={{
                      isGreaterEqualToField: 'must be greater than first serial number',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />

                  <TextInput type="number" name={'amount_' + serialSet.id} label="Amount Per Card" required
                    value={serialSet.amount}
                    inputProps={{
                      startAdornment: <InputAdornment position="start">{currencySymbol(webposProfile.currency_code)}</InputAdornment>
                    }}
                    onChange={(e) => updateSerial(serialSet.id, {amount: e.target.value})}
                    inputFieldProps={{
                      step: '0.01'
                    }}
                    validations={{
                      isGreaterEqualTo: minCardValue,
                      isLesserEqualTo: maxCardValue
                    }}
                    validationErrors={{
                      isGreaterEqualTo: 'must be ' + formatCurrency(minCardValue, webposProfile.currency_code) + ' or greater',
                      isLesserEqualTo: 'must be ' + formatCurrency(maxCardValue, webposProfile.currency_code) + ' or less',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />
                </Grid>
                {index !== 0 &&
                  <Grid item xs={1}>
                    <IconButton aria-label="Delete" onClick={() => removeSerial(serialSet.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                }
              </Grid>
            </React.Fragment>
          )}
          <Button variant="contained" color="secondary" size="small" onClick={() => addSerial()}>Add another denomination</Button>

          <div className="button-container">
            <Button type="submit" variant="contained" color="secondary">Next</Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}

BulkCardDetailsForm.defaultProps = {
  minCardValue: 5
};

function mapStateToProps(state) {
  return {
    serials: state.load.serials,
    webposProfile: state.session.webposProfile
  }
};

const mapDispatchToProps = {
  addSerial,
  updateSerial: (id, attrs) => updateSerial(id, attrs),
  removeSerial: (id) => removeSerial(id),
  validateSerial: (id, first, last, form) => validateSerial(id, first, last, form),
  flashMessage: (message, options = {}) => flashMessage(message, options)
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkCardDetailsForm)
