import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export default class LoginMenu extends React.Component {

  render() {
    return (
      <div className="login-links top-space">
        <NavLink to="/login" className="button-link" activeClassName="hidden">
          <Typography gutterBottom>Sign In</Typography>
        </NavLink>
        <NavLink to="/forgot_password" className="button-link" activeClassName="hidden">
          <Typography gutterBottom>Forgot Password?</Typography>
        </NavLink>
        <NavLink to="/resend_confirmation" className="button-link" activeClassName="hidden">
          <Typography gutterBottom>Didn't receive confirmation instructions?</Typography>
        </NavLink>
      </div>
    )
  }
}
