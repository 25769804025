import React from 'react';
import { Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import _ from 'lodash';
import { formatCurrency } from 'utils/currency';
import Formsy from 'formsy-react';
import CheckboxInput from 'components/inputs/CheckboxInput';
import HelpTooltip from 'components/HelpTooltip';
import renderHTML from 'react-render-html';

export default class PurchaseTotals extends React.Component {
  render() {
    const { webposProfile, cardQuantity, cardValue, cardFeesValue, creditCardFee,
            creditCardSurcharge, formData, totalAmount, salesTax, onDataChange } = this.props;

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Card Quantity:</TableCell>
            <TableCell align="right">{cardQuantity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Card Value:</TableCell>
            <TableCell align="right">{formatCurrency(cardValue, webposProfile.currency_code)}</TableCell>
          </TableRow>
          {creditCardFee &&
            <TableRow>
              <TableCell>{creditCardFee.name + " (" + creditCardFee.percentage + "%)"}:</TableCell>
              <TableCell align="right">{formatCurrency(creditCardSurcharge, webposProfile.currency_code)}</TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell>
              Card Fees:
              {cardQuantity >= webposProfile.waive_fee_quantity && webposProfile.waive_fee_quantity > 0 &&
                <Formsy onChange={onDataChange}>
                  <CheckboxInput name="waive_card_fee" label="Waive fees" />
                  <HelpTooltip message={renderHTML(webposProfile.waive_fee_message)} />
                </Formsy>
              }
            </TableCell>
            <TableCell align="right">{formatCurrency(cardFeesValue, webposProfile.currency_code)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Total:</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6">{formatCurrency(totalAmount, webposProfile.currency_code)}</Typography>
            </TableCell>
          </TableRow>
          {salesTax > 0 &&
            <TableRow>
              <TableCell>GST:</TableCell>
              <TableCell align="right">{formatCurrency(salesTax, webposProfile.currency_code)}</TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    );
  }
}
