import React from 'react';
import TextInput from 'components/inputs/TextInput';

export default class ChequePaymentMethodFieldset extends React.Component {
  render() {
    return (
      <TextInput name="name" label="Cheque Number" required
        validationErrors={{
          isDefaultRequiredValue: "can't be blank"
        }}
      />
    );
  }
}
