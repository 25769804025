import axios from 'axios';
import { flashMessage } from 'redux-flash';

export function cancelCard(card_id) {
  return(dispatch) => {
    return axios.put('cards/' + card_id + '/cancel')
      .then(response => {
        dispatch(flashMessage('Card cancelled successfully'));
        window.scrollTo(0, 0);
      }).catch(error => {
        dispatch(flashMessage('Error cancelling card', {isError: true}));
        window.scrollTo(0, 0);
      });
  }
}

export function resendCard(card_id) {
  return(dispatch) => {
    return axios.post('cards/' + card_id + '/resend')
      .then(response => {
        dispatch(flashMessage('Card has been successfully queued to resend'));
        window.scrollTo(0, 0);
      }).catch(error => {
        dispatch(flashMessage('Error resending card', {isError: true}));
        window.scrollTo(0, 0);
      });
  }
}
