export default {
  palette: {
    background: {
      default: 'rgb(0,0,0,0)'
    },
    secondary: {
      main: '#000'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        '@media print': {
          // Turn off Paper box-shadow when printing
          boxShadow: 'none !important'
        }
      }
    }
  }
};
