import axios from 'axios';
import { flashMessage } from 'redux-flash';
import { parseCardID, parseCreditCardSwipe } from 'utils/card-swipes';
import { push } from 'connected-react-router';
import { updateInvoiceBalance } from './SessionActions';

export const VALIDATE_STORED_CARD_REQUEST = 'VALIDATE_STORED_CARD_REQUEST';
export const VALIDATE_STORED_CARD_SUCCESS = 'VALIDATE_STORED_CARD_SUCCESS';
export const VALIDATE_STORED_CARD_ERROR = 'VALIDATE_STORED_CARD_ERROR';
export const VALIDATE_SERIAL_SUCCESS = 'VALIDATE_SERIAL_SUCCESS';
export const VALIDATE_CREDIT_CARD_REQUEST = 'VALIDATE_CREDIT_CARD_REQUEST';
export const VALIDATE_CREDIT_CARD_SUCCESS = 'VALIDATE_CREDIT_CARD_SUCCESS';
export const VALIDATE_CREDIT_CARD_ERROR = 'VALIDATE_CREDIT_CARD_ERROR';
export const ADD_CARD = 'ADD_CARD';
export const UPDATE_CARD = 'UPDATE_CARD';
export const REMOVE_CARD = 'REMOVE_CARD';
export const ADD_SERIAL = 'ADD_SERIAL';
export const UPDATE_SERIAL = 'UPDATE_SERIAL';
export const REMOVE_SERIAL = 'REMOVE_SERIAL';
export const PARSE_CREDIT_CARD_START = 'PARSE_CREDIT_CARD_START';
export const PARSE_CREDIT_CARD_COMPLETE = 'PARSE_CREDIT_CARD_COMPLETE';
export const LOAD_CARD_REQUEST = 'LOAD_CARD_REQUEST';
export const LOAD_CARD_SUCCESS = 'LOAD_CARD_SUCCESS';
export const LOAD_CARD_ERROR = 'LOAD_CARD_ERROR';
export const BULK_DIGITAL_SUCCESS = 'BULK_DIGITAL_SUCCESS';

function validateStoredCardRequest(id) {
  return {
    type: VALIDATE_STORED_CARD_REQUEST,
    id
  }
}

function validateStoredCardSuccess(id, cardFee, product) {
  return {
    type: VALIDATE_STORED_CARD_SUCCESS,
    id,
    cardFee,
    product
  }
}

function validateStoredCardError(id, cardError) {
  return {
    type: VALIDATE_STORED_CARD_ERROR,
    id,
    cardError
  }
}

function validateSerialSuccess(id, first, last, fee) {
  return {
    type: VALIDATE_SERIAL_SUCCESS,
    id,
    first,
    last,
    fee
  }
}

function validateCreditCardRequest() {
  return {
    type: VALIDATE_CREDIT_CARD_REQUEST
  }
}

function validateCreditCardSuccess(creditCardFee) {
  return {
    type: VALIDATE_CREDIT_CARD_SUCCESS,
    creditCardFee
  }
}

function validateCreditCardError() {
  return {
    type: VALIDATE_CREDIT_CARD_ERROR
  }
}


function addCardRequest() {
  return {
    type: ADD_CARD
  }
}

function updateCardRequest(id, attrs) {
  return {
    type: UPDATE_CARD,
    id,
    attrs
  }
}

function removeCardRequest(id) {
  return {
    type: REMOVE_CARD,
    id
  }
}

function addSerialRequest() {
  return {
    type: ADD_SERIAL
  }
}

function updateSerialRequest(id, attrs) {
  return {
    type: UPDATE_SERIAL,
    id,
    attrs
  }
}

function removeSerialRequest(id) {
  return {
    type: REMOVE_SERIAL,
    id
  }
}

function loadCardRequest() {
  return {
    type: LOAD_CARD_REQUEST
  }
}

function loadCardSuccess() {
  return {
    type: LOAD_CARD_SUCCESS
  }
}

function loadCardError() {
  return {
    type: LOAD_CARD_ERROR
  }
}

function parseCreditCardStart() {
  return {
    type: PARSE_CREDIT_CARD_START
  }
}

function parseCreditCardComplete(creditCard) {
  return {
    type: PARSE_CREDIT_CARD_COMPLETE,
    creditCard
  }
}

function bulkDigitalSuccess(cards) {
  return {
    type: BULK_DIGITAL_SUCCESS,
    cards
  }
}

export function validateStoredCard(id, cardNumber) {
  return dispatch => {
    dispatch(validateStoredCardRequest(id));
    return axios.post('/validations/stored_card', {card_number: cardNumber})
      .then((response) => {
        dispatch(validateStoredCardSuccess(id, response.data.fee || 0, response.data.product));
      }).catch(error => {
        if (error.response.status === 400) {
          dispatch(validateStoredCardError(id, error.response.data.message));
        }
      });
  }
}

export function validateSerial(id, first, last, form) {
  return dispatch => {
    dispatch(updateSerialRequest(id, {isValidating: true}));
    return axios.post('/validations/serial', {first, last})
      .then((response) => {
        dispatch(validateSerialSuccess(id, first, last, response.data.fee || 0));
      }).catch(error => {
        if (error.response.status === 400) {
          const firstError = error.response.data.first_error;
          const lastError = error.response.data.last_error;
          dispatch(updateSerialRequest(id, {isValidating: false, firstError, lastError}));
          form.updateInputsWithError({['first_serial_' + id]: firstError, ['last_serial_' + id]: lastError});
        }
      });
  }
}

export function addCard() {
  return dispatch => {
    dispatch(addCardRequest());
  }
}

export function updateCard(id, attrs) {
  return dispatch => {
    dispatch(updateCardRequest(id, attrs));
  }
}

export function removeCard(id) {
  return dispatch => {
    dispatch(removeCardRequest(id));
  }
}

export function addSerial() {
  return dispatch => {
    dispatch(addSerialRequest());
  }
}

export function updateSerial(id, attrs) {
  return dispatch => {
    dispatch(updateSerialRequest(id, attrs));
  }
}

export function removeSerial(id) {
  return dispatch => {
    dispatch(removeSerialRequest(id));
  }
}

// Parse input value to determine card number
// Params:
// id: id of card in redux
// inputValue: value in the text input
// cardRef: reference to the card ID input
// amountRef: reference to the amount text input
export function parseCard(id, inputValue, cardRef, amountRef) {
  return dispatch => {
    const value = inputValue.toLocaleString();
    const cardId = parseCardID(value);

    if (cardId) {
      dispatch(updateCardRequest(id, {card_number: cardId}));
      cardRef.current.value = cardId;
      amountRef.focusTextInput();
    }
  }
}

// Parse input value to determine credit card details
// Params:
// inputValue: value in the text input
// cvvRef: reference to the CVV input
// numberRef: reference to the credit card number input
export function parseCreditCard(inputValue, cvvRef, numberRef) {
  return dispatch => {
    if (inputValue.length > 19 && /%.*\?;.*=*\?$/.test(inputValue)) {
      dispatch(parseCreditCardStart());

      const values = parseCreditCardSwipe(inputValue);

      dispatch(parseCreditCardComplete(values));
      numberRef.current.value = values.number;
      cvvRef.focusTextInput();
    }
  }
}

export function validateCreditCard(creditCardNumber, formRef) {
  return dispatch => {
    dispatch(validateCreditCardRequest());
    return axios.post('/validations/credit_card', {credit_card_number: creditCardNumber})
      .then((response) => {
        dispatch(validateCreditCardSuccess(response.data.fee || null));
      }).catch(error => {
        if (error.response.status === 400) {
          dispatch(validateCreditCardError());
          formRef.current.updateInputsWithError({credit_card_number: error.response.data.message});
        }
      });
  }
}

export function processEftposType(type) {
  return dispatch => {
    return axios.post('/validations/eftpos_card_type', {type})
      .then((response) => {
        dispatch(validateCreditCardSuccess(response.data.fee || null));
      })
  }
}

export function loadCard(cards, data) {
  return dispatch => {
    dispatch(loadCardRequest());
    return axios.post('/loads', {cards: cards, transaction: data})
      .then((response) => {
        dispatch(loadCardSuccess());
        if (response.data.invoice) {
          // ensure transaction balance is updated in dropdown for back-to-back invoice purchases
          dispatch(updateInvoiceBalance(response.data.invoice));
        }
        dispatch(flashMessage('Purchase was successful'));
        dispatch(push('/purchases/' + response.data.transaction_id + '?success=true'));
      }).catch(error => {
        if (error.response.status == 400) {
          dispatch(loadCardError());
          dispatch(flashMessage(error.response.data.error, {isError: true}));
        }
      });
  }
}

export function bulkLoad(serials, data) {
  return dispatch => {
    dispatch(loadCardRequest());
    return axios.post('/loads/bulk', {serials, transaction: data})
      .then((response) => {
        dispatch(loadCardSuccess());
        if (response.data.invoice) {
          // ensure transaction balance is updated in dropdown for back-to-back invoice purchases
          dispatch(updateInvoiceBalance(response.data.invoice));
        }
        dispatch(flashMessage('Purchase was successful'));
        dispatch(push('/purchases/' + response.data.transaction_id + '?success=true'));
      }).catch(error => {
        if (error.response.status == 400) {
          dispatch(loadCardError());
          dispatch(flashMessage(error.response.data.error, {isError: true}));
        }
      });
  }
}

export function validateBulkDigitalFile(data, onSuccess, onFailure) {
  return dispatch => {
    return axios.post('/validations/bulk_digital_file', data)
      .then(response => {
        dispatch(bulkDigitalSuccess(response.data.cards));
        onSuccess(response);
      }).catch(error => {
        onFailure(error);
      });
  }
}

export function updateCards(cards) {
  return dispatch => {
    dispatch(bulkDigitalSuccess(cards));
  }
}
