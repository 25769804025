import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, CircularProgress, InputAdornment, Button,
         Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DateInput from 'components/inputs/DateInput';
import moment from 'moment';
import { formatCurrency } from 'utils/currency';
import ReportMenu from 'components/menus/ReportMenu';
import { fetchUnusedFundsReportData, downloadUnusedFundsReportData } from 'actions/ReportActions';

export class UnusedFundsReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.fetchUnusedFundsReportData(this.formRef.current.getModel());
  }

  render() {
    const { isFetching, reportData, downloadUnusedFundsReportData, webposProfile } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ReportMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Unused Funds Report</Typography>
            <Typography varian="p" align="center" paragraph>
              This report is up to date as of the previous day, it will not include data from the current day.
            </Typography>

            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_to" label="Date Up To" disableFuture required
                    value={moment().format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Search</Button>
                </Grid>
              </Grid>
            </Formsy>

            {isFetching && <center><CircularProgress size={30} /></center>}

            {reportData &&
              <React.Fragment>
                <Grid container spacing={16} className="top-space" justify="center">
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Cards</Typography>
                    <Typography variant="h5" color="primary">{reportData.total_cards}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Unused Funds</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(reportData.total_unused_value, webposProfile.currency_code)}</Typography>
                  </Grid>
                </Grid>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="dense">Site Name</TableCell>
                      <TableCell align="right" padding="dense">Total Cards</TableCell>
                      <TableCell align="right" padding="dense">Total Unused Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.group_data.map((row, index) =>
                      <TableRow key={index}>
                        <TableCell padding="dense">{row.name}</TableCell>
                        <TableCell align="right" padding="dense">{row.total_cards}</TableCell>
                        <TableCell align="right" padding="dense">{formatCurrency(row.unused_funds_value, webposProfile.currency_code)}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <Typography variant="body1" className="top-space">
                  Download results in <button onClick={() => downloadUnusedFundsReportData(this.formRef.current.getModel())} className="raw-button-link">Excel</button>
                </Typography>
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.report.isFetching,
    reportData: state.report.unusedFundsData,
    webposProfile: state.session.webposProfile
  }
}

const mapDispatchToProps = {
  fetchUnusedFundsReportData: (data) => fetchUnusedFundsReportData(data),
  downloadUnusedFundsReportData: (data) => downloadUnusedFundsReportData(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(UnusedFundsReportPage)
