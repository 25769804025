import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { FormHelperText } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers'
import moment from 'moment';

const dateTimeFormat = 'DD/MM/YYYY H:mm';

export class DateTimeInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: (this.props.value && moment(this.props.value, dateTimeFormat)) || null
    };

    this.props.setValue(this.props.value, false);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.format(dateTimeFormat));
    this.setState({ value: event });
  }

  render() {
    const { name, label, hint, inputProps, errorMessage, isPristine,
            disableFuture, shouldDisableDate, startDate, endDate } = this.props;
    const isError = !isPristine && errorMessage != null;

    return (
      <div className="input-container">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            error={isError}
            disableFuture={disableFuture}
            id={name}
            name={name}
            value={this.state.value}
            onChange={this.changeValue}
            label={label}
            format={dateTimeFormat}
            shouldDisableDate={shouldDisableDate}
            InputProps={inputProps}
            fullWidth
            minDate={startDate || '1900-01-01'}
            maxDate={endDate || '2100-01-01' }
          />
        </MuiPickersUtilsProvider>
        {!isPristine && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

DateTimeInput.propTypes = {
  ...propTypes
};

export default withFormsy(DateTimeInput);
