import {
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAILURE,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  INVOICE_ADD_CARD,
  INVOICE_UPDATE_CARD,
  INVOICE_REMOVE_CARD
} from '../actions/InvoiceActions'

const initialState = {
  isFetching: false,
  invoices: [],
  invoiceErrors: null,
  isProcessing: false,
  cards: []
};

export default function invoice(state = initialState, action) {
  switch(action.type) {
    case FETCH_INVOICES_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_INVOICES_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        invoices: action.invoices
      });
    }
    case FETCH_INVOICES_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case CREATE_INVOICE_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true,
        invoiceErrors: null
      });
    }
    case CREATE_INVOICE_SUCCESS : {
      return Object.assign({}, state, {
        isProcessing: false,
        invoiceErrors: null,
        cards: []
      });
    }
    case CREATE_INVOICE_FAILURE : {
      return Object.assign({}, state, {
        isProcessing: false,
        invoiceErrors: action.errors
      });
    }
    case INVOICE_ADD_CARD : {
      return Object.assign({}, state, {
        cards: state.cards.concat({id: new Date().getTime(), product_id: null, value_per_card: null, number_of_cards: null})
      });
    }
    case INVOICE_UPDATE_CARD : {
      const newCards = state.cards.map((card) => {
        if (card.id != action.id) return card;
        return Object.assign({}, {...card}, action.attrs);
      });

      return Object.assign({}, state, {
        cards: newCards
      });
    }
    case INVOICE_REMOVE_CARD : {
      return Object.assign({}, state, {
        cards: state.cards.filter((card) => card.id != action.id)
      });
    }
    default:
      return state;
  }
}
