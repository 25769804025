import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Typography, Divider } from '@material-ui/core';

export class ApiHelpPage extends React.Component {
  render() {
    const { user } = this.props;

    return (
      <Grid container spacing={16} justify="center">
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>API Access</Typography>
            <Typography variant="subtitle1" align="center" style={{marginBottom: '2em'}}>
              This access token can be used to access Givv APIs.
              Do not share your access token secret with anyone.
            </Typography>

            <Typography variant="subtitle1" align="center" gutterBottom>Access Token</Typography>
            <Typography variant="subtitle1" align="center" color="secondary" gutterBottom>{user.api.token}</Typography>
            <Divider style={{margin: '1em'}} />
            <Typography variant="subtitle1" align="center" gutterBottom>Access Token Secret</Typography>
            <Typography variant="subtitle1" align="center" color="secondary" gutterBottom>{user.api.secret}</Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.session.currentUser
  }
}

export default connect(mapStateToProps, null)(ApiHelpPage)
