import { createIntl, createIntlCache } from 'react-intl';

// Formats a given currency amount to (-)$XXX.XX format
export function formatCurrency(amount, currencyCode) {
  var amount = parseFloat(amount);
  var negative = amount < 0 ? '-' : '';
  var rounded = Math.round(Math.abs(amount) * 100) / 100;

  return negative + intl().formatNumber(rounded.toFixed(2), {style: 'currency', currency: currencyCode || 'AUD', currencyDisplay: 'narrowSymbol'});
}

export function currencySymbol(currencyCode) {
  return intl().formatNumberToParts(0, {style: 'currency', currency: currencyCode || 'AUD', currencyDisplay: 'narrowSymbol'}).find((part) => part.type === 'currency').value;
}

function intl() {
  return createIntl({ locale: 'en' }, createIntlCache());
}
