import React from 'react';
import { connect } from 'react-redux';
import { Paper, MenuList, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { hasPermission } from 'utils/auth';

export class ReportMenu extends React.Component {
  render() {
    const { permissions, webposProfile } = this.props;

    return (
      <Paper>
        <MenuList className="menu" disablePadding>
          {hasPermission(permissions, 'read', 'Organisation') &&
            <NavLink to="/reports/organisation" exact activeClassName="active">
              <MenuItem>Organisation Report</MenuItem>
            </NavLink>
          }
          {hasPermission(permissions, 'read', 'Transaction') &&
            <NavLink to="/reports/purchases" exact isActive={(match, location) => {return location.pathname == '/reports' || location.pathname == '/reports/purchases'}} activeClassName="active">
              <MenuItem>{webposProfile.title} Purchases</MenuItem>
            </NavLink>
          }
          {hasPermission(permissions, 'read', 'Transaction') &&
            <NavLink to="/reports/all_purchases" exact activeClassName="active">
              <MenuItem>All Purchases</MenuItem>
            </NavLink>
          }
          {hasPermission(permissions, 'read', 'TransactionRecord') &&
            <NavLink to="/reports/customer_redemptions" exact activeClassName="active">
              <MenuItem>Redemption Report</MenuItem>
            </NavLink>
          }
          {hasPermission(permissions, 'read', 'TransactionRecord') && hasPermission(permissions, 'read', 'Organisation') &&
            <NavLink to="/reports/unused_funds" exact activeClassName="active">
              <MenuItem>Unused Funds Report</MenuItem>
            </NavLink>
          }
          {hasPermission(permissions, 'read', 'Transaction') &&
            <NavLink to="/reports/products" exact activeClassName="active">
              <MenuItem>Product Report</MenuItem>
            </NavLink>
          }
          {hasPermission(permissions, 'read', 'Transaction') &&
            _.includes(_.map(webposProfile.payment_methods, (m) => { return m[1] }), 'invoice') &&
              <NavLink to="/reports/invoices" exact activeClassName="active">
                <MenuItem>Invoice Summary</MenuItem>
              </NavLink>
          }
          {hasPermission(permissions, 'read', 'Transaction') &&
            _.includes(_.map(webposProfile.payment_methods, (m) => { return m[1] }), 'invoice') &&
              <NavLink to="/reports/active_invoices" exact activeClassName="active">
                <MenuItem>Active Invoice Report</MenuItem>
              </NavLink>
          }
          {webposProfile.webstore_enabled && hasPermission(permissions, 'read', 'Organisation') &&
            <NavLink to="/reports/webstores" exact activeClassName="active">
              <MenuItem>Webstores Report</MenuItem>
            </NavLink>
          }
        </MenuList>
      </Paper>
    )
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.session.permissions,
    webposProfile: state.session.webposProfile
  }
}

export default connect(mapStateToProps)(ReportMenu)
