import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Typography, Button } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import { createPassword } from 'actions/SessionActions';
import queryString from 'query-string';
import PasswordForm from 'components/forms/PasswordForm';

export class CreatePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _data, _invalidateForm) {
    data.confirmation_token = queryString.parse(this.props.location.search).confirmation_token;
    this.props.createPassword(data);
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>Please create a password</Typography>
        <PasswordForm handleSubmit={this.handleSubmit} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  createPassword: (data) => createPassword(data)
};

export default connect(null, mapDispatchToProps)(CreatePasswordPage)
