import React from 'react';
import TextInput from 'components/inputs/TextInput';
import { Typography, InputAdornment } from '@material-ui/core';
import { formatCurrency, currencySymbol } from 'utils/currency';

export default class CashPaymentMethodFieldset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalPaid: null
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({target}) {
    this.setState({totalPaid: target.value});
  }

  render() {
    const { totalCost, webposProfile } = this.props;
    let changeDue = this.state.totalPaid - totalCost;
    if (changeDue < 0) changeDue = 0; // Don't show change until valid amount

    return (
      <React.Fragment>
        <TextInput type="number" name="total_paid" label="Total Paid" required
          onChange={this.handleChange}
          validations={{
            isGreaterEqualTo: totalCost
          }}
          validationErrors={{
            isGreaterEqualTo: 'Insufficient funds',
            isDefaultRequiredValue: "can't be blank"
          }}
          inputProps={{
            startAdornment: <InputAdornment position="start">{currencySymbol(webposProfile.currency_code)}</InputAdornment>
          }}
          inputFieldProps={{
            step: '0.01'
          }}
        />

        <Typography variant="subtitle1" align="center">Change Due</Typography>
        <Typography variant="h5" color="primary" align="center">{formatCurrency(changeDue, webposProfile.currency_code)}</Typography>
      </React.Fragment>
    );
  }
}
