import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { AppRoute } from './AppRoute';
import SessionRouter from './SessionRouter';
import LoginLayout from 'components/layouts/LoginLayout';
import { LoginPage, ForgotPasswordPage, ResendConfirmationPage, CreatePasswordPage, ResetPasswordPage } from './pages/all';
import LoadingComponent from './LoadingComponent';

export class Router extends React.Component {
  render() {
    if (this.props.isFetching) {
      return <LoadingComponent />;
    }

    return (
      <React.Fragment>
        <Switch>
          <AppRoute exact path="/login" layout={LoginLayout} component={LoginPage} />
          <AppRoute exact path="/forgot_password" layout={LoginLayout} component={ForgotPasswordPage} />
          <AppRoute exact path="/password/edit" layout={LoginLayout} component={ResetPasswordPage} />
          <AppRoute exact path="/resend_confirmation" layout={LoginLayout} component={ResendConfirmationPage} />
          <AppRoute exact path="/users/confirm" layout={LoginLayout} component={CreatePasswordPage} />
          <Route component={SessionRouter} />
        </Switch>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.session.isFetching
  };
}

export default withRouter(connect(mapStateToProps)(Router));
