import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button, Divider } from '@material-ui/core';
import SelectInput from 'components/inputs/SelectInput';
import { formatCurrency } from 'utils/currency';
import ReportMenu from 'components/menus/ReportMenu';
import { hasPermission } from 'utils/auth';
import { fetchActiveInvoiceReportData, downloadActiveInvoiceReportData } from 'actions/ReportActions';

export class ActiveInvoiceReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group_id: 'all'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { fetchActiveInvoiceReportData, permissions } = this.props;

    if (!hasPermission(permissions, 'read', 'Organisation')) {
      fetchActiveInvoiceReportData({});
    }
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.fetchActiveInvoiceReportData(data);
  }

  render() {
    const { webposProfile, isFetching, activeInvoiceData, downloadActiveInvoiceReportData, permissions } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ReportMenu />
        </Grid>
        {/* Set min-height so page is always big enough should the group dropdown be shown */}
        <Grid item md={8} className="full-width" style={{minHeight: '500px'}}>
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Active Invoice Report</Typography>
            {hasPermission(permissions, 'read', 'Organisation') &&
              <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
                <Grid container spacing={16} justify="center">
                  <Grid item sm={10} md={4} className="full-width">
                    <SelectInput name="group_id" label="Site"
                      options={[{label: 'All', value: 'all'}].concat(webposProfile.groups)}
                      value={this.state.group_id}
                      onChange={(e) => this.setState({group_id: e.target.value})}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={16} justify="center">
                  <Grid item sm={10} md={6} className="full-width">
                    <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Search</Button>
                  </Grid>
                </Grid>
              </Formsy>
            }

            {activeInvoiceData &&
              <React.Fragment>
                <Grid container spacing={16} className="top-space" justify="center">
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Number of Active Invoices</Typography>
                    <Typography variant="h5" color="primary">{activeInvoiceData.invoice_count}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Active Balance</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(activeInvoiceData.total_current_balance, webposProfile.currency_code)}</Typography>
                  </Grid>
                </Grid>

                <Divider className="report-divider" />

                <Typography variant="body1" className="top-space">
                  Download detailed data in <button onClick={() => downloadActiveInvoiceReportData(this.state)} className="raw-button-link">Excel</button>
                </Typography>
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    isFetching: state.report.isFetching,
    activeInvoiceData: state.report.activeInvoiceData,
    permissions: state.session.permissions
  }
}

const mapDispatchToProps = {
  fetchActiveInvoiceReportData: (data) => fetchActiveInvoiceReportData(data),
  downloadActiveInvoiceReportData: (data) => downloadActiveInvoiceReportData(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveInvoiceReportPage)
