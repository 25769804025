import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import UserMenu from 'components/menus/UserMenu';
import SelectInput from 'components/inputs/SelectInput';
import { formatCurrency } from 'utils/currency';
import { fetchDigitalFloat } from 'actions/DigitalPartnerActions';

export class DigitalFloatPage extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { webposProfile, fetchDigitalFloat } = this.props;

    if (webposProfile.digital_partners.length == 1) {
      fetchDigitalFloat(webposProfile.digital_partners[0].value, null);
    }
  }

  handleChange(event) {
    this.props.fetchDigitalFloat(event.target.value, this.formRef);
  }

  render() {
    const { webposProfile, isFetching, digitalPartner } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <UserMenu />
        </Grid>
        <Grid item md={10} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Digital Floats</Typography>
            <Formsy ref={this.formRef} className="top-space">
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={3} className="full-width">
                  {webposProfile.digital_partners && webposProfile.digital_partners.length > 1 &&
                    <SelectInput name="id" label="Digital Float" options={webposProfile.digital_partners} onChange={this.handleChange} />
                  }
                  {isFetching &&
                    <Grid container justify="center" className="top-space">
                      <CircularProgress />
                    </Grid>
                  }
                  {digitalPartner &&
                    <React.Fragment>
                      <Typography variant="subtitle1" align="center">{digitalPartner.name}</Typography>
                      <Typography variant="h4" color="primary" align="center">{formatCurrency(digitalPartner.balance, webposProfile.currency_code)}</Typography>
                    </React.Fragment>
                  }
                </Grid>
              </Grid>
              {digitalPartner &&
                <Typography variant="body1" align="center" className="top-space">
                  Please allow 72 hours for recent float top-up payments to be reflected in the float balance.
                </Typography>
              }
            </Formsy>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    digitalPartner: state.digitalPartner.digitalPartner,
    isFetching: state.digitalPartner.isFetching
  }
}

const mapDispatchToProps = {
  fetchDigitalFloat: (id, form) => fetchDigitalFloat(id, form)
};

export default connect(mapStateToProps, mapDispatchToProps)(DigitalFloatPage)
