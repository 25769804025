import axios from 'axios';

export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';
export const SEARCH_TRANSACTIONS_REQUEST = 'SEARCH_TRANSACTIONS_REQUEST';
export const SEARCH_TRANSACTIONS_SUCCESS = 'SEARCH_TRANSACTIONS_SUCCESS';
export const SEARCH_TRANSACTIONS_FAILURE = 'SEARCH_TRANSACTIONS_FAILURE';

function fetchBalanceRequest() {
  return {
    type: FETCH_BALANCE_REQUEST
  }
}

function fetchBalanceSuccess(data) {
  return {
    type: FETCH_BALANCE_SUCCESS,
    data
  }
}

function fetchBalanceFailure() {
  return {
    type: FETCH_BALANCE_FAILURE
  }
}

function searchTransactionsRequest() {
  return {
    type: SEARCH_TRANSACTIONS_REQUEST
  }
}

function searchTransactionsSuccess(transactions) {
  return {
    type: SEARCH_TRANSACTIONS_SUCCESS,
    transactions
  }
}

function searchTransactionsFailure() {
  return {
    type: SEARCH_TRANSACTIONS_FAILURE
  }
}

export function fetchBalance(data, form) {
  return(dispatch) => {
    dispatch(fetchBalanceRequest());
    return axios.post('balance', data)
      .then(response => {
        dispatch(fetchBalanceSuccess(response.data.card));
      }).catch(error => {
        dispatch(fetchBalanceFailure());
        if (error.response.data.error) {
          form.current && form.current.updateInputsWithError({card_number: error.response.data.error});
        }
      });
  }
}

export function searchTransactions(data) {
  return(dispatch) => {
    dispatch(searchTransactionsRequest());
    return axios.post('purchases/search', data)
      .then(response => {
        dispatch(searchTransactionsSuccess(response.data.transactions));
      }).catch(error => {
        dispatch(searchTransactionsFailure());
      });
  }
}
