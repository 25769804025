import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';

class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    this.props.setValue(this.props.value || '0', false);

    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { setValue, onChange } = this.props;
    setValue(event.target.checked ? '1' : '0');
    if (onChange) onChange(event);
  }

  render() {
    const { name, label, hint, value, errorMessage, isPristine } = this.props;
    const isError = !isPristine && errorMessage != null;

    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Checkbox id={name} checked={value == '1'} onChange={this.changeValue} value={name} />
          }
          label={label}
        />
        {!isPristine && errorMessage &&
          <FormHelperText error={isError} style={{marginTop: 0, marginBottom: '1em'}}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </React.Fragment>
    );
  }
}

CheckboxInput.propTypes = {
  ...propTypes
};

export default withFormsy(CheckboxInput);
