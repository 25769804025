import React from 'react';
import { Typography } from '@material-ui/core';

export default class ReceiptHeader extends React.Component {
  render() {
    const { item, webposProfile } = this.props;
    const itemWebposProfile = item.webpos_profile;

    return (
      <React.Fragment>
        <div className='pos-receipt'>
          <div style={{display: 'inline-block'}}>
            <img src={itemWebposProfile.receipt_image} width='150' />
            <Typography>
              Email: {webposProfile.support_email}
              <br />
              Phone: {webposProfile.support_phone}
            </Typography>
          </div>
          <div style={{float: 'right'}}>
            <Typography>
              {itemWebposProfile.company}
              <br />
              {itemWebposProfile.address_line_1}
              <br />
              {itemWebposProfile.address_line_2 &&
                <React.Fragment>
                  {itemWebposProfile.address_line_2}
                  <br />
                </React.Fragment>
              }
              {itemWebposProfile.suburb}
              <br />
              {itemWebposProfile.state} {itemWebposProfile.postcode}
              <br />
              <React.Fragment>
                ABN: {itemWebposProfile.abn}
              </React.Fragment>
            </Typography>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
