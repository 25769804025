import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  SEND_RESET_INSTRUCTIONS_REQUEST,
  SEND_RESET_INSTRUCTIONS_SUCCESS,
  SEND_RESET_INSTRUCTIONS_FAILURE,
  SEND_CONFIRM_INSTRUCTIONS_REQUEST,
  SEND_CONFIRM_INSTRUCTIONS_SUCCESS,
  SEND_CONFIRM_INSTRUCTIONS_FAILURE
} from 'actions/ProfileActions';

const initialState = {
  isFetching: false,
  user: null,
  webposProfile: null,
  permissions: null,
  isProcessing: false
};

export default function profile(state = initialState, action) {
  switch(action.type) {
    case FETCH_PROFILE_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_PROFILE_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        user: action.user,
        webposProfile: action.webposProfile,
        permissions: action.permissions
      });
    }
    case FETCH_PROFILE_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case SEND_RESET_INSTRUCTIONS_REQUEST :
    case SEND_CONFIRM_INSTRUCTIONS_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case SEND_RESET_INSTRUCTIONS_SUCCESS :
    case SEND_CONFIRM_INSTRUCTIONS_SUCCESS : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case SEND_RESET_INSTRUCTIONS_FAILURE :
    case SEND_CONFIRM_INSTRUCTIONS_FAILURE : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    default:
      return state;
  }
}
