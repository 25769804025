import React from 'react';
import Formsy from 'formsy-react';
import { Button } from '@material-ui/core';
import CardPaymentMethodFieldset from 'components/fieldsets/load/CardPaymentMethodFieldset';
import InvoicePaymentMethodFieldset from 'components/fieldsets/load/InvoicePaymentMethodFieldset';
import EftposPaymentMethodFieldset from 'components/fieldsets/load/EftposPaymentMethodFieldset';
import ChequePaymentMethodFieldset from 'components/fieldsets/load/ChequePaymentMethodFieldset';
import CashPaymentMethodFieldset from 'components/fieldsets/load/CashPaymentMethodFieldset';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import CheckboxInput from 'components/inputs/CheckboxInput';

const labels = {
  card: 'ID has been verified',
  cash: 'Cash has been taken'
};

export default class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { paymentMethod, totalCost, cardCost, webposProfile, handleDataChange, formData,
            handleNext, handleBack, isProcessing, disableSubmit } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleNext} ref={this.formRef}>
          {paymentMethod === 'card' && !webposProfile.encrypted_card_reader &&
            <CardPaymentMethodFieldset formRef={this.formRef} />
          }
          {paymentMethod === 'invoice' &&
            <InvoicePaymentMethodFieldset formRef={this.formRef} cardCost={cardCost} totalCost={totalCost} />
          }
          {paymentMethod === 'eftpos' &&
            <EftposPaymentMethodFieldset webposProfile={webposProfile} />
          }
          {paymentMethod === 'cheque' &&
            <ChequePaymentMethodFieldset />
          }
          {paymentMethod === 'cash' &&
            <CashPaymentMethodFieldset totalCost={totalCost} webposProfile={webposProfile} />
          }

          {webposProfile.collect_customer_data &&
            <React.Fragment>
              <TextInput name="customer_name" label="Customer Name" hint="Optional" />
              <TextInput type="email" name="customer_email" label="Customer Email"
                hint="Optional. Receipt will be automatically sent by email"
                validations="isEmail"
                validationErrors={{isEmail: 'not a valid email'}}
              />
              <TextInput type="number" name="customer_phone" label="Customer Phone" hint="Optional" />
            </React.Fragment>
          }

          {webposProfile.custom_merchant_field_enabled &&
            (webposProfile.custom_merchant_field_options.length ?
              <SelectInput name="custom_merchant_field" label={webposProfile.custom_merchant_field}
                options={webposProfile.custom_merchant_field_options}
              />
            :
              <TextInput name="custom_merchant_field" label={webposProfile.custom_merchant_field} />
            )
          }

          <CheckboxInput name="accepted" label={labels[paymentMethod] || 'Payment has been processed'} value="0"
            validations="equals:&quot;1&quot;"
            validationErrors={{
              equals: 'Must check to continue'
            }}
          />

          <div className="button-container">
            <Button variant="contained" disabled={isProcessing} onClick={handleBack}>Back</Button>
            <Button type="submit" disabled={isProcessing || disableSubmit} variant="contained" color="secondary">Submit</Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}
