import jstz from 'jstz';
import _ from 'lodash';

const timezoneMap = {
  "Pacific/Pago_Pago": ["(GMT-11:00) American Samoa", "American Samoa"],
  "Pacific/Midway": ["(GMT-11:00) International Date Line West", "International Date Line West"],
  "Pacific/Midway": ["(GMT-11:00) Midway Island", "Midway Island"],
  "Pacific/Honolulu": ["(GMT-10:00) Hawaii", "Hawaii"],
  "America/Juneau": ["(GMT-09:00) Alaska", "Alaska"],
  "America/Los_Angeles": ["(GMT-08:00) Pacific Time (US & Canada)", "Pacific Time (US & Canada)"],
  "America/Tijuana": ["(GMT-08:00) Tijuana", "Tijuana"],
  "America/Phoenix": ["(GMT-07:00) Arizona", "Arizona"],
  "America/Chihuahua": ["(GMT-07:00) Chihuahua", "Chihuahua"],
  "America/Mazatlan": ["(GMT-07:00) Mazatlan", "Mazatlan"],
  "America/Denver": ["(GMT-07:00) Mountain Time (US & Canada)", "Mountain Time (US & Canada)"],
  "America/Guatemala": ["(GMT-06:00) Central America", "Central America"],
  "America/Chicago": ["(GMT-06:00) Central Time (US & Canada)", "Central Time (US & Canada)"],
  "America/Mexico_City": ["(GMT-06:00) Guadalajara", "Guadalajara"],
  "America/Mexico_City": ["(GMT-06:00) Mexico City", "Mexico City"],
  "America/Monterrey": ["(GMT-06:00) Monterrey", "Monterrey"],
  "America/Regina": ["(GMT-06:00) Saskatchewan", "Saskatchewan"],
  "America/Bogota": ["(GMT-05:00) Bogota", "Bogota"],
  "America/New_York": ["(GMT-05:00) Eastern Time (US & Canada)", "Eastern Time (US & Canada)"],
  "America/Indiana/Indianapolis": ["(GMT-05:00) Indiana (East)", "Indiana (East)"],
  "America/Lima": ["(GMT-05:00) Lima", "Lima"],
  "America/Lima": ["(GMT-05:00) Quito", "Quito"],
  "America/Caracas": ["(GMT-04:30) Caracas", "Caracas"],
  "America/Halifax": ["(GMT-04:00) Atlantic Time (Canada)", "Atlantic Time (Canada)"],
  "America/Guyana": ["(GMT-04:00) Georgetown", "Georgetown"],
  "America/La_Paz": ["(GMT-04:00) La Paz", "La Paz"],
  "America/St_Johns": ["(GMT-03:30) Newfoundland", "Newfoundland"],
  "America/Sao_Paulo": ["(GMT-03:00) Brasilia", "Brasilia"],
  "America/Argentina/Buenos_Aires": ["(GMT-03:00) Buenos Aires", "Buenos Aires"],
  "America/Godthab": ["(GMT-03:00) Greenland", "Greenland"],
  "America/Santiago": ["(GMT-03:00) Santiago", "Santiago"],
  "Atlantic/South_Georgia": ["(GMT-02:00) Mid-Atlantic", "Mid-Atlantic"],
  "Atlantic/Azores": ["(GMT-01:00) Azores", "Azores"],
  "Atlantic/Cape_Verde": ["(GMT-01:00) Cape Verde Is.", "Cape Verde Is."],
  "Africa/Casablanca": ["(GMT+00:00) Casablanca", "Casablanca"],
  "Europe/Dublin": ["(GMT+00:00) Dublin", "Dublin"],
  "Europe/London": ["(GMT+00:00) Edinburgh", "Edinburgh"],
  "Europe/Lisbon": ["(GMT+00:00) Lisbon", "Lisbon"],
  "Europe/London": ["(GMT+00:00) London", "London"],
  "Africa/Monrovia": ["(GMT+00:00) Monrovia", "Monrovia"],
  "Etc/UTC": ["(GMT+00:00) UTC", "UTC"],
  "Europe/Amsterdam": ["(GMT+01:00) Amsterdam", "Amsterdam"],
  "Europe/Belgrade": ["(GMT+01:00) Belgrade", "Belgrade"],
  "Europe/Berlin": ["(GMT+01:00) Berlin", "Berlin"],
  "Europe/Berlin": ["(GMT+01:00) Bern", "Bern"],
  "Europe/Bratislava": ["(GMT+01:00) Bratislava", "Bratislava"],
  "Europe/Brussels": ["(GMT+01:00) Brussels", "Brussels"],
  "Europe/Budapest": ["(GMT+01:00) Budapest", "Budapest"],
  "Europe/Copenhagen": ["(GMT+01:00) Copenhagen", "Copenhagen"],
  "Europe/Ljubljana": ["(GMT+01:00) Ljubljana", "Ljubljana"],
  "Europe/Madrid": ["(GMT+01:00) Madrid", "Madrid"],
  "Europe/Paris": ["(GMT+01:00) Paris", "Paris"],
  "Europe/Prague": ["(GMT+01:00) Prague", "Prague"],
  "Europe/Rome": ["(GMT+01:00) Rome", "Rome"],
  "Europe/Sarajevo": ["(GMT+01:00) Sarajevo", "Sarajevo"],
  "Europe/Skopje": ["(GMT+01:00) Skopje", "Skopje"],
  "Europe/Stockholm": ["(GMT+01:00) Stockholm", "Stockholm"],
  "Europe/Vienna": ["(GMT+01:00) Vienna", "Vienna"],
  "Europe/Warsaw": ["(GMT+01:00) Warsaw", "Warsaw"],
  "Africa/Algiers": ["(GMT+01:00) West Central Africa", "West Central Africa"],
  "Europe/Zagreb": ["(GMT+01:00) Zagreb", "Zagreb"],
  "Europe/Athens": ["(GMT+02:00) Athens", "Athens"],
  "Europe/Bucharest": ["(GMT+02:00) Bucharest", "Bucharest"],
  "Africa/Cairo": ["(GMT+02:00) Cairo", "Cairo"],
  "Africa/Harare": ["(GMT+02:00) Harare", "Harare"],
  "Europe/Helsinki": ["(GMT+02:00) Helsinki", "Helsinki"],
  "Europe/Istanbul": ["(GMT+02:00) Istanbul", "Istanbul"],
  "Asia/Jerusalem": ["(GMT+02:00) Jerusalem", "Jerusalem"],
  "Europe/Kiev": ["(GMT+02:00) Kyiv", "Kyiv"],
  "Africa/Johannesburg": ["(GMT+02:00) Pretoria", "Pretoria"],
  "Europe/Riga": ["(GMT+02:00) Riga", "Riga"],
  "Europe/Sofia": ["(GMT+02:00) Sofia", "Sofia"],
  "Europe/Tallinn": ["(GMT+02:00) Tallinn", "Tallinn"],
  "Europe/Vilnius": ["(GMT+02:00) Vilnius", "Vilnius"],
  "Asia/Baghdad": ["(GMT+03:00) Baghdad", "Baghdad"],
  "Asia/Kuwait": ["(GMT+03:00) Kuwait", "Kuwait"],
  "Europe/Minsk": ["(GMT+03:00) Minsk", "Minsk"],
  "Europe/Moscow": ["(GMT+03:00) Moscow", "Moscow"],
  "Africa/Nairobi": ["(GMT+03:00) Nairobi", "Nairobi"],
  "Asia/Riyadh": ["(GMT+03:00) Riyadh", "Riyadh"],
  "Europe/Moscow": ["(GMT+03:00) St. Petersburg", "St. Petersburg"],
  "Europe/Moscow": ["(GMT+03:00) Volgograd", "Volgograd"],
  "Asia/Tehran": ["(GMT+03:30) Tehran", "Tehran"],
  "Asia/Muscat": ["(GMT+04:00) Abu Dhabi", "Abu Dhabi"],
  "Asia/Baku": ["(GMT+04:00) Baku", "Baku"],
  "Asia/Muscat": ["(GMT+04:00) Muscat", "Muscat"],
  "Asia/Tbilisi": ["(GMT+04:00) Tbilisi", "Tbilisi"],
  "Asia/Yerevan": ["(GMT+04:00) Yerevan", "Yerevan"],
  "Asia/Kabul": ["(GMT+04:30) Kabul", "Kabul"],
  "Asia/Yekaterinburg": ["(GMT+05:00) Ekaterinburg", "Ekaterinburg"],
  "Asia/Karachi": ["(GMT+05:00) Islamabad", "Islamabad"],
  "Asia/Karachi": ["(GMT+05:00) Karachi", "Karachi"],
  "Asia/Tashkent": ["(GMT+05:00) Tashkent", "Tashkent"],
  "Asia/Kolkata": ["(GMT+05:30) Chennai", "Chennai"],
  "Asia/Kolkata": ["(GMT+05:30) Kolkata", "Kolkata"],
  "Asia/Kolkata": ["(GMT+05:30) Mumbai", "Mumbai"],
  "Asia/Kolkata": ["(GMT+05:30) New Delhi", "New Delhi"],
  "Asia/Colombo": ["(GMT+05:30) Sri Jayawardenepura", "Sri Jayawardenepura"],
  "Asia/Kathmandu": ["(GMT+05:45) Kathmandu", "Kathmandu"],
  "Asia/Almaty": ["(GMT+06:00) Almaty", "Almaty"],
  "Asia/Dhaka": ["(GMT+06:00) Astana", "Astana"],
  "Asia/Dhaka": ["(GMT+06:00) Dhaka", "Dhaka"],
  "Asia/Novosibirsk": ["(GMT+06:00) Novosibirsk", "Novosibirsk"],
  "Asia/Urumqi": ["(GMT+06:00) Urumqi", "Urumqi"],
  "Asia/Rangoon": ["(GMT+06:30) Rangoon", "Rangoon"],
  "Asia/Bangkok": ["(GMT+07:00) Bangkok", "Bangkok"],
  "Asia/Bangkok": ["(GMT+07:00) Hanoi", "Hanoi"],
  "Asia/Jakarta": ["(GMT+07:00) Jakarta", "Jakarta"],
  "Asia/Krasnoyarsk": ["(GMT+07:00) Krasnoyarsk", "Krasnoyarsk"],
  "Asia/Shanghai": ["(GMT+08:00) Beijing", "Beijing"],
  "Asia/Chongqing": ["(GMT+08:00) Chongqing", "Chongqing"],
  "Asia/Hong_Kong": ["(GMT+08:00) Hong Kong", "Hong Kong"],
  "Asia/Irkutsk": ["(GMT+08:00) Irkutsk", "Irkutsk"],
  "Asia/Kuala_Lumpur": ["(GMT+08:00) Kuala Lumpur", "Kuala Lumpur"],
  "Australia/Perth": ["(GMT+08:00) Perth", "Perth"],
  "Asia/Singapore": ["(GMT+08:00) Singapore", "Singapore"],
  "Asia/Taipei": ["(GMT+08:00) Taipei", "Taipei"],
  "Asia/Ulaanbaatar": ["(GMT+08:00) Ulaan Bataar", "Ulaan Bataar"],
  "Asia/Tokyo": ["(GMT+09:00) Osaka", "Osaka"],
  "Asia/Tokyo": ["(GMT+09:00) Sapporo", "Sapporo"],
  "Asia/Seoul": ["(GMT+09:00) Seoul", "Seoul"],
  "Asia/Tokyo": ["(GMT+09:00) Tokyo", "Tokyo"],
  "Asia/Yakutsk": ["(GMT+09:00) Yakutsk", "Yakutsk"],
  "Australia/Adelaide": ["(GMT+09:30) Adelaide", "Adelaide"],
  "Australia/Darwin": ["(GMT+09:30) Darwin", "Darwin"],
  "Australia/Brisbane": ["(GMT+10:00) Brisbane", "Brisbane"],
  "Australia/Sydney": ["(GMT+10:00) Sydney", "Sydney"],
  "Australia/Melbourne": ["(GMT+10:00) Canberra", "Canberra"],
  "Pacific/Guam": ["(GMT+10:00) Guam", "Guam"],
  "Australia/Hobart": ["(GMT+10:00) Hobart", "Hobart"],
  "Asia/Magadan": ["(GMT+10:00) Magadan", "Magadan"],
  "Australia/Melbourne": ["(GMT+10:00) Melbourne", "Melbourne"],
  "Pacific/Port_Moresby": ["(GMT+10:00) Port Moresby", "Port Moresby"],
  "Asia/Magadan": ["(GMT+10:00) Solomon Is.", "Solomon Is."],
  "Asia/Vladivostok": ["(GMT+10:00) Vladivostok", "Vladivostok"],
  "Pacific/Noumea": ["(GMT+11:00) New Caledonia", "New Caledonia"],
  "Pacific/Auckland": ["(GMT+12:00) Auckland", "Auckland"],
  "Pacific/Fiji": ["(GMT+12:00) Fiji", "Fiji"],
  "Asia/Kamchatka": ["(GMT+12:00) Kamchatka", "Kamchatka"],
  "Pacific/Majuro": ["(GMT+12:00) Marshall Is.", "Marshall Is."],
  "Pacific/Auckland": ["(GMT+12:00) Wellington", "Wellington"],
  "Pacific/Tongatapu": ["(GMT+13:00) Nuku'alofa", "Nuku'alofa"],
  "Pacific/Apia": ["(GMT+13:00) Samoa", "Samoa"],
  "Pacific/Fakaofo": ["(GMT+13:00) Tokelau Is.", "Tokelau Is."]
};

export function getTimezones() {
  return _.map(timezoneMap, (timezone) => { return {label: timezone[0], value: timezone[1]} });
  return _.values(timezoneMap);
}

export function determineTimezone() {
  // Override window.Intl as per jstz documentation for mapping with Rails timezones
  const oldIntl = window.Intl;
  try {
    window.Intl = undefined;
    const tz = jstz.determine().name();
    window.Intl = oldIntl;
    const timezone = timezoneMap[tz];
    if (timezone) {
      return timezone[1];
    } else {
      return '';
    }
  } catch (e) {
    return ''; // Fallback
  }
}
