import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button, InputAdornment, Table, TableHead, TableBody,
         TableFooter, TableRow, TableCell, TablePagination } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DateInput from 'components/inputs/DateInput';
import SelectInput from 'components/inputs/SelectInput';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatCurrency } from 'utils/currency';
import ReportMenu from 'components/menus/ReportMenu';
import { hasPermission } from 'utils/auth';
import { fetchProductReportData, downloadProductReportData } from 'actions/ReportActions';

export class ProductReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0
    };

    this.formRef = React.createRef();
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangePage(_event, page) {
    this.setState({page}, this.fetchData);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchProductReportData(this.formRef.current.getModel(), this.state.page + 1);
  }

  render() {
    const { webposProfile, permissions, isFetching, productData, downloadProductReportData } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ReportMenu />
        </Grid>
        {/* Set min-height so page is always big enough should the date dropdown be shown */}
        <Grid item md={8} className="full-width" style={{minHeight: '500px'}}>
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Product Report</Typography>
            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_from" label="Date From" disableFuture required
                    value={moment().startOf('month').format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_to" label="Date To" disableFuture required
                    value={moment().format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validations="dateSameOrAfter:date_from"
                    validationErrors={{
                      dateSameOrAfter: 'must be after from date',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />
                </Grid>
              </Grid>
              {hasPermission(permissions, 'read', 'Organisation') &&
                <Grid container spacing={16} justify="center">
                  <Grid item sm={10} md={6} className="full-width print-full-width" align="left">
                    <SelectInput name="group_id" label="Site"
                      options={[{label: 'All', value: 'all'}].concat(webposProfile.groups)}
                      value="all"
                    />
                  </Grid>
                </Grid>
              }
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Search</Button>
                </Grid>
              </Grid>
            </Formsy>

            {productData &&
              <React.Fragment>
                <Grid container spacing={16} className="top-space" justify="center">
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Quantity</Typography>
                    <Typography variant="h5" color="primary">{productData.total_quantity}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Value</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(productData.total_value, webposProfile.currency_code)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Cost*</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(productData.total_cost, webposProfile.currency_code)}</Typography>
                  </Grid>
                </Grid>

                <div className="top-space">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      title: {text: 'Product Sales'},
                      chart: {type: 'pie', plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
                      plotOptions: {
                        pie: {
                          allowPointSelect: true,
                          cursor: 'pointer',
                          showInLegend: true
                        }
                      },
                      series: [{
                        name: 'Sales',
                        colorByPoint: true,
                        data: productData.products.map((product) => {return {name: product.name, y: parseInt(product.quantity)}})
                      }],
                      credits: {enabled: false}
                    }}
                  />
                </div>

                <div className="top-space scroll-container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="dense">Product Name</TableCell>
                        <TableCell padding="dense">Quantity</TableCell>
                        <TableCell padding="dense">Value</TableCell>
                        <TableCell padding="dense">Cost*</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productData.products.map((product, index) =>
                        <TableRow key={index}>
                          <TableCell padding="dense">{product.name}</TableCell>
                          <TableCell padding="dense">{product.quantity}</TableCell>
                          <TableCell padding="dense">{formatCurrency(product.value, webposProfile.currency_code)}</TableCell>
                          <TableCell padding="dense">{formatCurrency(product.cost, webposProfile.currency_code)}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={4}
                          rowsPerPageOptions={[]}
                          count={productData.products_count}
                          rowsPerPage={20}
                          page={this.state.page}
                          onChangePage={this.handleChangePage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>

                  <Typography variant="caption" className="top-space">*Cost does not include credit card surcharge fees</Typography>
                </div>

                <Typography variant="body1" className="top-space">
                  Download results in <button onClick={() => downloadProductReportData(this.formRef.current.getModel())} className="raw-button-link">Excel</button>
                </Typography>
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    permissions: state.session.permissions,
    isFetching: state.report.isFetching,
    productData: state.report.productData
  }
}

const mapDispatchToProps = {
  fetchProductReportData: (data, page) => fetchProductReportData(data, page),
  downloadProductReportData: (data) => downloadProductReportData(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductReportPage)
