import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  paper: {
    paddingBottom: theme.spacing.unit * 2
  }
});

class LoadingComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={16} justify="center">
          <Grid item xs={2}>
            <Paper elevation={1} className={classes.paper}>
              <Grid container justify="center">
                <Typography variant="h5" gutterBottom>
                  Loading
                </Typography>
              </Grid>
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

LoadingComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingComponent)
