import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import RadioInput from 'components/inputs/RadioInput';
import TextInput from 'components/inputs/TextInput';
import { processEftposType } from 'actions/LoadActions';

export class EftposPaymentMethodFieldset extends React.Component {
  render() {
    const { webposProfile, processEftposType } = this.props;

    return (
      <React.Fragment>
        {webposProfile.include_eftpos_surcharges &&
          <React.Fragment>
            <Typography variant="h5" align="center">
              Select the card type to be used before taking payment so correct surcharges are applied.
            </Typography>
            <RadioInput name="eftpos_card_type" label="Card Type" required
              onChange={(e) => processEftposType(e.target.value)}
              options={webposProfile.eftpos_card_types}
              validationErrors={{
                isDefaultRequiredValue: "can't be blank"
              }}
            />
          </React.Fragment>
        }
        <TextInput name="name" label="Receipt Reference Number" required
          validationErrors={{
            isDefaultRequiredValue: "can't be blank"
          }}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  processEftposType: (type) => processEftposType(type)
};

export default connect(null, mapDispatchToProps)(EftposPaymentMethodFieldset)
