import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Formsy from 'formsy-react';
import { Grid, InputAdornment, Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatCurrency, currencySymbol } from 'utils/currency';
import SelectInput from 'components/inputs/SelectInput';
import TextInput from 'components/inputs/TextInput';
import { invoiceAddCard, invoiceUpdateCard, invoiceRemoveCard } from 'actions/InvoiceActions';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export class DetailsStep extends React.Component {
  getProducts() {
    const { webposProfile } = this.props;

    return webposProfile.digital_purchases_enabled ?
            webposProfile.scannable_products.concat(webposProfile.digital_products)
            : webposProfile.scannable_products;
  }

  handleSelectProduct(id, {target}) {
    const { invoiceUpdateCard } = this.props;
    const products = this.getProducts();
    const product = _.find(products, ['id', target.value]);
    invoiceUpdateCard(id, {product_id: target.value, card_fees: product.webpos_fees})
  }

  renderCards() {
    const { cards, minCardValue, invoiceRemoveCard, invoiceUpdateCard, webposProfile } = this.props;
    const maxCardValue = webposProfile.max_card_value;
    const products = this.getProducts();

    return (
      cards.map((card, index) =>
        <React.Fragment key={index}>
          <Grid container spacing={8}>
            <Grid item xs={11}>
              <SelectInput name={'product_id_' + card.id} label='Product' required
                           value={card.product_id}
                           onChange={(e) => this.handleSelectProduct(card.id, e)}
                           options={products.map( function(product){ return {value: product.id, label: product.name}})}
                           validationErrors={requiredValidation}
              />
              <TextInput type='number' name={'value_per_card_' + card.id} label='Value Per Card' required
                value={card.value_per_card}
                onChange={(e) => invoiceUpdateCard(card.id, {value_per_card: e.target.value})}
                inputProps={{
                  startAdornment: <InputAdornment position="start">{currencySymbol(webposProfile.currency_code)}</InputAdornment>
                }}
                validations={{
                  isGreaterEqualTo: minCardValue,
                  isLesserEqualTo: maxCardValue
                }}
                validationErrors={{
                  isGreaterEqualTo: 'must be ' + formatCurrency(minCardValue, webposProfile.currency_code) + ' or greater',
                  isLesserEqualTo: 'must be ' + formatCurrency(maxCardValue, webposProfile.currency_code) + ' or less',
                  isDefaultRequiredValue: "can't be blank"
                }}
              />
              <TextInput type='number' name={'number_of_cards_' + card.id} label='Quantity' required
                value={card.number_of_cards}
                onChange={(e) => invoiceUpdateCard(card.id, {number_of_cards: e.target.value})}
                validations={{
                  isGreaterThan: 0
                }}
                validationErrors={{
                  isGreaterThan: 'must be greater than 0',
                  isDefaultRequiredValue: "can't be blank"
                }}
              />
            </Grid>
            {index !== 0 &&
              <Grid item xs={1}>
                <IconButton aria-label='Delete' onClick={() => invoiceRemoveCard(card.id)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            }
          </Grid>
        </React.Fragment>
      )
    )
  }

  render() {
    const { webposProfile, handleNext, invoiceAddCard, updateInvoice, invoiceType, initialBalance } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleNext} className='full-width'>
          <Grid container spacing={8}>
            <Grid item xs={11} lg={9}>
              <SelectInput name='invoice_type' label='Invoice Type' required
                           value={invoiceType}
                           onChange={(e) => updateInvoice({invoice_type: e.target.value})}
                           options={webposProfile.invoice_types.map( function(invoice_type){ return {value: invoice_type[1], label: invoice_type[0]}})}
                           validationErrors={requiredValidation}
              />
            </Grid>

            <Grid item xs={11}>
              { invoiceType === 'float' &&
                <TextInput type='number' name='initial_balance' label='Requested Amount' required
                  value={initialBalance}
                  onChange={(e) => updateInvoice({initial_balance: e.target.value})}
                  inputProps={{
                    startAdornment: <InputAdornment position="start">{currencySymbol(webposProfile.currency_code)}</InputAdornment>
                  }}
                  validations={{
                    isGreaterThan: 0
                  }}
                  validationErrors={{
                    isGreaterThan: 'must be greater than 0',
                    isDefaultRequiredValue: "can't be blank"
                  }}
                />
              }
            </Grid>
          </Grid>
          { invoiceType !== 'float' &&
            <React.Fragment>
              {this.renderCards()}
              <Button variant="contained" color="secondary" size="small" onClick={() => invoiceAddCard()}>Add another card</Button>
            </React.Fragment>
          }
          <div className='button-container'>
            <Button type='submit' variant='contained' color='secondary'>Next</Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
};

DetailsStep.defaultProps = {
  minCardValue: 5
};

function mapStateToProps(state) {
  return {
    cards: state.invoice.cards,
  }
}

const mapDispatchToProps = {
  invoiceAddCard,
  invoiceUpdateCard: (id, attrs) => invoiceUpdateCard(id, attrs),
  invoiceRemoveCard: (id) => invoiceRemoveCard(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsStep)
