import _ from 'lodash';

export const hasPermission = (permissions, action, model) => {
  if (action && model) {
    const actionModels = permissions && permissions.can[action];
    if (actionModels) {
      return _.has(actionModels, model);
    }
    return false;
  }
  return false;
};
