import React from 'react';
import { connect } from 'react-redux';
import { Paper, MenuList, MenuItem, Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { hasPermission } from 'utils/auth';

export class InvoiceMenu extends React.Component {
  render() {
    return (
      <Paper>
        <MenuList className="menu" disablePadding>
          <NavLink to='/invoices' exact activeClassName='active'>
            <MenuItem>Invoices</MenuItem>
          </NavLink>
          {hasPermission(this.props.permissions, 'create', 'InvoiceRequest') &&
            <div>
              <Divider />
              <NavLink to='/invoices/new' exact activeClassName='active'>
                <MenuItem>New Invoice</MenuItem>
              </NavLink>
            </div>
          }
        </MenuList>
      </Paper>
    )
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.session.permissions
  }
}

export default connect(mapStateToProps)(InvoiceMenu)
