import React from 'react';
import { Paper, MenuList, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export default class BalanceMenu extends React.Component {
  render() {
    return (
      <Paper>
        <MenuList className="menu" disablePadding>
          <NavLink to="/balance" exact activeClassName="active">
            <MenuItem>Balance Check</MenuItem>
          </NavLink>
          <NavLink to="/transaction_search" exact activeClassName="active">
            <MenuItem>Transaction Search</MenuItem>
          </NavLink>
        </MenuList>
      </Paper>
    )
  }
}
