import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';
import ProfileMenu from 'components/menus/ProfileMenu';
import TextInput from 'components/inputs/TextInput';
import ProfileForm from 'components/forms/ProfileForm';
import { updateProfile } from 'actions/SessionActions';

export class EditProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.updateProfile(data);
  }

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <ProfileMenu />
        </Grid>
        <Grid item md={8} className='full-width'>
          <Paper className='form-container' elevation={1}>
            <Typography variant='h4' align='center' gutterBottom>Edit Profile</Typography>
            <ProfileForm handleSubmit={this.handleSubmit} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  updateProfile: (data) => updateProfile(data)
};

export default connect(null, mapDispatchToProps)(EditProfilePage)
