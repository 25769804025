import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Card, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import LoadMenu from 'components/menus/LoadMenu';
import PhoneIcon from '@material-ui/icons/Smartphone';
import CardIcon from '@material-ui/icons/CreditCard';

export class BulkPurchaseSelectionPage extends React.Component {
  render() {
    const { webposProfile } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <LoadMenu />
        </Grid>
        <Grid item md={8} className="full-width" align="center">
          <Paper className="form-container" elevation={1}>
            <NavLink to="/bulk_load/physical">
              <Card className="home-card">
                <CardIcon color="primary" className="icon" />
                <Typography variant="h5">Physical</Typography>
              </Card>
            </NavLink>
            {webposProfile.digital_purchases_enabled && webposProfile.digital_products.length > 0 &&
              <NavLink to="/bulk_load/digital">
                <Card className="home-card">
                  <PhoneIcon color="primary" className="icon" />
                  <Typography variant="h5">Digital</Typography>
                </Card>
              </NavLink>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile
  }
}

export default connect(mapStateToProps)(BulkPurchaseSelectionPage);
