import React from 'react';
import Formsy from 'formsy-react';
import { Button } from '@material-ui/core';
import SelectInput from 'components/inputs/SelectInput';
import TextInput from 'components/inputs/TextInput';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export default class ReferenceStep extends React.Component {
  render() {
    const { user, webposProfile, handleNext, handleBack, isProcessing, referenceNumber,
            internalReference, paymentType, requestedEmails, formRef } = this.props;

    const paymentTypeOptions = webposProfile.invoice_payment_methods.map(
      function(payment_method){ return {value: payment_method[1], label: payment_method[0]}}
    );

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleNext} className='full-width' ref={formRef}>
          <TextInput name='reference_number' label='Purchase Order or Reference Number' required
            value={referenceNumber}
            validationErrors={requiredValidation}
          />
          <TextInput name='internal_reference' label='Internal Reference' required
            value={internalReference}
            validationErrors={requiredValidation}
          />
          <SelectInput name='payment_type' label='Payment Type' required
                       value={paymentTypeOptions.length === 1 ? paymentTypeOptions[0].value : paymentType}
                       options={paymentTypeOptions}
                       validationErrors={requiredValidation}
          />
          <TextInput name='requested_emails' label='Your Email' required
            value={requestedEmails || user.email}
            hint="Comma-separated list of email addresses for the invoice to be sent to"
            validations='isCommaSeparatedEmails'
            validationErrors={{
              isCommaSeparatedEmails: 'must be a comma-separated list of valid email addresses',
              isDefaultRequiredValue: "can't be blank"
            }}
          />

          <div className='button-container'>
            <Button variant='contained' onClick={handleBack}>Back</Button>
            <Button type='submit' variant='contained' color='primary' disabled={isProcessing}>Submit</Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
};
