import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CREATE_UPDATE_USER_REQUEST,
  CREATE_UPDATE_USER_SUCCESS,
  CREATE_UPDATE_USER_FAILURE
} from '../actions/UserActions'

const initialState = {
  isFetching: false,
  isProcessing: false,
  userErrors: null,
  users: []
};

export default function user(state = initialState, action) {
  switch(action.type) {
    case FETCH_USERS_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_USERS_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        users: action.users
      });
    }
    case FETCH_USERS_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case CREATE_UPDATE_USER_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true,
        userErrors: null
      });
    }
    case CREATE_UPDATE_USER_SUCCESS : {
      return Object.assign({}, state, {
        isProcessing: false,
        userErrors: null
      });
    }
    case CREATE_UPDATE_USER_FAILURE : {
      return Object.assign({}, state, {
        isProcessing: false,
        userErrors: action.errors
      });
    }
    default:
      return state;
  }
}
