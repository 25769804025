import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_COMPLETE,
  FETCH_ORGANISATION_DATA_SUCCESS,
  FETCH_ORGANISATION_GROUP_DATA_SUCCESS,
  FETCH_PURCHASES_DATA_SUCCESS,
  FETCH_ALL_PURCHASES_DATA_SUCCESS,
  FETCH_CUSTOMER_REDEMPTION_DATA_SUCCESS,
  FETCH_UNUSED_FUNDS_DATA_SUCCESS,
  FETCH_REDEMPTION_DATA_SUCCESS,
  FETCH_PRODUCT_DATA_SUCCESS,
  FETCH_INVOICE_DATA_SUCCESS,
  FETCH_ACTIVE_INVOICE_DATA_SUCCESS,
  FETCH_TIP_DATA_SUCCESS
} from '../actions/ReportActions'

const initialState = {
  isFetching: false,
  isDownloading: false,
  organisationData: null,
  organisationGroupData: null,
  purchasesData: null,
  allPurchasesData: null,
  customerRedemptionData: null,
  unusedFundsData: null,
  redemptionData: null,
  productData: null,
  invoiceData: null,
  activeInvoiceData: null,
  tipData: null
};

export default function report(state = initialState, action) {
  switch(action.type) {
    case FETCH_REPORT_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_REPORT_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case FETCH_REPORT_FAILURE : {
      return initialState;
    }
    case DOWNLOAD_REPORT_REQUEST : {
      return Object.assign({}, state, {
        isDownloading: true
      });
    }
    case DOWNLOAD_REPORT_COMPLETE : {
      return Object.assign({}, state, {
        isDownloading: false
      });
    }
    case FETCH_ORGANISATION_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        organisationData: action.data,
        organisationGroupData: null
      });
    }
    case FETCH_ORGANISATION_GROUP_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        organisationGroupData: action.data
      });
    }
    case FETCH_PURCHASES_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        purchasesData: action.data
      });
    }
    case FETCH_ALL_PURCHASES_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        allPurchasesData: action.data
      });
    }
    case FETCH_CUSTOMER_REDEMPTION_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        customerRedemptionData: action.data
      });
    }
    case FETCH_UNUSED_FUNDS_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        unusedFundsData: action.data
      });
    }
    case FETCH_REDEMPTION_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        redemptionData: action.data
      });
    }
    case FETCH_PRODUCT_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        productData: action.data
      });
    }
    case FETCH_INVOICE_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        invoiceData: action.data
      });
    }
    case FETCH_ACTIVE_INVOICE_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        activeInvoiceData: action.data
      });
    }
    case FETCH_TIP_DATA_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        tipData: action.data
      });
    }
    default:
      return state;
  }
}
