import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button, InputAdornment, AppBar, Tabs, Tab, CircularProgress } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DateInput from 'components/inputs/DateInput';
import SelectInput from 'components/inputs/SelectInput';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatCurrency } from 'utils/currency';
import ReportMenu from 'components/menus/ReportMenu';
import { hasPermission } from 'utils/auth';
import { fetchCustomerRedemptionReportData, downloadCustomerRedemptionReportData } from 'actions/ReportActions';

const MAX_ROWS_EXPORT = 1000;

export class CustomerRedemptionReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0
    };

    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.fetchCustomerRedemptionReportData(this.formRef.current.getModel());
  }

  handleTabChange(_event, value) {
    this.setState({tab: value});
  }

  renderDownloadLink(count) {
    const { isDownloading, downloadCustomerRedemptionReportData } = this.props;

    if (isDownloading) return null;

    const viaEmail = count > MAX_ROWS_EXPORT;

    return (
      <React.Fragment>
        {viaEmail ? 'Send raw data export via ' : 'Download raw data as '}
        <button onClick={() => downloadCustomerRedemptionReportData(this.formRef.current.getModel(), viaEmail)} className="raw-button-link">{viaEmail ? 'Email' : 'Excel'}</button>
      </React.Fragment>
    );
  }

  render() {
    const { webposProfile, permissions, isFetching, isDownloading, redemptionData } = this.props;
    const { tab } = this.state;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ReportMenu />
        </Grid>
        {/* Set min-height so page is always big enough should the date dropdown be shown */}
        <Grid item md={8} className="full-width" style={{minHeight: '500px'}}>
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Redemption Report</Typography>
            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_from" label="Date From" disableFuture required
                    value={moment().startOf('month').format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_to" label="Date To" disableFuture required
                    value={moment().format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validations="dateSameOrAfter:date_from"
                    validationErrors={{
                      dateSameOrAfter: 'must be after from date',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />
                </Grid>
              </Grid>
              {hasPermission(permissions, 'read', 'Organisation') &&
                <Grid container spacing={16} justify="center">
                  <Grid item sm={10} md={6} className="full-width print-full-width" align="left">
                    <SelectInput name="group_id" label="Site"
                      options={[{label: 'All', value: 'all'}].concat(webposProfile.groups)}
                      value="all"
                    />
                  </Grid>
                </Grid>
              }
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Search</Button>
                </Grid>
              </Grid>
            </Formsy>

            {redemptionData &&
              <React.Fragment>
                <Grid container spacing={16} className="top-space" justify="center">
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Redemptions</Typography>
                    <Typography variant="h5" color="primary">{redemptionData.total_redemptions}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Total Redemption Value</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(redemptionData.total_value, webposProfile.currency_code)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} align="center">
                    <Typography variant="subtitle1" className="capitalize">Average Redemption Amount</Typography>
                    <Typography variant="h5" color="primary">{formatCurrency(redemptionData.average_redemption, webposProfile.currency_code)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} align="center">
                    <Typography variant="subtitle1">Average Redemptions Per Card</Typography>
                    <Typography variant="h5" color="primary">{parseFloat(redemptionData.redemptions_per_card).toFixed(2)}</Typography>
                  </Grid>
                </Grid>

                <div className="top-space">
                  <AppBar position='static'>
                    <Tabs variant="fullWidth" value={tab} onChange={this.handleTabChange}>
                      <Tab label='Categories' />
                      <Tab label='Merchants' />
                      <Tab label='Locations' />
                    </Tabs>
                  </AppBar>
                </div>
                <div className="full-width" style={{padding: '1em'}}>
                  {tab == 0 &&
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        title: {text: 'Most Redeemed Categories'},
                        chart: {type: 'bar'},
                        xAxis: {categories: redemptionData.category_counts.map((c) => {return c.name}), crosshair: true},
                        yAxis: {min: 0, allowDecimals: false, title: {text: 'Redemptions'}},
                        series: [{name: 'Redemptions', data: redemptionData.category_counts.map((c) => {return parseInt(c.count)})}],
                        legend: {enabled: false},
                        credits: {enabled: false}
                      }}
                    />
                  }
                  {tab == 1 &&
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        title: {text: 'Most Popular Merchants'},
                        chart: {type: 'bar'},
                        xAxis: {categories: redemptionData.merchant_counts.map((c) => {return c.name}), crosshair: true},
                        yAxis: {min: 0, allowDecimals: false, title: {text: 'Redemptions'}},
                        series: [{name: 'Redemptions', data: redemptionData.merchant_counts.map((c) => {return parseInt(c.count)})}],
                        legend: {enabled: false},
                        credits: {enabled: false}
                      }}
                    />
                  }
                  {tab == 2 &&
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        title: {text: 'Redemption Locations'},
                        chart: {type: 'pie', plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false},
                        plotOptions: {
                          pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            showInLegend: true
                          }
                        },
                        series: [{
                          name: 'Redemptions',
                          colorByPoint: true,
                          data: redemptionData.city_counts
                        }],
                        credits: {enabled: false}
                      }}
                    />
                  }
                </div>

                {hasPermission(permissions, 'export', 'TransactionRecord') &&
                  <React.Fragment>
                    {isDownloading && <CircularProgress size={20} />}
                    <Typography variant="body1" className="top-space">
                      {this.renderDownloadLink(redemptionData.total_redemptions)}
                    </Typography>
                  </React.Fragment>
                }
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    permissions: state.session.permissions,
    isFetching: state.report.isFetching,
    isDownloading: state.report.isDownloading,
    redemptionData: state.report.customerRedemptionData
  }
}

const mapDispatchToProps = {
  fetchCustomerRedemptionReportData: (data) => fetchCustomerRedemptionReportData(data),
  downloadCustomerRedemptionReportData: (data, viaEmail) => downloadCustomerRedemptionReportData(data, viaEmail)
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRedemptionReportPage)
