import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { history } from 'utils/store';
import { flashMessage } from 'redux-flash';
import { signInUser } from 'actions/SessionActions';
import { Typography, Button } from '@material-ui/core';
import queryString from 'query-string';
import Formsy from 'formsy-react';
import TextInput from 'components/inputs/TextInput';
import LoginMenu from 'components/menus/LoginMenu';

export class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { currentUser, navigateTo, location, flashMessage } = this.props;

    if (currentUser) {
      navigateTo('/');
    }

    if (location && queryString.parse(location.search).expired) {
      flashMessage('Your session has expired', {props: {type: 'info'}});
      history.replace('/login');
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.signInUser(data.username, data.password);
  }

  render() {
    const { isProcessing, currentUser, webposProfile, permissions } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>Log In</Typography>

        <Formsy onValidSubmit={this.handleSubmit}>
          <TextInput name="username" label="Username" compact required
            validationErrors={{
              isDefaultRequiredValue: "can't be blank"
            }}
          />

          <TextInput type="password" name="password" label="Password" compact required
            validationErrors={{
              isDefaultRequiredValue: "can't be blank"
            }}
            inputFieldProps={{autoComplete: 'off'}}
          />

          <div className="login-button-container">
            <Button type="submit" disabled={isProcessing} variant="contained" color="primary">Log In</Button>
            <LoginMenu />
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isProcessing: state.session.isProcessing,
    currentUser: state.session.currentUser,
    webposProfile: state.session.webposProfile,
    permissions: state.session.permissions
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path),
  signInUser: (username, password) => signInUser(username, password),
  flashMessage: (message, opts) => flashMessage(message, opts)
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
