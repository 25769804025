export function parseCardID(value) {
  let cardId = null;

  // EML & Linx
  if (value.length > 9 && /%.*=\?.*\?$/.test(value)) {
    cardId = value.substr(value.indexOf('%') + 1, 9);
  }
  // Encrypted Linx card readers
  else if (/%.*=\?.*\?(.*\|){11}\d{4}$/.test(value)) {
    cardId = value.substr(value.indexOf('%') + 1, 9);
  }
  // Nucleus
  else if (value.length > 13 && /%\d{11}\?.*\?$/.test(value)) {
    cardId = value.substr(value.indexOf('%') + 1, 11);
  }
  // Indue
  else if (value.length > 19 && /%.*\?;.*=*\?$/.test(value)) {
    const track2 = value.substr(value.indexOf(';') + 1);
    cardId = track2.substr(0, track2.indexOf('='));
  }

  return cardId;
}

export function parseCardNumber(value) {
  if (value.length > 16 && /%.*=\?.*\?$/.test(value)) {
    return value.substr(value.indexOf(';') + 1, 16);
  // Encrypted Linx card readers
  } else if (/%.*=\?.*\?(.*\|){11}\d{4}$/.test(value)) {
    return value.substr(value.indexOf(';') + 1, 16);
  } else {
    return null;
  }
}

export function parseCreditCardSwipe(value) {
  const indexOfTrack2 = value.indexOf(';') + 1;
  const indexOfExpiry = value.indexOf('=', indexOfTrack2) + 1;
  const indexOfName = value.indexOf('^') + 1;
  const indexOfEndOfName = value.indexOf('^', indexOfName);
  const indexOfFirstName = value.indexOf('/', indexOfName) + 1;
  const indexOfTitle = value.indexOf('.', indexOfName) + 1;

  const number = value.substr(indexOfTrack2, indexOfExpiry - indexOfTrack2 - 1);
  const expiryYear = 2000 + parseInt(value.substr(indexOfExpiry, 2));
  const expiryMonth = value.substr(indexOfExpiry + 2, 2);
  let name;

  if (indexOfFirstName <= 0) {
    name = value.substr(indexOfName, indexOfEndOfName - indexOfName).trim();
  }
  else if (indexOfTitle <= 0) {
    name = value.substr(indexOfFirstName, indexOfEndOfName - indexOfFirstName).trim() + ' ' +
      value.substr(indexOfName, indexOfFirstName - indexOfName - 1).trim();
  }
  else {
    name = (value.substr(indexOfTitle, indexOfEndOfName - indexOfTitle).trim() + ' ' +
      value.substr(indexOfFirstName, indexOfTitle - indexOfFirstName - 1).trim() + ' ' +
      value.substr(indexOfName, indexOfFirstName - indexOfName - 1).trim()).trim();
  }

  return { number, name, expiryMonth, expiryYear };
}

function parseBarcodeForCode(scanContents, code) {
  var codeIndex = scanContents.indexOf(code);
  if (codeIndex == -1) return '';
  var index = codeIndex + code.length;
  var end = scanContents.indexOf('\n', index);
  return scanContents.substring(index, end);
}
