export function customUpdateInputsWithError(current, errorMessages) {
  // If error is for input not currently present, remove error from errorMessages
  for (let errorMessageKey of Object.keys(errorMessages)) {
    if (!current.inputs.some((input) => input.props.name === errorMessageKey)) {
      delete errorMessages[errorMessageKey]
    }
  }

  current.updateInputsWithError(errorMessages);
}
