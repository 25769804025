import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, Button, InputAdornment } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DateInput from 'components/inputs/DateInput';
import SelectInput from 'components/inputs/SelectInput';
import moment from 'moment';
import ReportMenu from 'components/menus/ReportMenu';
import { requestWebstoresReport } from 'actions/ReportActions';

export class WebstoresReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.requestWebstoresReport(this.formRef.current.getModel());
  }

  render() {
    const { isFetching, webposProfile } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <ReportMenu />
        </Grid>
        {/* Set min-height so page is always big enough should the date dropdown be shown */}
        <Grid item md={8} className="full-width" style={{minHeight: '500px'}}>
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Webstores Report</Typography>
            <Typography variant="body1" align="center" gutterBottom>
              You will be sent an email with the webstores order history in the specified time period
            </Typography>
            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_from" label="Date From" disableFuture required
                    value={moment().startOf('month').format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
                <Grid item sm={10} md={3} className="full-width">
                  <DateInput name="date_to" label="Date To" disableFuture required
                    value={moment().format('DD/MM/YYYY')}
                    inputProps={{
                      endAdornment: <InputAdornment position="end"><CalendarIcon className="cursor" /></InputAdornment>
                    }}
                    validations="dateSameOrAfter:date_from"
                    validationErrors={{
                      dateSameOrAfter: 'must be after from date',
                      isDefaultRequiredValue: "can't be blank"
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <SelectInput name="kiosk_id" label="Site" options={[{label: 'All', value: 'all'}].concat(webposProfile.webstores)} value="all" />
                </Grid>
              </Grid>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width">
                  <Button type="submit" fullWidth disabled={isFetching} variant="contained" color="secondary">Submit</Button>
                </Grid>
              </Grid>
            </Formsy>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.report.isFetching,
    webposProfile: state.session.webposProfile
  }
}

const mapDispatchToProps = {
  requestWebstoresReport: (data) => requestWebstoresReport(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(WebstoresReportPage)
