import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';
import UserMenu from 'components/menus/UserMenu';
import UserForm from 'components/forms/UserForm';
import { createUser } from 'actions/UserActions';

export class NewUserPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.createUser(data);
  }

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item md={2} className="full-width">
          <UserMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center">New User</Typography>
            <UserForm handleSubmit={this.handleSubmit} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  createUser: (data) => createUser(data)
};

export default connect(null, mapDispatchToProps)(NewUserPage)
